import AssetFetcher from 'src/AssetFetcher';
/** type imports */
import type {
  // VideoNode,
  Node,
  // ScreenCleanerOption,
} from 'types';




// type NodeType = VideoNode;
// type OptionType = ScreenCleanerOption;
// type OptionId = string;



type MediaFields<T extends Node, K extends keyof T> = keyof Pick<T, K>;
// const mediaFields: MediaFields[] = ['video'];

// type OptionMediaFields = keyof Pick<OptionType, 'botAudio' | 'soundEffect' | 'responseVideo' | 'image'>;
// const optionMediaFields: OptionMediaFields[] = ['botAudio', 'soundEffect', 'responseVideo', 'image'];

// type OptionKey = keyof Pick<NodeType, 'screenCleanerOptions'>;
// const optionKey: OptionKey = 'screenCleanerOptions';

interface TransformArgs<T extends Node, K extends keyof T> {
  node: T;
  mediaFields: MediaFields<T, K>[];
}

export async function transformSimpleNode<T extends Node, K extends keyof T>({ node, mediaFields }: TransformArgs<T, K>): Promise<T> {
  const assetFetcher = new AssetFetcher();
  await assetFetcher.init();
  // const options = node[optionKey];

  // const filteredOptionAssetPathTuples = Object.entries(options).map(([optionId, option]): [OptionId, [OptionMediaFields, string][]] => {
  //   const reducedOption = optionMediaFields.reduce((accumulator: [OptionMediaFields, string][], currentValue) => {
  //     const assetPath = option[currentValue];
  //     if (assetPath) {
  //       accumulator.push([currentValue, assetPath]);
  //     }
  //     return accumulator;
  //   }, []);
  //   return [optionId, reducedOption];
  // });

  const filteredAssetPathTuples = mediaFields.reduce((accumulator: [K, string][], currentValue) => {
    const assetPath = node[currentValue];
    if (assetPath && typeof assetPath === 'string') {
      accumulator.push([currentValue, assetPath]);
    }
    return accumulator;
  }, []);




  /**
   * start all requests for option assets
   */
  // const optionMediaAssetsPromises = Promise.all(filteredOptionAssetPathTuples.map(async ([optionId, fieldAssetPathArray]): Promise<[OptionId, [OptionMediaFields, string][]]> => {
  //   const optionAssets = await Promise.all(fieldAssetPathArray.map(async ([field, assetPath]): Promise<[OptionMediaFields, string]> => {
  //     const objUrl = await assetFetcher.getAsset(assetPath);
  //     return [field, objUrl];
  //   }));
  //   return [optionId, optionAssets];
  // }));

  /**
   * start all requests for node assets
   */
  const mediaAssets = await Promise.all(filteredAssetPathTuples.map(async ([field, assetPath]): Promise<[K, string]> => {
    const objUrl = await assetFetcher.getAsset(assetPath);
    return [field, objUrl];
  }));

  /**
   * wait for all of the requests to finish
   */
  // const [mediaAssets, optionMediaAssets] = await Promise.all([mediaAssetsPromises, optionMediaAssetsPromises]);

  // const transformedOptions = optionMediaAssets.reduce((accumulator: { [optionId: string]: typeof options[string] }, currentValue) => {
  //   const [optionId, mediaAssets] = currentValue;
  //   accumulator[optionId] = {
  //     ...options[optionId],
  //     ...Object.fromEntries(mediaAssets),
  //   };
  //   return accumulator;
  // }, {});

  const transformed: T = {
    ...node,
    ...Object.fromEntries(mediaAssets),
    // [optionKey]: transformedOptions,
  };

  return transformed;
}
