import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

// import { emailFrequencySettingOptions } from 'src/helpers';
import { userConverter } from 'src/features/authentication/helpers';
import './index.css';
/** import types */
// import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';
// import type {
//   SubscriptionResponse,
// } from 'types';
// import type { Stripe } from 'stripe';


const PasswordEmailReset: React.FC = () => {
  const [passwordResetEmailSent, setPasswordResetEmailSent] = React.useState<boolean | null>(null);
  const { firestore, auth } = firebase;
  const uid = auth().currentUser?.uid;
  const email = auth().currentUser?.email;
  const db = firestore();
  const userRef = (uid) ? db.collection('appUsers').withConverter(userConverter).doc(uid) : null;
  // const [emailFreq, setEmailFreq] = React.useState<typeof emailFrequencySettingOptions[keyof typeof emailFrequencySettingOptions] | null>(null);
  React.useEffect(() => {
    let unsub: (() => void) | undefined;
    if (userRef) {
      unsub = userRef.onSnapshot((snapShot) => {
        const data = snapShot.data();
        if (data) {
          // setEmailFreq(data.emailFrequencySetting);
          console.log('hello');

        }
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [userRef]);
  if (!uid || !email || !userRef) {
    return (
      <div className={'update-password-email-container'}>
        <div className={'tappity-header'}>Loading...</div>
        
      </div>
    );
  }


  return (
    <div className={'update-password-email-container'}>
      <div className={'tappity-header'}>Reset password</div>
      <div className={'email-display'}>
        {email}
      </div>
      <button
        className={`tappity-form-submit-button`}
        disabled={passwordResetEmailSent !== null && passwordResetEmailSent}
        onClick={async () => {
          try {
            console.log('clicked');
            await firebase.auth().sendPasswordResetEmail(email);
            setPasswordResetEmailSent(true);
          } catch (error) {
            console.error(error);
            setPasswordResetEmailSent(false);
          }
        }}
      >
        {(passwordResetEmailSent === null) ? 'Send reset password email' : ((passwordResetEmailSent) ? 'Email sent' : 'Something went wrong')}
      </button>
    </div>
  );
};

export default PasswordEmailReset;