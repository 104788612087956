import React from 'react';

interface Props {
  onSubmit: React.FormEventHandler<HTMLFormElement>
}
const CTAForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    let hasTypo = false;
    const RFC2822 = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    hasTypo = email.endsWith(".con");
    hasTypo = hasTypo || email.endsWith("gmial.com");
    hasTypo = hasTypo || !RFC2822.test(email);
    if (hasTypo) {
      setError("Hmm, looks like you have a typo");
    } else {
      setError(null);
      onSubmit(evt);
    }
  };

  return (
    <form className="landing-cta-form" onSubmit={handleSubmit}>
      <div className="email-button-group">
        <input
          className="sign-up-email"
          name="email"
          type="email"
          value={email}
          onChange={(evt) => setEmail(evt.currentTarget.value)}
          required
          placeholder="Email address"
        />
        <button
          className="sign-up-button"
          type="submit"
        >
          Get started
        </button>
      </div>
      {error && <div className="cta-form-error-message">{error}</div>}
      <div className="terms-condition-group">
        <input
          className="terms-conditions-checkbox"
          defaultChecked={true}
          required
          type="checkbox"
          name="terms-conditions"
          value="terms"
        />
        <label
          className="terms-conditions-label"
          htmlFor="terms-conditions"
        >
          By continuing you agree to our <a href="https://www.tappityapp.com/terms" target="_blank" rel="noreferrer">Terms</a> and <a href="https://www.tappityapp.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </label>
      </div>
    </form>
  );
};

export default CTAForm;