import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import posthog from 'posthog-js';
import { userConverter } from './helpers';
import { generateFirestorePath } from 'src/helpers';
import { fetchUserProfiles } from 'src/features/userProfiles/userProfilesSlice';
import { userProfileConverter } from 'src/features/userProfiles/helpers';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
/** type imports */
import type { FirebaseAuthUser, User, UserProfile } from 'types';
import type { RootState } from 'src/app/rootReducer';

interface AuthState {
  user: User | null;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}


const initialState: AuthState = {
  user: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

function unixProcessor(strMilliSec: string): { seconds: number; nanoseconds: number } {
  const milliSec = parseInt(strMilliSec);
  const seconds = Math.floor(milliSec / 1000);
  const nanoseconds = (milliSec - (seconds * 1000)) * 1000000;
  return { seconds, nanoseconds } as const;
}


export const fetchUpsertUser = createAsyncThunk<User | null, FirebaseAuthUser | null, { state: RootState }>(
  'user/fetchUpsertUser',
  async (payload, { getState, requestId }) => {
    const { loading, currentRequestId } = getState().userState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    if (payload === null) {
      posthog.reset();
      return null;
    }
    const { uid } = payload;
    const usersColRef = firebase.firestore()
      .collection(generateFirestorePath('appUsers'))
      .withConverter(userConverter);

    const userRef = usersColRef.doc(uid);
    let userSnapshot = await userRef.get();
    let user = userSnapshot.data();
    let createdAt = dayjs().valueOf();
    if (payload.createdAt) {
      const { seconds, nanoseconds } = unixProcessor(payload.createdAt);
      createdAt = (new firebase.firestore.Timestamp(seconds, nanoseconds)).toMillis();
      console.log('parsing createdAt for new user', payload.createdAt, createdAt);
    }
    if (!user) {
      const { email, displayName } = payload;
      /**
       * create new user document
       */
      const newUser: User = {
        uid,
        isAnonymous: payload.isAnonymous ?? true,
        featureFlags: {},
        membershipStatus: 'inactive',
        parentSummaryEmailEnabled: true,
        emailFrequencySetting: 86400,
        providerData: payload.providerData || [],
        createdAt,
        updatedAt: createdAt,
      };

      if (email) {
        newUser.email = email;
      }
      if (displayName && displayName !== '') {
        newUser.parentName = displayName;
      }
      await userRef.set(newUser);
      user = newUser;

    } else {
      const userUpdate: Partial<User> = {};
      let hasUpdated = false;
      if (!('isAnonymous' in user)) {
        userUpdate.isAnonymous = payload.isAnonymous;
        hasUpdated = true;
      }
      if (!('membershipStatus' in user)) {
        userUpdate.membershipStatus = 'inactive';
        hasUpdated = true;
      }
      if (!('featureFlags' in user)) {
        userUpdate.featureFlags = {};
        hasUpdated = true;
      }
      if (!('createdAt' in user)) {
        userUpdate.createdAt = createdAt;
        hasUpdated = true;
      }
      if (!('parentSummaryEmailEnabled' in user)) {
        userUpdate.parentSummaryEmailEnabled = true;
        hasUpdated = true;
      }
      if (!('emailFrequencySetting' in user)) {
        userUpdate.emailFrequencySetting = 86400;
        hasUpdated = true;
      }
      if (!('providerData' in user)) {
        userUpdate.providerData = payload.providerData || [];
        hasUpdated = true;
      }
      if ((!('email' in user)) && payload.email && payload.email !== '') {
        userUpdate.email = payload.email;
        hasUpdated = true;
      }
      if (hasUpdated) {
        userUpdate.updatedAt = dayjs().valueOf();
        await userRef.update(userUpdate);
      } else if (!('updatedAt' in user)) {
        userUpdate.updatedAt = dayjs().valueOf();
        await userRef.update(userUpdate);
      }
    }

    userSnapshot = await userRef.get();
    user = userSnapshot.data();

    if (!user) {
      return null;
    }
    posthog.identify(user.uid, { displayName: user.parentName });
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).fbq('init', '278820836775081', {
        external_id: user.uid,
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
    return user;
  }
);

export const linkProvider = createAsyncThunk<User | null, { credential: firebase.auth.AuthCredential; parentName?: string; userProfile: Required<Pick<UserProfile, 'ageGroup' | 'name'>>; }, { state: RootState }>(
  'user/convertUser',
  async ({ credential, parentName, userProfile }, { getState, requestId, rejectWithValue, dispatch }) => {
    const { loading, currentRequestId, user } = getState().userState;
    const { currentUser } = firebase.auth();
    if (currentUser === null || user === null || loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }

    let linkedResult: firebase.auth.UserCredential | null = null;
    try {
      linkedResult = await currentUser.linkWithCredential(credential);
    } catch (error) {
      return rejectWithValue(error);
    }

    const { uid } = user;
    const userRef = firebase.firestore()
      .collection(generateFirestorePath('appUsers'))
      .withConverter(userConverter)
      .doc(uid);


    function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
      return value !== null && value !== undefined;
    }

    const providerData = user.providerData.filter(notEmpty).map((data) => ({ ...data }));
    const userUpdate: Partial<User> = {};
    if (parentName) {
      userUpdate.parentName = parentName;
    }
    const linkedUser = linkedResult?.user;
    if (linkedUser) {
      if ('isAnonymous' in linkedUser) {
        userUpdate.isAnonymous = linkedUser.isAnonymous;
      }
      if (linkedUser.email) {
        userUpdate.email = linkedUser.email;
      }
      userUpdate.providerData = providerData;
      userUpdate.updatedAt = dayjs().valueOf();
    }
    await userRef.update(userUpdate);
    const userSnapshot = await userRef.get();
    const userData = userSnapshot.data();
    if (!userData) {
      return null;
    }

    const userProfilesColRef = userRef.collection(generateFirestorePath('profiles'))
      .withConverter(userProfileConverter);

    const userProfilesSnapshot = await userProfilesColRef.get();

    if (userProfilesSnapshot.empty) {
      await userProfilesColRef.add({
        ...userProfile,
        points: 0,
        topics: [],
        storyProgress: {},
        seasonProgress: {},
        interactionEvents: {},
        deleted: false,
      });
    } else {
      const profileQuerySnapshot = await userProfilesColRef.limit(1).get();
      const profileSnapshot = profileQuerySnapshot.docs[0];
      await profileSnapshot.ref.update(userProfile);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).fbq('track', 'CompleteRegistration');
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message, 'facebook pixel tracking failed');
      }
    }
    posthog.identify(userData.uid, { displayName: userData.parentName });
    await dispatch(fetchUserProfiles());
    return userData;
  }
);

export const signIn = createAsyncThunk<User | null, { email: string; password: string; }, { state: RootState }>(
  'user/signIn',
  async ({ email, password }, { getState, requestId, dispatch, rejectWithValue }) => {
    const { loading, currentRequestId, user: anonUser } = getState().userState;
    const { currentUser } = firebase.auth();
    if (currentUser === null || anonUser === null || loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    let user: firebase.User | null = null;
    try {
      const credential = await firebase.auth().signInWithEmailAndPassword(email, password);
      user = credential.user;
    } catch (error) {
      return rejectWithValue(error);
    }
    if (user === null) {
      return null;
    }
    const { uid } = user;
    const userRef = firebase.firestore()
      .collection(generateFirestorePath('appUsers'))
      .withConverter(userConverter)
      .doc(uid);

    let userSnapshot = await userRef.get();
    let userData = userSnapshot.data();
    if (!userData) {
      console.log('no user record matching that uid');
      return null;
    }


    function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
      return value !== null && value !== undefined;
    }

    const providerData = user.providerData.filter(notEmpty).map((data) => ({ ...data }));
    const userUpdate: Partial<User> = {};
    let hasUpdated = false;
    if (!('isAnonymous' in userData) || (userData.isAnonymous !== user.isAnonymous)) {
      userUpdate.isAnonymous = user.isAnonymous;
      hasUpdated = true;
    }
    if (!('membershipStatus' in userData)) {
      userUpdate.membershipStatus = 'inactive';
      hasUpdated = true;
    }
    if (!('featureFlags' in userData)) {
      userUpdate.featureFlags = {};
      hasUpdated = true;
    }
    if (!('createdAt' in userData)) {
      userUpdate.createdAt = dayjs().valueOf();
      hasUpdated = true;
    }
    if (!('parentSummaryEmailEnabled' in user)) {
      userUpdate.parentSummaryEmailEnabled = true;
      hasUpdated = true;
    }
    if (!('emailFrequencySetting' in user)) {
      userUpdate.emailFrequencySetting = 86400;
      hasUpdated = true;
    }
    userUpdate.providerData = providerData;
    if ((!('email' in userData)) && user.email && user.email !== '') {
      userUpdate.email = user.email;
      hasUpdated = true;
    }
    if (hasUpdated) {
      userUpdate.updatedAt = dayjs().valueOf();
    } else if (!('updatedAt' in user)) {
      userUpdate.updatedAt = dayjs().valueOf();
    }

    await userRef.update(userUpdate);
    userSnapshot = await userRef.get();
    userData = userSnapshot.data();
    if (!userData) {
      return null;
    }
    posthog.identify(userData.uid, { displayName: userData.parentName });
    await dispatch(fetchUserProfiles());
    return userData;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpsertUser.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchUpsertUser.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.user = payload;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(fetchUpsertUser.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(linkProvider.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(linkProvider.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.user = payload;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(linkProvider.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(signIn.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.user = payload;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(signIn.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default userSlice.reducer;