import React from 'react';
import {
  useSelector,
  useDispatch,
  shallowEqual,
} from 'react-redux';
import {
  fetchUserProfiles,
  setSelectedUserProfile,
  fetchUserProfileAsset,
} from 'src/features/userProfiles/userProfilesSlice';
import { useHistory, useParams } from 'react-router-dom';
import { fetchProfileActivities } from 'src/features/profileActivities/profileActivitiesSlice';
import { fetchTags } from 'src/features/stories/tagsSlice';
import { fetchStories } from 'src/features/stories/storiesSlice';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './index.css';
/** import types */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import { StoryState } from 'src/features/stories/storiesSlice';

interface Params {
  topicId?: string;
}

const ProgressPage: React.FC = () => {
  const { topicId } = useParams<Params>();

  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const {
    selectedProfileId,
    userProfiles,
    profileActivities,
    stories,
    tags,
  } = useSelector((state: RootState) => {
    return {
      profileActivities: state.profileActivitiesState.activities,
      selectedProfileId: state.userProfilesState.selectedProfileId,
      userProfiles: state.userProfilesState.userProfiles,
      stories: state.storiesState.stories,
      tags: state.tagsState.tags,
    };
  }, shallowEqual);

  const [lightboxIsOpen, setLightboxIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [showCompletedLessons, setShowCompletedLessons] = React.useState(true);


  const userProfile = (userProfiles && selectedProfileId) ? userProfiles[selectedProfileId] : null;

  React.useEffect(() => {
    if (userProfiles === null) {
      dispatch(fetchUserProfiles());
    }
    if (stories === null) {
      dispatch(fetchStories());
    }
    if (tags === null) {
      dispatch(fetchTags());
    }
    if (!selectedProfileId) {
      return;
    }
    if (profileActivities === null) {
      dispatch(fetchProfileActivities({ selectedProfileId: selectedProfileId }));
    }
    if (!userProfile?.['image/id-badge-selfie'].blobUrl) {
      dispatch(fetchUserProfileAsset({ userProfileId: selectedProfileId, assetName: 'image/id-badge-selfie' }));
    }
  }, [dispatch, userProfiles, userProfile, stories, tags, selectedProfileId, profileActivities]);

  if (!selectedProfileId || !stories || !tags || !profileActivities || !userProfile) {
    return (
      <div style={{ height: '100vh' }} className='loading-screen'></div>
    );
  }
  const userPhotoStyle: React.CSSProperties = {};
  const profilePhotoUrl = userProfile['image/id-badge-selfie'].blobUrl;
  if (profilePhotoUrl) {
    userPhotoStyle.backgroundImage = `url(${profilePhotoUrl})`;
  }

  const photoList = Object.entries(profileActivities)
    .filter(([, activity]) => activity.imageFullPath)
    .map(([, activity]) => {
      return `https://firebasestorage.googleapis.com/v0/b/tappity-user-uploads/o/${encodeURIComponent(activity.imageFullPath || "")}?alt=media`;
    });

  const topicProgressPage = () => {
    if (!topicId) return <div></div>;

    const tag = tags[topicId];
    const completed: StoryState[] = [];
    const incomplete: StoryState[] = [];

    const tagStories = stories.byTagId[topicId].map(story => stories.byId[story]);
    tagStories.forEach(story => {
      const storyProgress = userProfile.storyProgress[story.storyId];
      if (storyProgress && storyProgress.completed) {
        completed.push(story);
      } else {
        incomplete.push(story);
      }
    });

    completed.sort( (a, b) => {
      return (userProfile.storyProgress[b.storyId]?.updatedAt || 0 )-
        (userProfile.storyProgress[a.storyId]?.updatedAt || 0);
    });

    return (
      <div id="topic-progress-page" className="app-page">
        <h1>{`${tag.emoji} ${tag.displayName} Progress`}</h1>

        <div className="story-list-wrapper">
          <nav>
            <button onClick={() => setShowCompletedLessons(true)} className={showCompletedLessons ? "active" : ""}>
              Completed Lessons
            </button>
            <button onClick={() => setShowCompletedLessons(false)} className={!showCompletedLessons ? "active" : ""}>
              Incomplete Lessons
            </button>
          </nav>
          {
            showCompletedLessons && completed.length == 0 &&
            <div className="empty-list">No lessons completed yet</div>
          }
          {
            !showCompletedLessons && incomplete.length == 0 &&
            <div className="empty-list">Everything has been completed!</div>
          }
          {
            (showCompletedLessons ? completed : incomplete).map( story => {
              const completionDate = userProfile.storyProgress[story.storyId]?.updatedAt;
              const storyURL = `/seasons/${story.seasonId}/stories/${story.storyId}`;

              return <div className="story-item" key={story.storyId} onClick={() => history.push(storyURL)}>
                <h4>{story.story.title}</h4>
                {
                  showCompletedLessons ?
                    <h6>Last completed {completionDate ? new Date(completionDate * 1000).toLocaleDateString() : "a while ago"}</h6> :
                    <h6>{ story.story.description }</h6>
                  }
                <button>&rsaquo;</button>
              </div>;
            })
          }
        </div>
      </div>
    );
  };

  const fullProgressPage= () => {
    return (
      <div id="progress-page" className="app-page">
        <nav>
          <h1>Progress Report</h1>
          {userProfiles && Object.keys(userProfiles).length > 1 &&
            <div className="tappity-select-wrapper">
              <select
                value={selectedProfileId}
                onChange={e => { dispatch(setSelectedUserProfile({ userProfileId: e.target.value })); }}
              >
                {Object.entries(userProfiles).map(([id, profile]) => {
                  return (
                    <option key={id} value={id}>
                      {profile.name || "Tappernaut"}
                    </option>
                  );
                })}
              </select>
              <span className="focus"></span>
            </div>}
        </nav>

        <div className="summary progress-section">
          <div style={userPhotoStyle} className="user-profile-photo"></div>
          <h2>{userProfile.name || "Tappernaut"}</h2>
          {userProfile.topics.length > 0 &&
            <div className="topics">
              <strong>Favorite Topics: </strong>
              {userProfile.topics.map(tagID => {
                const tag = tags[tagID];
                if (!tag) return '';
                return `${tag.emoji} ${tag.displayName}`;
              }).join(", ")}
            </div>}
        </div>

        <div className="topics progress-section">
          <h4>Progress by Topic</h4>
          <div className="topics-container">
            {Object.entries(tags)
              .filter(([tagId]) => stories.byTagId[tagId])
              .map(([tagId, tag]) => {
                let completed = 0;
                const total = stories.byTagId[tagId].length;
                stories.byTagId[tagId].forEach(story => {
                  const storyProgress = userProfile.storyProgress[story];
                  if (storyProgress && storyProgress.completed) {
                    if (!storyProgress.updatedAt) {
                      console.log(storyProgress);
                    }
                    completed++;
                  }
                });
                const percentage = Math.round((completed / total) * 100);

                return (
                  <div key={tagId} onClick={() => history.push(`/progress/${tagId}`)} className="topic">
                    <div className="topic-title">{tag.emoji} {tag.displayName}</div>
                    <div
                      className="progress-bar-container"
                      role="progressbar"
                      aria-valuenow={percentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar-value"
                        style={{ width: `${percentage}%` }}
                      >
                      </div>
                    </div>
                    <div className="progress-summary">
                      <span className="progress-count">{completed} of {stories.byTagId[tagId].length}</span> Lessons completed
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>

        <div className="creations progress-section">
          <h4>Creations &amp; Rewards</h4>
          <div className="creations-wrapper">
            {photoList.map((photo, index) => {
              return (
                <a
                  key={photo}
                  onClick={() => {
                    setPhotoIndex(index);
                    setLightboxIsOpen(true);
                  }}
                >
                  <img src={photo} />
                </a>
              );
            })}
          </div>
          {lightboxIsOpen &&
            <Lightbox
              mainSrc={photoList[photoIndex]}
              nextSrc={photoList[(photoIndex + 1) % photoList.length]}
              prevSrc={photoList[(photoIndex + photoList.length - 1) % photoList.length]}
              onCloseRequest={() => setLightboxIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photoList.length - 1) % photoList.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photoList.length)}
            />}
        </div>
      </div>
    );
  };

  if (topicId) {
    return topicProgressPage();
  } else {
    return fullProgressPage();
  }
};

export default ProgressPage;