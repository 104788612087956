import React from 'react';
import firebase from 'firebase';
import posthog from 'posthog-js';
import 'firebase/auth';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AnonymousButtons from './AnonymousButtons';
import SignedInButtons from './SignedInButtons';
import './index.css';

// type imports
import type { User, UserProfile } from 'types';


interface Props {
  user: User;
  selectedProfile?: UserProfile;
}
const Sidebar: React.FC<Props> = ({ user, selectedProfile }: Props) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const promoCode = (new URLSearchParams(search)).get('promo');
  const homeUrl = promoCode ? `/?promo=${promoCode}` : '/';

  const isAnonymous = user.isAnonymous;
  return (
    <div id='sidebar'>
      <div className={'sidebar-top'}>
        <div className={'tappity-header'}>
          <img className={'logo'} src='/tappity-logo-type.png' alt='logo' width="140" />
          <div className={'sub-header'}>Every science topic kids love.</div>
        </div>
        
        <Link className={`sidebar-menu-item ${(pathname === '/') ? 'sidebar-item-selected' : ''}`} to={homeUrl}>Home</Link>
        {(isAnonymous) ? (<AnonymousButtons />) : (<SignedInButtons selectedProfile={selectedProfile} />)}
      </div>
      <div className={'sidebar-bottom'}>
        {!isAnonymous && (
          <button
            className={'sidebar-menu-item sign-out-button'}
            onClick={async () => {
              try {
                posthog.reset();
                await firebase.auth().signOut();
                history.push(homeUrl);
              } catch (error) {
                console.log('Sign out error:', error);
              }
            }}
          >
            Sign-Out
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;