import React from 'react';
import { Link } from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import {
  StarIcon,
  CheckIcon
} from '@heroicons/react/outline';
/** import types */
import type { Stripe } from 'stripe';
import type { RootState } from 'src/app/rootReducer';


interface Props {
  stripePrices: Stripe.Price[];
  promoCode: Stripe.PromotionCode | undefined;
  onClick: () => void;
}

const DigitalWithClasses: React.FC<Props> = ({ stripePrices, promoCode, onClick }: Props) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const [selectedPrice, setSelectedPrice] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (stripePrices && selectedPrice === null) {
      setSelectedPrice(stripePrices[1].id);
    }
  }, [stripePrices, selectedPrice]);

  const prices = stripePrices
    .filter(({ active }) => active)
    .sort(({ unit_amount: price_a }, { unit_amount: price_b }) => (price_a || 0) - (price_b || 0));
  const unit_amount = prices[prices.length - 1].unit_amount;
  const monthly = (unit_amount) ? unit_amount / (12 * 100) : 10;
  console.log(prices[prices.length - 1]);

  const SignInButton: React.FC = () => (
    <div className='footer-buttons-container'>
      <Link
        className={'tappity-form-submit-button'}
        to={{
          pathname: '/sign-in',
          state: {
            promoCode,
          }
        }}
      >
        Sign-in to start trial
      </Link>
      <div>
        <div>{"or don't have an account?"}</div>
        <Link
          className={'tappity-form-submit-button'}
          to={{
            pathname: '/landing',
            search: promoCode && `?promo=${promoCode.code}`,
            state: {
              promoCode,
              skipSplash: true,
            }
          }}
        >
          Create an account
        </Link>
      </div>
    </div>
  );

  const StartButton: React.FC = () => {
    return (
      <div className="category-footer">
        <div className="category-price-preview">
          <div>STARTING FROM</div>
          <div className="lowest-price">${(monthly.toFixed(2))}</div>
          <div>/MONTH</div>
        </div>
        <button
          className={'tappity-form-submit-button-inverted'}
          // disabled={selectedPrice === null}
          onClick={onClick}
        >
          SELECT ➞
        </button>
      </div>
    );
  };

  const PricePreview: React.FC = () => {
    return (
      <div className="category-footer">
        <div className="category-price-preview">
          <div>STARTING FROM</div>
          <div className="lowest-price">${(monthly.toFixed(2))}</div>
          <div>/MONTH</div>
        </div>
      </div>
    );
  };

  return (
    <div className={'category-container'}>
      <div className="stripe-category-title">14 Days free</div>
      <img src="class-membership-image.png" />
      <div className="marketing-category-text-container">
        <div className="marketing-item-category-header">Digital + ClassPass</div>
        <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Everything in Digital Membership.</div>
        <div className="marketing-item-category highlighter"><StarIcon className="list-icon" width="22" /> Two 45-min Small-Group Classes per month (value: $79.99).</div>
        <div className="marketing-item-category">
        <a href="https://www.tappityapp.com/smallgroups" target="_blank" rel="noreferrer">Learn more about Small-Group Classes ➞</a></div>
        <div className="marketing-item-category invisible"> .</div>
      </div>
      {(!user?.isAnonymous) ? <StartButton /> : <div><SignInButton /> <PricePreview /></div> }
    </div>
  );
};

export default DigitalWithClasses;