import AssetFetcher from 'src/AssetFetcher';
/** type imports */
import type {
  LAMCAnswerDefinition,
  LAMCQuestionNode,
} from 'types';








type NodeType = LAMCQuestionNode;
type OptionType = LAMCAnswerDefinition;
type OptionId = string;



type MediaFields = keyof Pick<NodeType, 'musicAudio' | 'questionAudio' | 'questionVideo' | 'transitionVideo' | 'waitingLoopingVideo'>;
const mediaFields: MediaFields[] = ['musicAudio', 'questionAudio', 'questionVideo', 'transitionVideo', 'waitingLoopingVideo'];

type OptionMediaFields = keyof Pick<OptionType, 'answerAudio' | 'responseBotAudio' | 'responseBackgroundVideo' | 'responseBackgroundImage' | 'answerImage'>;
const optionMediaFields: OptionMediaFields[] = ['answerAudio', 'responseBotAudio', 'responseBackgroundVideo', 'responseBackgroundImage', 'answerImage'];

type OptionKey = keyof Pick<NodeType, 'lamcAnswers'>;
const optionKey: OptionKey = 'lamcAnswers';



export async function transformLAMCQuestion(node: NodeType): Promise<NodeType> {
  const assetFetcher = new AssetFetcher();
  await assetFetcher.init();
  const options = node[optionKey];

  const filteredOptionAssetPathTuples = Object.entries(options).map(([optionId, option]): [OptionId, [OptionMediaFields, string][]] => {
    const reducedOption = optionMediaFields.reduce((accumulator: [OptionMediaFields, string][], currentValue) => {
      const assetPath = option[currentValue];
      if (assetPath) {
        accumulator.push([currentValue, assetPath]);
      }
      return accumulator;
    }, []);
    return [optionId, reducedOption];
  });

  const filteredAssetPathTuples = mediaFields.reduce((accumulator: [MediaFields, string][], currentValue) => {
    const assetPath = node[currentValue];
    if (assetPath) {
      accumulator.push([currentValue, assetPath]);
    }
    return accumulator;
  }, []);




  /**
   * start all requests for option assets
   */
  const optionMediaAssetsPromises = Promise.all(filteredOptionAssetPathTuples.map(async ([optionId, fieldAssetPathArray]): Promise<[OptionId, [OptionMediaFields, string][]]> => {
    const optionAssets = await Promise.all(fieldAssetPathArray.map(async ([field, assetPath]): Promise<[OptionMediaFields, string]> => {
      const objUrl = await assetFetcher.getAsset(assetPath);
      return [field, objUrl];
    }));
    return [optionId, optionAssets];
  }));

  /**
   * start all requests for node assets
   */
  const mediaAssetsPromises = Promise.all(filteredAssetPathTuples.map(async ([field, assetPath]): Promise<[MediaFields, string]> => {
    const objUrl = await assetFetcher.getAsset(assetPath);
    return [field, objUrl];
  }));

  /**
   * wait for all of the requests to finish
   */
  const [mediaAssets, optionMediaAssets] = await Promise.all([mediaAssetsPromises, optionMediaAssetsPromises]);

  const transformedOptions = optionMediaAssets.reduce((accumulator: { [optionId: string]: typeof options[string] }, currentValue) => {
    const [optionId, mediaAssets] = currentValue;
    accumulator[optionId] = {
      ...options[optionId],
      ...Object.fromEntries(mediaAssets),
    };
    return accumulator;
  }, {});

  const transformed = {
    ...node,
    ...Object.fromEntries(mediaAssets),
    [optionKey]: transformedOptions,
  };

  return transformed;
}
