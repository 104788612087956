import { transformBotMultipleQuestion } from './BotMultipleQuestion';
import { transformLAMCQuestion } from './LAMCQuestion';
import { transformScreenCleaning } from './ScreenCleaning';
import { transformSimpleNode } from './simpleNodeTranformer';
/** type imports */
import type {
  Node,
} from 'types';



export async function transformNode(node: Node): Promise<Node> {
  switch (node.type) {
    case 'Live Action Multiple Choice Question': {
      return transformLAMCQuestion(node);
    }
    case 'Bot multi-choice question': {
      return transformBotMultipleQuestion(node);
    }
    case 'Screen Cleaning': {
      return transformScreenCleaning(node);
    }
    case 'Video': {
      return transformSimpleNode({
        node,
        mediaFields: ['video'],
      });
    }
    case 'X-ray': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio', 'xrayImage'],
      });
    }
    case 'Selfie': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio', 'musicAudio', 'overlayImage'],
      });
    }
    case 'Pulley': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio', 'startingVideo', 'waitingVideo', 'afterVideo'],
      });
    }
    case 'Phone': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio', 'backgroundImage'],
      });
    }
    case 'ID Badge': {
      return transformSimpleNode({
        node,
        mediaFields: ['video', 'waitingVideo'],
      });
    }
    case 'Drawing': {
      return transformSimpleNode({
        node,
        mediaFields: ['backgroundImage', 'botAudio', 'musicAudio'],
      });
    }
    case '3D Model': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio'],
      });
    }
    case 'Points Display': {
      return transformSimpleNode({
        node,
        mediaFields: [],
      });
    }
    case 'Travel': {
      return transformSimpleNode({
        node,
        mediaFields: [],
      });
    }
    case 'Badge Earning': {
      return transformSimpleNode({
        node,
        mediaFields: ['botAudio'],
      });
    }
    case 'Builder Image Assembler': {
      return transformSimpleNode({
        node,
        mediaFields: ['backgroundImage'],
      });
    }
  }
}