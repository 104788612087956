import { SnapshotOptions, User } from 'types';
import firebase from 'firebase';


export const userConverter: firebase.firestore.FirestoreDataConverter<User> = {
  toFirestore(user: User): User {
    return user;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<User>, options: SnapshotOptions): User {
    const data = snapshot.data(options);
    return data;
  },
};