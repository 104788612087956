import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import 'firebase/firestore';
import dayjs from 'dayjs';
import { v1 as uuidV1 } from 'uuid';
import { profileActivityConverter } from './helpers';
import { generateFirestorePath } from 'src/helpers';
const { firestore } = firebase;
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type {
  Activity,
} from 'types';

interface ProfileActivities {
  selectedProfileId: string | null;
  activities: null | { [activityId: string]: Activity };
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: ProfileActivities = {
  selectedProfileId: null,
  activities: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchProfileActivities = createAsyncThunk<{ selectedProfileId: string; activities: { [activityId: string]: Activity } } | null, { selectedProfileId: string; }, { state: RootState }>(
  'profileActivities/fetchProfileActivities',
  async ({ selectedProfileId }, { getState, requestId }) => {
    // must do this in order to make sign-in work
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId } = getState().profileActivitiesState;

    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { uid } = user;
    const profileActivitiesColRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles/${selectedProfileId}/activities`))
      .withConverter(profileActivityConverter);

    const activities: { [activityId: string]: Activity } = {};

    const profileActivitiesSnapshot = await profileActivitiesColRef.get();

    profileActivitiesSnapshot.forEach((activity) => {
      if (activity.exists) {
        activities[activity.id] = activity.data();
      }
    });
    return { selectedProfileId, activities };
  }
);


export const addProfileActivity = createAsyncThunk<{ activityId: string, activity: Activity } | null, Omit<Activity, 'id' | 'createdAt' | 'updatedAt'>, { state: RootState }>(
  'profileActivities/addProfileActivity',
  async (activity, { getState }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { activities, selectedProfileId } = getState().profileActivitiesState;
    if (!activities || !selectedProfileId) {
      return null;
    }
    const { uid } = user;
    const activityId = uuidV1();
    const activityRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles/${selectedProfileId}/activities`))
      .withConverter(profileActivityConverter)
      .doc(activityId);

    const now = dayjs().unix(); // Unix timestamp in seconds
    await activityRef.set({
      id: activityId,
      createdAt: now,
      updatedAt: now,
      ...activity,
    });
    const activitySnapshot = await activityRef.get();
    const activityData = activitySnapshot.data();
    if (!activityData) {
      console.error('seems to have written but did not come back correctly');
      return null;
    }
    return { activityId, activity: activityData };
  }
);

const profileActivitiesSlice = createSlice({
  name: 'profileActivities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfileActivities.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchProfileActivities.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;
      if (payload && state.loading === 'pending' && state.currentRequestId === requestId) {
        state.selectedProfileId = payload.selectedProfileId;
        state.activities = payload.activities;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(fetchProfileActivities.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(addProfileActivity.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(addProfileActivity.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;
      if (payload && state.loading === 'pending' && state.currentRequestId === requestId && state.activities) {
        const { activityId, activity } = payload;
        state.activities[activityId] = activity;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(addProfileActivity.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default profileActivitiesSlice.reducer;