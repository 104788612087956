import React from 'react';


const DownloadApp: React.FC = () => {
  return (
    <div>
      hi download the app
    </div>
  );
};

export default DownloadApp;