import React from 'react';
import axios from 'axios';
import firebase from 'firebase';
import 'firebase/auth';


import type {
  AppCollection,
  AssetReference,
  NodeTypes,
  Node,
  LiveNodeTypes,
} from 'types';


export function generateFirestorePath(collectionName: AppCollection): string {
  const nodeEnv = process.env.REACT_APP_ENVIRONMENT;
  if (nodeEnv === 'development') {
    return 'testData/1/' + collectionName;
  }
  return collectionName;
}

export function generateBackendUrl(): string {
  const nodeEnv = process.env.REACT_APP_ENVIRONMENT;
  if (nodeEnv === 'development') {
    return 'http://localhost:8080';
  }
  return 'https://tappity-web-backend-dot-storytime-a93ed.uc.r.appspot.com';
}


export function transformFiles(assets: AssetReference[]): { text: string; value: string }[] {
  return assets.map(({ fullPath }) => ({ text: fullPath, value: fullPath }));
}


/**
 * Source of truth for what will populate forms
 * If you want to add more color themes add them here
 */
export const colorThemes = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'brown'] as const;

export const colorThemeOptions: Record<typeof colorThemes[number], string> = {
  red: 'Red',
  orange: 'Orange',
  yellow: 'Yellow',
  green: 'Green',
  blue: 'Blue',
  purple: 'Purple',
  brown: 'Brown',
} as const;


export const authorizationOptions = [
  'users',
  'node_definitions',
  'story_definitions',
  'asset_manager',
  'tag_definitions'
] as const;

export const authorizationDefinitions: Record<typeof authorizationOptions[number], string> = {
  users: 'User Authorizations',
  node_definitions: 'Node Definitions',
  story_definitions: 'Story Definitions',
  asset_manager: 'Asset Manager',
  tag_definitions: 'Tag Definitions',
} as const;


export const nodeTypes: readonly NodeTypes[] = [
  'Video',
  'Live Action Multiple Choice Question',
  'Phone',
  'Bot multi-choice question',
  'Screen Cleaning',
  '3D Model',
  'Drawing',
  'ID Badge',
  'Pulley',
  'Selfie',
  'X-ray',
  'Points Display',
  'Travel',
] as const;

export const liveNodeTypes: readonly LiveNodeTypes[] = [
  'Live Poll Question',
  'Live True Or False Question',
  'Live Image Overlay',
  'Points Display',
  'Badge Earning',
] as const;




export const threeDModelIDs = ['planet', 'fishes', 'rolling_ball'] as const;

export const threeDModelOptions: Record<typeof threeDModelIDs[number], string> = {
  planet: 'Planet',
  fishes: 'Fishes',
  rolling_ball: 'Rolling Ball',
} as const;

/**
 * Source of truth for what will populate forms
 * if you want to add more levels, add them here
 */
export const difficultyLevels = ['easy', 'medium', 'hard', 'extrahard'] as const;

export const difficultyLevelOptions: Record<typeof difficultyLevels[number], string> = {
  easy: 'Easy',
  medium: 'Medium',
  hard: 'Hard',
  extrahard: 'Extra Hard',
} as const;


export const particleEffects = ['fire', 'foam', 'air', 'confetti'] as const;

export const particleEffectOptions: Record<typeof particleEffects[number], string> = {
  fire: 'Fire',
  foam: 'Foam',
  air: 'Air',
  confetti: 'Confetti',
} as const;


export const seasonStatus = ['staged', 'published', 'archived'] as const;

export const seasonStatusOptions: Record<typeof seasonStatus[number], string> = {
  staged: 'Staged',
  published: 'Published',
  archived: 'Archived',
} as const;

/** source of truth for story status options */
export const storyStatusKeys = ['staged', 'published', 'archived', 'preview', 'live', 'pre_show'] as const;

export const storyStatusOptions: Record<typeof storyStatusKeys[number], string> = {
  staged: 'Staged',
  published: 'Published',
  archived: 'Archived',
  preview: 'Preview',
  live: 'Live',
  pre_show: 'Pre-show',
} as const;


export const storyTypes = ['feature', 'mini', 'checkpoint', 'live'] as const;

export const storyTypeOptions: Record<typeof storyTypes[number], string> = {
  feature: 'Feature',
  mini: 'Mini',
  checkpoint: 'Checkpoint',
  live: 'Live',
} as const;

export const liveStoryTypes = ['activity', 'badge', 'build_a_blank', 'drawing', 'mission', 'narrative', 'trivia', 'sos', 'field_trip', 'guess_who'] as const;
export const liveStoryTypeOptions: Record<typeof liveStoryTypes[number], string> = {
  activity: 'Activity',
  badge: 'Badge',
  build_a_blank: 'Build a blank',
  drawing: 'Drawing',
  mission: 'Mission',
  narrative: 'Narrative',
  trivia: 'Trivia',
  sos: 'SOS',
  field_trip: 'Field Trip',
  guess_who: 'Guess Who'
} as const;

export const momentFormat = 'M/D/YY h:mm A';

interface Dimensions {
  height: number;
  width: number;
}
/** react hook to use the dimensions of the window */
export function useWindowDimensions(): Dimensions {

  const [dimensions, setDimensions] = React.useState<Dimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize(): void {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
}


interface UserResponseOption {
  value: string;
  id: string;
  label: string;
  title: string;
  disabled: boolean;
  isCorrect?: boolean;
}

export function getDeletedStoryNodeIds(nodes: { [nodeId: string]: Node }): string[] {
  return Object.entries(nodes)
    .filter(([, { isDeleted }]) => isDeleted)
    .map(([id]) => id);
}
/**
 * 
 * @param node node to get options from
 * @param deletedStoryNodeIds array of ids from deleted nodes on story
 * @returns array of "UserResponseOption"s that have already filtered out deleted options
 * and indicates that the option is being used on the node via the "disabled" field
 */
export function getOptions(node: Partial<Node>, deletedStoryNodeIds: string[]): UserResponseOption[] | null {
  const selectedOptions = Object.values(node.edges || {})
    .filter(({ targetNodeId }) => !deletedStoryNodeIds.includes(targetNodeId))
    .map(({ context }) => { return context?.optionIds ?? []; }).flat();
  switch (node.type) {
    case 'Live Action Multiple Choice Question': {
      return Object.entries(node.lamcAnswers || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({
          value: id,
          id: id,
          label: answer,
          title: answer,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    case 'Bot multi-choice question': {
      return Object.entries(node.answerDefinitions || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { answer, isCorrect }]) => ({
          value: id,
          id: id,
          label: answer,
          title: answer,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    case 'Screen Cleaning': {
      return Object.entries(node.screenCleanerOptions || {})
        .filter(([, { isDeleted }]) => !isDeleted)
        .map(([id, { displayName, isCorrect }]) => ({
          value: id,
          id,
          label: displayName,
          title: displayName,
          disabled: selectedOptions.includes(id),
          isCorrect,
        }));
    }
    default:
      return null;
  }
}
export const levelPoints = [
  0, // level 1
  100, // level 2
  200, // level 3
  250, // level 4
  300, // level 5
  400, // level 6
  450, // level 7
  500, // level 8
  520, // level 9
  600, // level 10
  620, // level 11
  650, // level 12
  700, // level 13
  750, // level 14
  800, // level 15
  850, // level 16
  900, // level 17
  950, // level 18
  1000, // level 19
  1750, // level 20
  2000, // level 21
  2250, // level 22
  2500, // level 23
  2750, // level 24
  3500, // level 25
  4000, // level 26
  4250, // level 27
  4500, // level 28
  7500, // level 29
  15000, // level 30
];


export const emailFrequencySettingOptions: Record<'Daily' | 'Weekly' | 'Every 2 weeks' | 'Monthly' | 'Never', 86400 | 604800 | 1209600 | 2419200 | -1> = {
  'Daily': 86400,
  'Weekly': 604800,
  'Every 2 weeks': 1209600,
  'Monthly': 2419200,
  'Never': -1,
};

export function getLevel(totalPoints: number): number {
  let nextLevelPoints = 0;
  let i;
  for (i = 0; i < levelPoints.length; i++) {
    nextLevelPoints += levelPoints[i];
    if (totalPoints < nextLevelPoints) {
      break;
    }
  }
  return i;
}
export function getLevelPoints(level: number): number {
  let points = 0;
  let limit = level;
  if (limit >= levelPoints.length) {
    limit = levelPoints.length - 1;
  }
  for (let i = 0; i < limit; i++) {
    points += levelPoints[i];
  }
  return points;
}

export function getEarnedPointsAudioAssetPath(score: number): string {
  if (score >= 500) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/500-points-chip-01.mp3';
  } else if (score >= 400) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/400-points-chip.mp3';
  } else if (score >= 300) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/300-points-chip.mp3';
  } else if (score >= 200) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/200-points-chip.mp3';
  } else if (score >= 100) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/100-points-chip.mp3';
  } else if (score >= 50) {
    return 'BundledRemoteAssets/audio/Leveling/Points earned/50-points-chip.mp3';
  }
  return 'BundledRemoteAssets/audio/Leveling/Points earned/10-points-chip.mp3';
}

export const ageGroup: Record<'5 and under' | '6 7 8' | '9 and over', '5 and under' | '6 7 8' | '9 and over'> = {
  '5 and under': '5 and under',
  '6 7 8': '6 7 8',
  '9 and over': '9 and over'
};

export const firebaseIdTokenKey = 'firebase-id-token';
/** create axios instance for backend */
const axiosInstanceTemp = axios.create({
  baseURL: generateBackendUrl(),
});

axiosInstanceTemp.interceptors.request.use(async (config) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error('Unauthenticated');
  }
  const firebaseIdToken = await user.getIdToken();
  /** add firebase token to each request */
  config.headers[firebaseIdTokenKey] = firebaseIdToken;
  return config;
});

export const axiosInstance = axiosInstanceTemp;




