import React from 'react';
import { useDispatch } from 'react-redux';
import { updateProfileStoryProgress } from 'src/features/userProfiles/userProfilesSlice';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type {
  NodeState,
  NodeSelection,
  StoryProgress,
  LiveStory,
  LiveBuilderImageSelectorNode,
  LiveBuilderImageOption,
} from 'types';


interface ImageInfo {
  x: number;
  y: number;
  adjWidth: number;
  adjHeight: number;
  inCanvas: boolean;
}

interface Props {
  seasonId: string;
  storyId: string;
  story: LiveStory
  nodesProgress: StoryProgress['nodes'];
  active: boolean;
  nodeState: NodeState<LiveBuilderImageSelectorNode>;
  videoWidth: number;
}

const LiveBuilderImageSelectorPlayback: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeState, active, storyId, videoWidth, nodesProgress } = props;
  const { node, nodeId } = nodeState;
  const optionEntries = Object.entries(node.options);
  const nodesProgressEntries = Object.entries(nodesProgress).filter(([, { type }]) => type === "Live Builder Image Selector");
  const [optionSelected, setSelectedOption] = React.useState<string | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const backgroundImageRef = React.useRef<HTMLImageElement | null>(null);
  const optionSelectedRef = React.useRef<string | null>(null);
  const hasChosen = React.useRef(false);
  const hasStarted = React.useRef(false);
  const hasFinished = React.useRef(false);
  const prevImagesRef = React.useRef<{ [nodeId: string]: [HTMLImageElement, ImageInfo, NodeSelection] }>({});
  const imagesOrderedByLayer = React.useRef<{ id: string; layer: number; type: 'new' | 'prev'; }[]>([]);
  const imagesRef = React.useRef<{ [optionId: string]: [HTMLImageElement, ImageInfo, LiveBuilderImageOption] }>({});
  const [ratio, setRatio] = React.useState(1);
  const [hovering, setIsHovering] = React.useState<{ id: string; type: 'new' | 'prev'; } | null>(null);
  const [dragging, setDragging] = React.useState<{ id: string; offset: { x: number; y: number; }; type: 'new' | 'prev'; } | null>(null);
  const coordConst = 1000;

  const [{ targetNodeId }] = Object.values(node.edges);

  let nodeContainerHidden = 'hidden';
  /** once the user selects their choice this will deactivate the node */
  if (active && !(nodeId in nodesProgress)) {
    nodeContainerHidden = '';
  }

  const dispatchSelection = React.useCallback(async (info: ImageInfo, option: LiveBuilderImageOption, random = false) => {
    try {
      const selections: {
        [nodeId: string]: NodeSelection;
      } = Object.fromEntries(Object.entries(prevImagesRef.current)
        .map(([nodeId, [, imageInfo, selection]]) => [
          nodeId,
          {
            ...selection,
            positionX: imageInfo.x,
            positionY: imageInfo.y
          }
        ]));

      selections[nodeId] = {
        type: 'Live Builder Image Selector',
        optionSelected: option,
        positionX: info.x,
        positionY: info.y,
        createdAt: Date.now(),
      };

      await dispatch(updateProfileStoryProgress({
        storyId,
        completed: false,
        nextNodeId: targetNodeId,
        completedNodePoints: (random) ? 0 : option.points,
        nodeSelection: selections,
      }));
      hasChosen.current = true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }

  }, [dispatch, nodeId, storyId, targetNodeId]);


  /**
   * This effect is used to make a selection for the user if 
   * they have not confirmed a selection by the time the time
   * for the node elapses
   */
  React.useEffect(() => {
    async function makeSelection() {
      try {
        const currentImages = imagesRef.current;
        /** selecte a random option */
        let [[, [, info, option]]] = Object.entries(currentImages);
        if (optionSelected) {
          /** if the user has selected choice use that */
          [, info, option] = currentImages[optionSelected];
        }
        await dispatchSelection(info, option, true);
      } catch (error) {
        console.error(error);
      }
    }

    if (active && !hasStarted.current) {
      hasStarted.current = true;
    }

    if (!active && hasStarted.current) {
      hasFinished.current = true;
      if (!hasChosen.current) {
        makeSelection();
      }
    }
  }, [dispatchSelection, active, optionSelected]);


  React.useEffect(() => {
    if (optionSelected) {
      const [, info] = imagesRef.current[optionSelected];
      info.inCanvas = true;
    }
  }, [optionSelected]);

  React.useEffect(() => {
    function computeFrame() {
      const backgroundImage = backgroundImageRef.current;
      const currentCanvas = canvasRef.current;
      if (currentCanvas && backgroundImage) {
        const ctx = currentCanvas.getContext('2d');
        if (ctx) {
          try {
            const bgImgWidth = backgroundImage.naturalWidth;
            const bgImgHeight = backgroundImage.naturalHeight;
            const canvasWidth = ctx.canvas.width;
            const canvasHeight = ctx.canvas.height;

            /** this clears the canvas which is necessary if later images are transparent */
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            const sx = 0; // The x-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            const sy = 0; // The y-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            const sWidth = bgImgWidth; // The width of the sub-rectangle of the source image to draw into the destination context. If not specified, the entire rectangle from the coordinates specified by sx and sy to the bottom-right corner of the image is used.
            const sHeight = bgImgHeight; // The height of the sub-rectangle of the source image to draw into the destination context.
            const dx = 0; // The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            const dy = 0; // The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            const dWidth = canvasWidth; // The width to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in width when drawn.
            const dHeight = canvasHeight; // The height to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in height when drawn.

            ctx.drawImage(backgroundImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);



            for (const { id, type } of imagesOrderedByLayer.current) {
              if (type === "prev") {
                const [imageEl, { x, y, adjWidth, adjHeight }] = prevImagesRef.current[id];
                ctx.drawImage(
                  imageEl, // source image
                  0, // sx
                  0, // sy
                  imageEl.naturalWidth, // sWidth
                  imageEl.naturalHeight, // sHeight
                  x - (adjWidth / 2), // dx
                  y - (adjHeight / 2), // dy
                  adjWidth, // dWidth
                  adjHeight // dHeight
                );
              } else {
                const [imageEl, { x, y, adjWidth, adjHeight }] = imagesRef.current[id];
                ctx.drawImage(
                  imageEl, // source image
                  0, // sx
                  0, // sy
                  imageEl.naturalWidth, // sWidth
                  imageEl.naturalHeight, // sHeight
                  x - (adjWidth / 2), // dx
                  y - (adjHeight / 2), // dy
                  adjWidth, // dWidth
                  adjHeight // dHeight
                );

              }
            }

            /**
             * this is how we recursively redraw each frame
             */
            setTimeout(() => {
              if (!hasFinished.current) {
                computeFrame();
              }
            }, 0);
          } catch (error) {
            console.log(error);
          }

        } else {
          console.log("couldn't find context");
        }
      }
    }

    if (active) {
      computeFrame();
    }
  }, [active]);

  /** 
   * this is necessary so that we can avoid using React.useCallback
   * for the function that computes each frame in the canvas element
   */
  React.useEffect(() => {
    optionSelectedRef.current = optionSelected;
    if (optionSelected) {
      const [, , { layer }] = imagesRef.current[optionSelected];
      const temp: {
        id: string;
        layer: number;
        type: "new" | "prev";
      }[] = [...imagesOrderedByLayer.current, {
        id: optionSelected,
        layer,
        type: "new",
      }];
      imagesOrderedByLayer.current = temp.sort(({ layer: layerA }, { layer: layerB }) => layerA - layerB);
    }
  }, [optionSelected]);

  /** this should update the ratio based on background video resize events */
  React.useEffect(() => {
    if (videoWidth) {
      const r = videoWidth / coordConst;
      setRatio(r);
    }
  }, [backgroundImageRef, videoWidth]);

  const nodesProgressLength = nodesProgressEntries.length;
  const prevImagesLength = Object.keys(prevImagesRef.current).length;
  /**
   * This initializes values for references to previous user selections
   */
  React.useEffect(() => {
    const backgroundImage = backgroundImageRef.current;
    if (active && backgroundImage && nodesProgressLength === prevImagesLength) {
      const { naturalWidth: bgImgWidth, naturalHeight: bgImgHeight } = backgroundImage;
      for (const [, [{ naturalHeight, naturalWidth }, info]] of Object.entries(prevImagesRef.current)) {
        info.adjWidth = coordConst * (naturalWidth / bgImgWidth);
        info.adjHeight = coordConst * (naturalHeight / bgImgHeight);
      }
    }
    imagesOrderedByLayer.current = Object.entries(prevImagesRef.current)
      .sort(([, [, , { optionSelected: { layer: layerA } }]], [, [, , { optionSelected: { layer: layerB } }]]) => layerA - layerB)
      .map(([id, [, , { optionSelected: { layer } }]]) => ({ id, type: 'prev', layer }));
  }, [active, nodesProgressLength, prevImagesLength]);

  const optionsLength = optionEntries.length;
  const imagesLength = Object.keys(imagesRef.current).length;
  /**
   * This initializes values for references to options users can select
   */
  React.useEffect(() => {
    if (active) {
      const backgroundImage = backgroundImageRef.current;
      if (backgroundImage && optionsLength === imagesLength) {
        const { naturalWidth: bgImgWidth, naturalHeight: bgImgHeight } = backgroundImage;
        for (const [, [imageEl, info, { defaultPosition }]] of Object.entries(imagesRef.current)) {
          const { naturalWidth, naturalHeight } = imageEl;
          if (info.x === -1) {
            let top = 0;
            let left = 0;
            const [ver, hoz] = defaultPosition.split('-');
            switch (ver) {
              case 'top': {
                top = coordConst * (1 / 6);
                break;
              }
              case 'middle': {
                top = coordConst * (1 / 2);
                break;
              }
              case 'bottom': {
                top = coordConst * (5 / 6);
                break;
              }
            }
            switch (hoz) {
              case 'left': {
                left = coordConst * (1 / 6);
                break;
              }
              case 'center': {
                left = coordConst * (1 / 2);
                break;
              }
              case 'right': {
                left = coordConst * (5 / 6);
                break;
              }
            }

            /** initialization of position */
            info.x = left;
            info.y = top;
          }
          /** set the width and height in the coordinate system of the canvas element */
          info.adjWidth = coordConst * (naturalWidth / bgImgWidth);
          info.adjHeight = coordConst * (naturalHeight / bgImgHeight);
        }
      }
    }
  }, [active, optionsLength, imagesLength]);

  /** this updates the position after each selection in a previous node */
  React.useEffect(() => {
    if (!active) {
      for (const [id, [, info]] of Object.entries(prevImagesRef.current)) {
        const progress = nodesProgress[id];
        if (progress) {
          info.x = progress.positionX;
          info.y = progress.positionY;
        }
      }
    }
  }, [active, nodesProgress]);

  const canvasCss: React.CSSProperties = {};
  if (hovering) {
    canvasCss.cursor = 'pointer';
  }

  function handleMove(clientX: number, clientY: number, rect: DOMRect) {
    const pointerX = (clientX - rect.left) / ratio;
    const pointerY = (clientY - rect.top) / ratio;
    if (dragging) {
      if (dragging.type === "new") {
        const [, image] = imagesRef.current[dragging.id];
        image.x = pointerX + dragging.offset.x;
        image.y = pointerY + dragging.offset.y;
      } else {
        const [, image] = prevImagesRef.current[dragging.id];
        image.x = pointerX + dragging.offset.x;
        image.y = pointerY + dragging.offset.y;
      }
    } else {
      let shouldHover: { id: string; type: "new" | "prev" } | null = null;
      let currLayer = -1;
      for (const [id, [, { x, y, adjWidth, adjHeight, inCanvas }, { layer }]] of Object.entries(imagesRef.current)) {
        if (inCanvas) {
          if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
            if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
              if (layer > currLayer) {
                shouldHover = { id, type: "new" };
                currLayer = layer;
              }
            }
          }
        }
      }
      for (const [id, [, { x, y, adjWidth, adjHeight }, { optionSelected: { layer } }]] of Object.entries(prevImagesRef.current)) {
        if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
          if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
            if (layer > currLayer) {
              shouldHover = { id, type: "prev" };
              currLayer = layer;
            }
          }
        }
      }
      if (shouldHover?.id !== hovering?.id) {
        setIsHovering(shouldHover);
      }
    }
  }

  function handleUp() {
    if (dragging) {
      setDragging(null);
    }
  }
  return (
    <div
      node-id={nodeState.nodeId}
      node-type={node.type}
      className={`live-builder-node-container ${nodeContainerHidden}`}
    >
      <canvas
        ref={canvasRef}
        className={'live-builder-node-canvas'}
        width={coordConst}
        height={coordConst}
        style={canvasCss}
        onMouseMove={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          handleMove(evt.clientX, evt.clientY, rect);
        }}
        onTouchMove={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const touch = evt.changedTouches[0];
          handleMove(touch.clientX, touch.clientY, rect);
        }}
        onMouseDown={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const pointerX = (evt.clientX - rect.left) / ratio;
          const pointerY = (evt.clientY - rect.top) / ratio;
          if (hovering) {
            if (hovering.type === "new") {
              const [, { x, y }] = imagesRef.current[hovering.id];
              setDragging({
                ...hovering,
                offset: {
                  x: x - pointerX,
                  y: y - pointerY,
                },
              });
            } else {
              const [, { x, y }] = prevImagesRef.current[hovering.id];
              setDragging({
                ...hovering,
                offset: {
                  x: x - pointerX,
                  y: y - pointerY,
                },
              });
            }
          }
        }}
        onTouchStart={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const touch = evt.changedTouches[0];
          const pointerX = (touch.clientX - rect.left) / ratio;
          const pointerY = (touch.clientY - rect.top) / ratio;
          let shouldDrag: { id: string; type: "new" | "prev" } | null = null;
          let currLayer = -1;
          for (const [id, [, { x, y, adjWidth, adjHeight, inCanvas }, { layer }]] of Object.entries(imagesRef.current)) {
            if (inCanvas) {
              if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
                if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
                  if (layer > currLayer) {
                    shouldDrag = { id, type: "new" };
                    currLayer = layer;
                  }
                  break;
                }
              }
            }
          }
          for (const [id, [, { x, y, adjWidth, adjHeight }, { optionSelected: { layer } }]] of Object.entries(prevImagesRef.current)) {
            if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
              if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
                if (layer > currLayer) {
                  shouldDrag = { id, type: "prev" };
                  currLayer = layer;
                }
                break;
              }
            }
          }
          if (shouldDrag) {
            let x = 0;
            let y = 0;
            if (shouldDrag.type === "new") {
              [, { x, y }] = imagesRef.current[shouldDrag.id];
            } else {
              [, { x, y }] = prevImagesRef.current[shouldDrag.id];
            }
            setDragging({
              ...shouldDrag,
              offset: {
                x: x - pointerX,
                y: y - pointerY,
              },
            });
          }
        }}
        onMouseUp={handleUp}
        onTouchEnd={handleUp}
        onMouseLeave={() => {
          if (hovering) {
            setIsHovering(null);
          }
          if (dragging) {
            setDragging(null);
          }
        }}
      />
      <img
        ref={backgroundImageRef}
        className="live-builder-node-background-image"
        src={`https://assetcdn.tappityapp.com/${node.imageOverlay}`}
      />
      {optionEntries.map(([optionId, option]) => {
        return (
          <img
            key={optionId}
            option-id={optionId}
            option-type="new-image"
            className="live-builder-option"
            ref={(el) => {
              if (el) {
                if (!imagesRef.current[optionId]) {
                  imagesRef.current[optionId] = [el, { x: -1, y: -1, adjWidth: 0, adjHeight: 0, inCanvas: false }, option];
                }
              }
            }}
            src={`https://assetcdn.tappityapp.com/${option.image}`}
          />
        );
      })}
      {nodesProgressEntries.map(([nodeId, option]) => {
        return (
          <img
            key={nodeId}
            option-node-id={nodeId}
            option-type="prev-selection"
            className="prev-builder-selection"
            ref={(el) => {
              if (el) {
                if (!prevImagesRef.current[nodeId]) {
                  prevImagesRef.current[nodeId] = [el, { x: option.positionX, y: option.positionY, adjWidth: 0, adjHeight: 0, inCanvas: false }, option];
                }
              }
            }}
            src={`https://assetcdn.tappityapp.com/${option.optionSelected.image}`}
          />
        );
      })}
      <div className="live-builder-node-interaction-panel">
        {optionSelected === null ? optionEntries.map(([optionId, option], index) => {
          return (
            <div
              key={index}
              option-id={optionId}
              className="builder-option-item"
              style={{
                backgroundImage: `url(https://assetcdn.tappityapp.com/${option.image})`,
              }}
              onClick={() => {
                setSelectedOption(optionId);
              }}
            />
          );
        }) : (
          <div
            className="live-builder-centered-option"
            onClick={async () => {
              try {
                const [, info, option] = imagesRef.current[optionSelected];
                if (info) {
                  await dispatchSelection(info, option);
                }
              } catch (error) {
                if (error instanceof Error) {
                  console.error(error.message);
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LiveBuilderImageSelectorPlayback;