import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchTags } from 'src/features/stories/tagsSlice';
import { fetchStories } from 'src/features/stories/storiesSlice';
import StoryCard from './StoryCard';
import Carousel from 'src/components/carousel/Carousel';
import FilterButtons from './filterButtons';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  UserProfile,
  LiveStory,
} from 'types';

interface Props {
  selectedProfile?: UserProfile;
}

const StoriesList: React.FC<Props> = ({ selectedProfile }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { stories, tags, loading } = useSelector((state: RootState) => {
    const loading = (state.storiesState.loading === 'pending' ||
      state.tagsState.loading === 'pending') ? 'pending' : 'idle';
    return {
      stories: state.storiesState.stories,
      tags: state.tagsState.tags,
      loading
    };
  }, shallowEqual);

  const [typeFilters, setTypeFilters] = React.useState<string[]>([]);
  const [topicFilters, setTopicFilters] = React.useState<string[]>([]);
  const [tinkererFilters, setTinkererFilters] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (stories === null) {
      dispatch(fetchStories());
    }
    if (tags === null) {
      dispatch(fetchTags());
    }
  }, [dispatch, stories, tags]);

  if (loading === 'pending' || !stories || !tags) {
    return (
      <div className='loading-screen'></div>
    );
  }

  const tagsAndStories = Object.entries(tags)
    .filter(([tagId]) => {
      if (!stories.byTagId[tagId]) return false;
      if (topicFilters.length > 0) {
        return topicFilters.includes(tagId);
      }
      return true;
    })
    .map(([tagId, tag]) => {
      const filteredStoryIds = stories.byTagId[tagId].filter((storyId) => {
        const story = stories.byId[storyId].story as LiveStory;
        if (!story || story.type !== 'live') return false;
        let shouldRetain = true;
        if (typeFilters.length > 0) {
          shouldRetain = shouldRetain && typeFilters.includes(story.subType);
        }
        if (tinkererFilters.length > 0) {
          shouldRetain = shouldRetain && tinkererFilters.includes(story.characterProfileId);
        }
        return shouldRetain;
      });
      return {
        tagId,
        tag,
        stories: filteredStoryIds.map((storyId) => stories.byId[storyId])
      };
    })
    .filter(({ stories }) => stories.length)
    .sort((a, b) => b.stories.length - a.stories.length);

  const tagElements = tagsAndStories.map(({ tagId, tag, stories }) => {
    return (
      <div key={tagId} className="tag-section">
        <h3>{tag.displayName}</h3>
        <Carousel>{
          stories.map((story) => {
            return (
              <StoryCard
                key={story.storyId}
                storyId={story.storyId}
                storyState={story}
                seasonId={story.seasonId}
              />
            );
          })
        }
        </Carousel>
      </div>
    );
  });

  return (
    <div id='stories-page' className="app-page">
      <header>
        <h1>👋 Hi {selectedProfile?.name || 'Tappernaut'}!</h1>
        <h2>Select a lesson below.</h2>
        <FilterButtons
          typeFilters={typeFilters}
          topicFilters={topicFilters}
          tinkererFilters={tinkererFilters}
          setTypeFilters={setTypeFilters}
          setTopicFilters={setTopicFilters}
          setTinkererFilters={setTinkererFilters}
        />
      </header>

      <div id="tag-list-wrapper">
        {!tagElements.length && <div className="no-lessons">No lessons found</div>}
        {tagElements}
      </div>
    </div>
  );
};

export default StoriesList;