import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { axiosInstance } from 'src/helpers';
import { useStripe } from '@stripe/react-stripe-js';
import {
  useSelector,
} from 'react-redux';
import {
  StarIcon,
  CheckIcon
} from '@heroicons/react/outline';
/** import types */
import type { Stripe } from 'stripe';
import type { RootState } from 'src/app/rootReducer';


interface Props {
  stripePrices: Stripe.Price[];
  promoCode: Stripe.PromotionCode | undefined;
  onClick: () => void;
}

const HomeschoolFamilyYearPlan: React.FC<Props> = ({ stripePrices, promoCode, onClick }: Props) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const [selectedPrice, setSelectedPrice] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (stripePrices && selectedPrice === null) {
      setSelectedPrice(stripePrices[0].id);
    }
  }, [stripePrices, selectedPrice]);

  const prices = stripePrices
    .filter(({ active }) => active)
    .sort(({ unit_amount: price_a }, { unit_amount: price_b }) => (price_a || 0) - (price_b || 0));
  const unit_amount = prices[prices.length - 1].unit_amount;
  const monthly = (unit_amount) ? unit_amount / (12 * 100) : 10;
  console.log(prices[prices.length - 1]);

  const SignInButton: React.FC = () => (
    <div className='footer-buttons-container'>
      <Link
        className={'tappity-form-submit-button'}
        to={{
          pathname: '/sign-in',
          state: {
            promoCode,
          }
        }}
      >
        Sign-in to checkout
      </Link>
      <div>
        <div>{"or don't have an account?"}</div>
        <Link
          className={'tappity-form-submit-button'}
          to={{
            pathname: '/landing',
            search: promoCode && `?promo=${promoCode.code}`,
            state: {
              promoCode,
              skipSplash: true,
            }
          }}
        >
          Create an account
        </Link>
      </div>
    </div>
  );

  const StartButton: React.FC = () => {
    const stripe = useStripe();

    return (
      <div className="category-footer">
        <div className="category-price-preview">
          <div>HOMESCHOOL FAMILY PRICE</div>
          
          <div className="lowest-price">
            <span className="strike-through">$2449.99 </span>
            $899.99</div>
          <div>/YEAR</div>
        </div>
        <button
          className={'tappity-form-submit-button-inverted'}
          // disabled={selectedPrice === null}
          onClick={async () => {
            try {
              if (selectedPrice) {
                if (promoCode?.code.includes("HOMESCHOOL")) {
                  const { data } = await axiosInstance.post<{ sessionId: string }>('/create-checkout-session', {
                    priceId: selectedPrice,
                    promoCode: promoCode?.code,
                    cancel_path: location.pathname + location.search,
                  });
                  if (stripe) {
                    await stripe.redirectToCheckout({ sessionId: data.sessionId });
                  } else {
                    console.error('missing stripe object, did you forget to wrap this component in the stripe Element componenent?');
                  }
                }
                else {
                  const trial_period_days = 14;
                  const { data } = await axiosInstance.post<{ sessionId: string }>('/create-checkout-session', {
                    priceId: selectedPrice,
                    promoCode: promoCode?.code,
                    trial_period_days,
                    cancel_path: location.pathname + location.search,
                  });
                  if (stripe) {
                    await stripe.redirectToCheckout({ sessionId: data.sessionId });
                  } else {
                    console.error('missing stripe object, did you forget to wrap this component in the stripe Element componenent?');
                  }
                }
                
               
              } else {
                console.log('missing selectedPrice');
              }
            } catch (error) {
              console.log('say what');
              console.error(error);
            }
          }}
        >
          CHECKOUT ➞
        </button>
      </div>
    );
  };

  const PricePreview: React.FC = () => {
    return (
      <div className="category-footer">
        <div className="category-price-preview">
            <div>DISCOUNT PRICING</div>
          <div className="lowest-price">
            <span className="strike-through">$2449.99 </span>
            $899.99
          </div>
          <div>/YEAR</div>
        </div>
      </div>
    );
  };

  return (
    <div className={'category-container summer-product'}>
      <img className="summer-image" src="homeschool-family-plan.png" />
      <br/>
      <div className="marketing-category-text-container">
        <div className="marketing-item-category highlighter"><StarIcon className="list-icon" width="22" /> 80 Small-Group Classes (value: $2400).</div>
              <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Daily Science Challenges (value: $50).</div>
              <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 500+ interactive science lessons.</div>
        <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Unlimited devices and child profiles.</div>
        <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 100% safe: Ad-free &#38; offline access.</div>
        <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Money back guarantee.</div>
      </div>
      {(!user?.isAnonymous) ? <StartButton /> : <div><SignInButton /> <PricePreview /></div> }
    </div>
  );
};

export default HomeschoolFamilyYearPlan;