import { combineReducers } from '@reduxjs/toolkit';
import userReducer from 'src/features/authentication/userSlice';
import tagsReducer from 'src/features/stories/tagsSlice';
import storiesReducer from 'src/features/stories/storiesSlice';
// seasons page is currently not something you can navigate too, but keeping it around for now
import seasonsReducer from 'src/features/seasons/seasonsSlice';
import characterProfilesReducer from 'src/features/characterProfiles/characterProfilesSlice';
import nodesReducer from 'src/features/nodes/nodesSlice';
import userProfilesReducer from 'src/features/userProfiles/userProfilesSlice';
import assetUploadsReducer from 'src/features/assetUploads/assetsUploadsSlice';
import chipReducer from 'src/features/chip/chipSlice';
import chipAssetsReducer from 'src/features/chip/chipAssetsSlice';
import revenuecatReducer from 'src/features/revenuecat/revenuecatSlice';
import profileActivitiesReducer from 'src/features/profileActivities/profileActivitiesSlice';

const rootReducer = combineReducers({
  userState: userReducer,
  tagsState: tagsReducer,
  storiesState: storiesReducer,
  seasonsState: seasonsReducer,
  characterProfilesState: characterProfilesReducer,
  nodesState: nodesReducer,
  userProfilesState: userProfilesReducer,
  assetUploadsState: assetUploadsReducer,
  chipState: chipReducer,
  chipAssetsState: chipAssetsReducer,
  revenuecatState: revenuecatReducer,
  profileActivitiesState: profileActivitiesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;