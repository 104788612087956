import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUserProfiles,
} from 'src/features/userProfiles/userProfilesSlice';
import {
  useHistory,
} from 'react-router-dom';
import UserProfileCard from './UserProfileCard';
import './index.css';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


const UserProfiles: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {
    selectedProfileId,
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);


  React.useEffect(() => {
    if (userProfiles === null) {
      dispatch(fetchUserProfiles());
    }
  }, [dispatch, userProfiles]);


  return (
    <div id={'user-profile-list'}>
      <div className={'user-profile-list-header'}>
        <div className={'child-profiles'}>Child Profiles</div>
        <button
          className={'add-child-profile'}
          onClick={() => history.push('/profiles/new')}
        >
          Add Profile
        </button>
        <br /><br />

      </div>
      <p>Create a profile for each of your kids and track their progress individually. To switch between users, simply select their name below and return Home.</p>
      {Object.entries(userProfiles ?? {}).filter(([, { deleted }]) => !deleted).map(([userProfileId]) => {
        const selected = userProfileId === selectedProfileId;
        return (
          <UserProfileCard
            key={userProfileId}
            userProfileId={userProfileId}
            selected={selected}
          />
        );
      })}
    </div>
  );
};

export default UserProfiles;