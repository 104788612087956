import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import { seasonConverter } from './helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { Season } from 'types';

interface SeasonsState {
  seasons: { [seasonId: string]: Season } | null;
  currentRequestId: undefined | string,
  loading: 'idle' | 'pending';
  error: null | SerializedError;
}

const initialState: SeasonsState = {
  seasons: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchSeasons = createAsyncThunk<{ [seasonId: string]: Season } | null, void, { state: RootState }>(
  'seasons/fetchSeasons',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().seasonsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const seasons: { [seasonId: string]: Season } = {};
    const seasonsColSnapshot = await firebase.firestore()
      .collection(generateFirestorePath('seasons'))
      .withConverter(seasonConverter)
      .where('status', 'in', ['published', 'preview'])
      .orderBy('priority', 'asc')
      .get();

    seasonsColSnapshot.forEach((seasonSnapshot) => {
      if (seasonSnapshot.exists) {
        const id = seasonSnapshot.id;
        const season = seasonSnapshot.data();
        seasons[id] = season;
      }
    });
    
    return seasons;
  }
);

const seasonsSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSeasons.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchSeasons.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.seasons = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchSeasons.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default seasonsSlice.reducer;