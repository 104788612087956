import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { axiosInstance } from 'src/helpers';
import { useLocation, Link } from 'react-router-dom';
import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import {
  CheckIcon,
  StarIcon,
} from '@heroicons/react/outline';
/** import types */
import type { Stripe } from 'stripe';
import type { RootState } from 'src/app/rootReducer';


interface Props {
  category: "with_classes" | "digital_only" | "with_four_classes" | "homeschool_plan" | "homeschool_family_plan" | "single_app_plan";
  stripePrices: Stripe.Price[];
  promoCode: Stripe.PromotionCode | undefined;
  prodegeTrxId: string | null;
  onCancel?: () => void;
}

const PriceSelector: React.FC<Props> = ({ stripePrices, promoCode, category, onCancel, prodegeTrxId }: Props) => {
  const { user } = useSelector((state: RootState) => state.userState);
  const stripe = useStripe();
  const [selectedPrice, setSelectedPrice] = React.useState<string | null>(null);
  const location = useLocation();

  React.useEffect(() => {

    if (stripePrices && selectedPrice === null && stripePrices.length > 1) {
      setSelectedPrice(stripePrices[1].id);
    } else if (stripePrices && selectedPrice === null && stripePrices.length > 0) {
      setSelectedPrice(stripePrices[0].id);
    }
  }, [stripePrices, selectedPrice]);

  const prices = stripePrices
    .filter(({ active }) => active)
    .sort(({ unit_amount: price_a }, { unit_amount: price_b }) => (price_a || 0) - (price_b || 0));

  const Details: React.FC = () => {
    if (category === "digital_only") {
      return (
        <div className="marketing-category-text-container">
          <div className="marketing-item-category-header">Digital Only</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 500+ Science Lessons.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 2-4 NEW Lessons every week.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Unlimited devices and child profiles.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 100% safe: Ad-free &#38; offline access.</div>
        </div>
      );
    }
    else if (category === "with_four_classes") {
      return (
        <div className="marketing-category-text-container">
          <div className="marketing-item-category-header">Summer of Science 2022 Package</div>
          <div className="marketing-item-category highlighter"><StarIcon className="list-icon" width="22" /> 12 Small-Group Classes (value: $360.00).</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Daily Science Challenges.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 500+ interactive science lessons and activities.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 2 NEW lessons every week.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Unlimited devices and child profiles.</div>
        </div>
      );
    }
    else {
      return (
        <div className="marketing-category-text-container">
          <div className="marketing-item-category-header">Digital + ClassPass</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 500+ Science Lessons.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 2-4 NEW Lessons every week.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> Unlimited devices and child profiles.</div>
          <div className="marketing-item-category"><CheckIcon className="list-icon" width="22" /> 100% safe: Ad-free &#38; offline access.</div>
          <div className="marketing-item-category highlighter"><StarIcon className="list-icon" width="22" /> Two 45-min Small-Group Classes per month (value: $79.99).</div>
        </div>
      );

    }
  };

  const SignInButton: React.FC = () => (
    <div className='footer-buttons-container'>
      <Link
        className={'tappity-form-submit-button'}
        to={{
          pathname: '/sign-in',
          state: {
            promoCode,
          }
        }}
      >
        Sign-in to start trial
      </Link>
      <div>
        {"Don't have an account?"}
        <Link
          className={'tappity-form-submit-button'}
          to={{
            pathname: '/landing',
            search: promoCode && `?promo=${promoCode.code}`,
            state: {
              promoCode,
              skipSplash: true,
            }
          }}
        >
          Create an account.
        </Link>
      </div>
    </div>
  );

  const StartButton: React.FC = () => (
    <button
      className={'tappity-form-submit-button'}
      disabled={selectedPrice === null}
      onClick={async () => {
        try {
          if (selectedPrice) {
            if (promoCode?.code.includes("SUMMER")) {
              const { data } = await axiosInstance.post<{ sessionId: string }>('/create-checkout-session', {
                priceId: selectedPrice,
                promoCode: promoCode?.code,
                cancel_path: location.pathname + location.search,
              });
              if (stripe) {
                await stripe.redirectToCheckout({ sessionId: data.sessionId });
              } else {
                console.error('missing stripe object, did you forget to wrap this component in the stripe Element componenent?');
              }
            } else {
              const trial_period_days = 14;
              const { data } = await axiosInstance.post<{ sessionId: string }>('/create-checkout-session', {
                priceId: selectedPrice,
                promoCode: promoCode?.code,
                trial_period_days,
                cancel_path: location.pathname + location.search,
                prodegeTrxId,
              });
              if (stripe) {
                await stripe.redirectToCheckout({ sessionId: data.sessionId });
              } else {
                console.error('missing stripe object, did you forget to wrap this component in the stripe Element componenent?');
              }
            }


          } else {
            console.log('missing selectedPrice');
          }
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {(selectedPrice) ? 'Continue' : 'Select a plan'}
    </button>
  );
  function getPriceTitle(price: Stripe.Price): string {
    if (price.unit_amount === null) return "";
    if (!price.recurring?.interval) return "";
    const interval = price.recurring.interval;
    const interval_count = price.recurring.interval_count;
    if (interval === "month" && interval_count === 1) {
      return "Monthly";
    } else if (interval === "month") {
      return `${interval_count}-months`;
    } else if (interval === "year") {
      return "Yearly";
    }
    return "";
  }
  function getMonthly(price: Stripe.Price): string {
    if (price.unit_amount === null) return "";
    if (!price.recurring?.interval) return "";
    const percentOff = promoCode?.coupon.percent_off;
    // let savings;
    // if (percentOff) {
    //   savings = ((price.unit_amount * ((100 - percentOff) / 100)) / 100).toFixed(2);
    // }

    let unit_amount = price.unit_amount / 100;
    if (percentOff) {
      unit_amount = unit_amount * ((100 - percentOff) / 100);
    }
    const interval = price.recurring.interval;
    const interval_count = price.recurring.interval_count;
    let monthly = unit_amount;
    if (interval === "month") {
      monthly = unit_amount / interval_count;
    } else if (interval === "year") {
      monthly = unit_amount / (interval_count * 12);
    }
    let monthlyStr = `Works out to $${monthly.toFixed(2)}/month`;
    if (percentOff) {
      monthlyStr = `Saves ${percentOff}%. ${monthlyStr}`;
    }
    return monthlyStr;
  }

  let freeTrial = true;

  if (promoCode?.code.includes("SUMMER")) {
    freeTrial = false;
  }

  return (
    <div className={'subscription-manager-container'}>
      <div className={'tappity-header'}>Select payment period</div>
      {freeTrial ?
        <div className="promo-code-banner">14-day free trial{promoCode ? ` and ${promoCode.coupon.percent_off}% off` : ''}</div>
        :
        <div className="tappity-sub-header">and start your Summer of Science</div>
      }
      <div className="price-options-container">
        <Details />
        <div className={'subscription-price-container'}>
          <div className="price-selector-header">SELECT PAYMENT PERIOD:</div> <br />
          <div className={'subscription-price-options'}>
            {prices.map((price, index) => {
              let priceSelected = '';
              if (price.id === selectedPrice) {
                priceSelected = 'price-selected';
              }
              let percentOff = promoCode?.coupon.percent_off;
              if (percentOff == null) {
                percentOff = promoCode?.coupon.amount_off;
              }
              if (promoCode?.code.includes("SUMMER")) {
                if (index == 0) {
                  return (
                    <div
                      className={`stripe-price-details ${priceSelected}`}
                      key={index}
                      onClick={() => {
                        setSelectedPrice(price.id);
                      }}
                    >
                      <div className={'stripe-price-interval'}>
                        <div className="price-title">3-Months</div>
                      </div>
                      <div>
                        {percentOff && (
                          <div className={`stripe-price-amount`}>
                            <div className='stripe-price-amount-coupon-applied'>
                              ${price.unit_amount !== null && price.unit_amount / 100}
                            </div>
                            ${price.unit_amount !== null && ((price.unit_amount - percentOff) / 100).toFixed(2)}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
                else {
                  return (
                    <span></span>
                  );
                }
              }
              return (
                <div
                  className={`stripe-price-details ${priceSelected}`}
                  key={index}
                  onClick={() => {
                    setSelectedPrice(price.id);
                  }}
                >
                  <div className={'stripe-price-interval'}>
                    <div className="price-title">{getPriceTitle(price)}</div>
                    <div>{getMonthly(price)}</div>
                  </div>
                  <div>
                    <div className={'stripe-price-nickname'}>
                      {price.nickname?.toUpperCase()}
                    </div>
                    <div className={percentOff ? 'stripe-price-amount-coupon-applied' : 'stripe-price-amount'}>
                      ${price.unit_amount !== null && price.unit_amount / 100}
                    </div>
                    {percentOff && (
                      <div className={`stripe-price-amount`}>
                        ${price.unit_amount !== null && ((price.unit_amount * ((100 - percentOff) / 100)) / 100).toFixed(2)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="price-selector-footer-btns">
          {onCancel && <button
            className={'tappity-form-submit-button'}
            onClick={onCancel} >Back</button>}
          {(!user?.isAnonymous) ? <StartButton /> : <SignInButton />}
        </div>
        <div className="small-print">Cancel any time.</div>
      </div>
    </div>
  );
};

export default PriceSelector;