import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedUserProfile,
  fetchUserProfileAsset,
} from 'src/features/userProfiles/userProfilesSlice';
import {
  useHistory,
} from 'react-router-dom';
import './index.css';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


interface Props {
  userProfileId: string;
  selected: boolean;
}
const UserProfileCard: React.FC<Props> = ({ userProfileId, selected }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { userProfiles } = useSelector((state: RootState) => state.userProfilesState);
  const userProfile = userProfiles?.[userProfileId];
  React.useEffect(() => {
    if (!userProfile?.['image/id-badge-selfie'].blobUrl) {
      dispatch(fetchUserProfileAsset({ userProfileId, assetName: 'image/id-badge-selfie' }));
    }
  }, [dispatch, userProfile, userProfileId]);


  console.log(userProfile?.['image/id-badge-selfie'].blobUrl);
  const userPhotoStyle: React.CSSProperties = {};
  const profilePhotoUrl = userProfile?.['image/id-badge-selfie'].blobUrl;
  if (profilePhotoUrl) {
    userPhotoStyle.backgroundImage = `url(${profilePhotoUrl})`;
  }

  return (
    <div onClick={() => dispatch(setSelectedUserProfile({ userProfileId }))} className={`user-profile-card ${selected ? 'selected-profile-card' : ''}`}>
      <div
        className={'user-profile-photo-name'}
      >
        <div style={userPhotoStyle} className={'user-profile-photo'}></div>
        <div className={'user-profile-details'}>
          <div className={'user-profile-name'}>
            {`${userProfile?.name || 'Tappernaut'} ${selected ? '(Selected)' : ''}`}
          </div>
          <div className={'user-profile-age-group'}>
            {userProfile?.ageGroup}
          </div>
        </div>
      </div>
      <div
        onClick={() => history.push(`/profiles/${userProfileId}`)}
        className={'edit-user-profile'}
      >
        Edit
      </div>
    </div>
  );
};

export default UserProfileCard;