import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
/** type imports */
import type { RootState } from 'src/app/rootReducer';

interface PrivateProps {
  children: React.ReactNode;
}

const SignedInRoute: React.FC<PrivateProps> = (props: PrivateProps) => {
  const location = useLocation();
  const {
    children,
  } = props;
  const { user } = useSelector((state: RootState) => state.userState);
  if (user && !user.isAnonymous) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  } else {
    return (
      <Redirect to={{
        pathname: "/landing",
        state: { from: location }
      }}
      />
    );
  }
};

export default SignedInRoute;
