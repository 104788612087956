import React from 'react';
import LandingBody from './LandingBody';
import './index.css';
import posthog from 'posthog-js';
import 'firebase/auth';
import firebase from 'firebase';
/** type imports */
// import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';
import type { User, UserProfile } from 'types';
import { Link, useLocation, useHistory } from 'react-router-dom';

interface Props {
  user: User;
  selectedProfileId: string;
  selectedProfile: UserProfile;
  skipSplash?: boolean;
}

const LandingPage: React.FC<Props> = ({ user, selectedProfileId, selectedProfile, skipSplash }: Props) => {
  const [email, setEmail] = React.useState("");
  const [step, setStep] = React.useState<0 | 1 | 2 >(0);
  const isAnonymous = user.isAnonymous;
  const topicsLength = selectedProfile.topics.length;
  const { pathname, search } = useLocation();
  const history = useHistory();
  const promoCode = (new URLSearchParams(search)).get('promo');
  const homeUrl = promoCode ? `/applanding?promo=${promoCode}` : '/';
  
  React.useEffect(() => {
    if (isAnonymous) {
      if (!email && !skipSplash) {
        setStep(0);
      } else {
        setStep(1);
      }
    } else {
        setStep(2);
    }
  }, [isAnonymous, email, topicsLength, skipSplash]);
  return (
    <div id="landing-page">
      {step > 0 && 
       <div className={'onboarding-top-nav'}>
       <Link to='/applanding'><img className={'logo'} src='/logo-updated-1.png' alt='logo' width="180" /></Link>
      
       {!isAnonymous && (
<button
 className={'sign-out-button'}
 onClick={async () => {
   try {
     posthog.reset();
     await firebase.auth().signOut();
     history.push(homeUrl);
   } catch (error) {
     console.log('Sign out error:', error);
   }
 }}
>
 Sign out
</button>
)}
</div>}
      {step > 1 && <div className={'step-indicator'}>Step {step} of 2</div>}
      <LandingBody
        step={step}
        selectedProfileId={selectedProfileId}
        email={email}
        setEmail={setEmail}
      />
    </div>
  );
};

export default LandingPage;