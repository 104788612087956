import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import { uploadNodeAsset } from 'src/features/assetUploads/assetsUploadsSlice';
import { useChip } from 'src/components/chip/ChipHook';
import { useRect } from 'src/RectHook';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  SelfieNode,
  NodeState,
  Story,
} from 'types';
/** Selie specific styling */
import './index.css';



interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  activeOverride?: boolean;
  nodeState: NodeState<SelfieNode>;
}
const Selfie: React.FC<Props> = ({ activeOverride = true, ...props }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [addJob] = useChip();
  const { byNodeId } = useSelector((state: RootState) => state.assetUploadsState);
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const videoPlayerRef = React.useRef<HTMLVideoElement>(null);
  const streamRef = React.useRef<MediaStream | null>(null);
  const nodeContainerRef = React.useRef<HTMLDivElement>(null);
  const [pictureData, setPictureData] = React.useState<Blob | null>(null);
  const nodeContainerRect = useRect(nodeContainerRef);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [cameraStatus, setCameraStatus] = React.useState<'waiting' | 'allowed' | 'blocked' | 'no-camera' | 'checking-devices'>('checking-devices');
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  const active = (nodeId === urlNodeId) && activeOverride;
  const assetUpload = byNodeId[nodeId];
  const shrinkFactor = 1; // I don't know why this is necessary
  let nextNodeId: string | null = null;
  /**
   * This will check to see if the user has a webcam and set the 
   * flag "hasVideoInput" to true or false
   */
  React.useEffect(() => {
    async function checkDevices(): Promise<void> {
      try {
        const { mediaDevices } = navigator;
        if (mediaDevices) {
          const devices = await mediaDevices.enumerateDevices();
          let foundVideoInput = false;
          for (const device of devices) {
            if (device.kind === 'videoinput') {
              foundVideoInput = true;
            }
          }
          if (foundVideoInput) {
            setCameraStatus('waiting');
          } else {
            setCameraStatus('no-camera');
          }
        } else {
          console.log('does not have "navigator.mediaDevices"');
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }
    if (cameraStatus === 'checking-devices') {
      checkDevices();
    }
  }, [cameraStatus]);

  const setUserMediaStream = React.useCallback(async () => {
    const { mediaDevices } = navigator;
    const currentVideo = videoPlayerRef.current;
    console.log('trying to enable the camera');
    if (active && currentVideo && mediaDevices && cameraStatus === 'waiting') {
      try {
        const stream = await mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'user',
          },
        });
        currentVideo.srcObject = stream;
        streamRef.current = stream;
        setCameraStatus('allowed');
        currentVideo.play()
          .then(() => {
            console.log('playing');
          })
          .catch((error) => {
            console.log(error);
          });

      } catch (error) {
        console.log('error occured when attempting', error);
        setCameraStatus('blocked');
      }
    }
  }, [active, cameraStatus]);

  const takePicture = React.useCallback(() => {
    const currentCanvas = canvasRef.current;
    if (currentCanvas) {
      currentCanvas.toBlob((blob) => {
        setPictureData(blob);
      }, 'image/png');
    }
  }, []);


  const computeFrame = React.useCallback((overlayImage: HTMLImageElement, height: number) => {
    const currentVideo = videoPlayerRef.current;
    const currentCanvas = canvasRef.current;
    const currentContainer = nodeContainerRef.current;
    if (currentCanvas && currentVideo && currentContainer) {
      const ctx = currentCanvas.getContext('2d');
      const containerRect = currentContainer.getBoundingClientRect();
      if (ctx) {
        try {
          const { videoWidth, videoHeight } = currentVideo;
          const sx = (videoWidth - videoHeight) / 2; // The x-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
          const sy = 0; // The y-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
          const sWidth = videoHeight; // The width of the sub-rectangle of the source image to draw into the destination context. If not specified, the entire rectangle from the coordinates specified by sx and sy to the bottom-right corner of the image is used.
          const sHeight = videoHeight; // The height of the sub-rectangle of the source image to draw into the destination context.
          const dx = 0; // The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
          const dy = 0; // The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
          const dHeight = height * (shrinkFactor); // The height to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in height when drawn.
          const dWidth = dHeight; // The width to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in width when drawn.
          // console.log(dHeight, dWidth);
          ctx.drawImage(currentVideo, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
          ctx.drawImage(overlayImage, 0, 0, overlayImage.width, overlayImage.height, 0, 0, dWidth, dHeight);
          setTimeout(() => {
            /**
             * This is important for resizing events. If you don't stop the recursion
             * every time the user changes the size of the screen a "new thread" of computeFrame is started
             */
            if (containerRect.height === height) {
              computeFrame(overlayImage, height);
            }
          }, 0);
        } catch (error) {
          console.log(error);
        }

      } else {
        console.log("couldn't find context");
      }
    }
  }, []);

  React.useEffect(() => {
    const currentVideo = videoPlayerRef.current;
    if (nodeWithAssets && currentVideo) {
      const img = new Image();
      img.src = nodeWithAssets.overlayImage;
      computeFrame(img, nodeContainerRect.height);
    }
  }, [computeFrame, nodeWithAssets, nodeContainerRect]);

  React.useEffect(() => {

    async function nextIfNoCamera(): Promise<void> {
      console.log("next if no camera");
      try {
        if (nextNodeId) {
          const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
          console.log(nextNodeUrl);
          history.replace(nextNodeUrl);
          // this stops the camera
          const tracks = streamRef.current?.getTracks();
          if (tracks) {
            for (const track of tracks) {
              track.stop();
            }
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }
    if (active && (cameraStatus === 'blocked' || cameraStatus === 'no-camera')) {
      nextIfNoCamera();
    }
  }, [active, cameraStatus, history, nextNodeId, seasonId, storyId]);

  React.useEffect(() => {
    setUserMediaStream();
  }, [setUserMediaStream]);

  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  React.useEffect(() => {
    if (nodeWithAssets && active) {
      addJob({
        audioClip: nodeWithAssets.botAudio,
        onFinish: async () => {
          console.log('finished job');
        },
      });
    }
  }, [addJob, nodeWithAssets, active]);

  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }

  React.useEffect(() => {
    if (assetUpload) {
      const { loading } = assetUpload;
      if (loading === 'pending') {
        console.log('loading is pending moving on');
        if (nextNodeId) {
          const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
          console.log(nextNodeUrl);
          history.replace(nextNodeUrl);
          // this stops the camera
          const tracks = streamRef.current?.getTracks();
          if (tracks) {
            for (const track of tracks) {
              track.stop();
            }
          }
        }
      }
    }
  }, [assetUpload, nextNodeId, history, seasonId, storyId]);

  if (!nodeWithAssets) {
    return <div>Loading....</div>;
  }

  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';

  }

  const VideoInstructions: React.FC = () => {
    switch (cameraStatus) {
      case 'blocked': {
        return (<div className={'dark-text'}>The camera is blocked.</div>);
      }
      case 'checking-devices': {
        return (<div className={'dark-text'}>Checking if you have a camera...</div>);
      }
      case 'no-camera': {
        return (<div className={'dark-text'}>No camera detected.</div>);
      }
      case 'waiting': {
        return (<div>Click allow to take a science photo.</div>);
      }
      case 'allowed': {
        return (<React.Fragment></React.Fragment>);
      }
    }
  };


  let canvasHidden = 'hidden';
  if (pictureData === null) {
    canvasHidden = '';
  }
  return (
    <div className={`node-container selfie-node-container ${nodeContainerHidden}`}>
      <div ref={nodeContainerRef} className={`webcam-mirror`}>
        {pictureData && (
          <img
            src={URL.createObjectURL(pictureData)}
            alt='selfie'
            className={'selfie-image'}
          />
        )}
        <video
          ref={videoPlayerRef}
          muted={true}
          style={{
            display: 'none',
          }}
        />
        <canvas
          ref={canvasRef}
          className={canvasHidden}
          width={nodeContainerRect.width}
          height={nodeContainerRect.width}
        />
        <VideoInstructions />
      </div>
      <div className='selfie-interaction-panel'>
        {(cameraStatus === 'allowed') ? (
          (pictureData === null) ? (
            <div
              className={'selfie-button'}
              onClick={takePicture}
            > 📷 </div>
          ) : (
            <React.Fragment>
              <div className={'option-items-container'}>
                <div
                  className={`option-item true-option`}
                  style={{
                    backgroundImage: 'url(/thumbs-up-button.png)',
                  }}
                  onClick={() => {
                    console.log('save picture and go to next node', nodeWithAssets.savedFileName);
                    const blob = new Blob([pictureData], { type: 'image/png' });
                    dispatch(uploadNodeAsset({ nodeId, fileName: nodeWithAssets.savedFileName, asset: blob }));
                  }}
                >
                </div>
                <div
                  className={`option-item false-option`}
                  style={{
                    backgroundImage: 'url(/thumbs-down-button.png)',
                  }}
                  onClick={() => {
                    setPictureData(null);
                  }}
                >
                </div>
              </div>
            </React.Fragment>
          )
        ) : (
          <div>
            <div className={"dark-text"}>Activate your camera to take science photos.</div>
            <button className={'activate-camera-button'} onClick={setUserMediaStream}>📷 Enable Camera</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Selfie;