import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
// type imports
import type { UserProfile } from 'types';


interface Props { selectedProfile?: UserProfile }
const SignedInButtons: React.FC<Props> = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>

      <Link className={`sidebar-menu-item ${(pathname === '/profiles') ? 'sidebar-item-selected' : ''}`} to='/profiles'>Child profiles</Link>
      <Link className={`sidebar-menu-item ${(pathname.indexOf('/progress') === 0) ? 'sidebar-item-selected' : ''}`} to='/progress'>Progress reports</Link>
      <Link className={`sidebar-menu-item ${(pathname === '/parent_menu') ? 'sidebar-item-selected' : ''}`} to='/parent_menu'>Parent menu</Link>
      <a className={`sidebar-menu-item`} target="_blank" rel='noopener noreferrer' href='https://www.tappityapp.com/adventuregroups'>Private classes</a>
    </React.Fragment>
  );
};

export default SignedInButtons;