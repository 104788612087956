import { createSlice, createAsyncThunk, SerializedError, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import 'firebase/storage';
import dayjs from 'dayjs';
import AssetFetcher from 'src/AssetFetcher';
import { userProfileConverter } from './helpers';
const { firestore, app } = firebase;
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type {
  UserProfile,
  StoryProgress,
  UserProfileAssets,
  ProfileAsset,
  NodeSelection,
} from 'types';

interface FileMetaData {
  bucket: string; //	NO
  generation: string; //	NO
  metageneration: string; //	NO
  fullPath: string; //	NO
  name: string; //	NO
  size: number; //	NO
  timeCreated: string; //	NO
  updated: string; //	NO
  md5Hash: string; //	YES on upload, NO on updateMetadata
  cacheControl: string; //	YES
  contentDisposition: string; //	YES
  contentEncoding: string; //	YES
  contentLanguage: string; //	YES
  contentType: string; //	YES
}
interface UserProfilesState {
  selectedProfileId: null | string;
  userProfiles: null | { [userProfileId: string]: UserProfile & UserProfileAssets };
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: UserProfilesState = {
  selectedProfileId: null,
  userProfiles: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchUserProfiles = createAsyncThunk<{ selectedProfileId: string; profiles: { [userProfileId: string]: UserProfile & UserProfileAssets } } | null, void, { state: RootState }>(
  'userProfiles/fetchUserProfiles',
  async (_, { getState, requestId, dispatch }) => {
    // const { user } = getState().userState;
    // must do this in order to make sign-in work
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId, selectedProfileId } = getState().userProfilesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { uid } = user;

    let profileId = selectedProfileId;
    const userProfiles: { [userProfileId: string]: UserProfile & UserProfileAssets } = {};

    const userProfilesColRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles`))
      .withConverter(userProfileConverter);

    let userProfilesSnapshot = await userProfilesColRef.get();

    if (userProfilesSnapshot.empty) {
      await userProfilesColRef.add({
        points: 0,
        topics: [],
        storyProgress: {},
        seasonProgress: {},
        interactionEvents: {},
        deleted: false,
      });
      userProfilesSnapshot = await userProfilesColRef.get();
    }

    userProfilesSnapshot.forEach((userProfile) => {
      if (userProfile.exists) {
        if (!profileId) {
          profileId = userProfile.id;
        }
        userProfiles[userProfile.id] = {
          ...userProfile.data(),
          'image/id-badge-selfie': {
            loading: 'idle',
            blobUrl: null,
            currentRequestId: undefined,
            error: null,
          },
        };
        dispatch(fetchUserProfileAsset({ userProfileId: userProfile.id, assetName: 'image/id-badge-selfie' }));
      }
    });
    if (profileId === null) {
      return null;
    }
    const ids = Object.keys(userProfiles);
    if (!(profileId in userProfiles)) {
      if (ids.length) {
        profileId = ids[0];
      } else {
        return null;
      }
    }
    return { selectedProfileId: profileId, profiles: userProfiles };
  }
);

export const addUserProfile = createAsyncThunk<{ userProfileId: string; userProfile: UserProfile } | null, { userProfile: Required<Pick<UserProfile, 'ageGroup' | 'name'>>; }, { state: RootState }>(
  'userProfiles/addUserProfile',
  async ({ userProfile }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId } = getState().userProfilesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { uid } = user;
    const userProfilesColRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles`))
      .withConverter(userProfileConverter);

    const newUserProfile: UserProfile = {
      ...userProfile,
      points: 0,
      topics: [],
      storyProgress: {},
      seasonProgress: {},
      interactionEvents: {},
      deleted: false,
    };
    const userProfileRef = await userProfilesColRef.add(newUserProfile);
    const userProfileSnapshot = await userProfileRef.get();
    const userProfileData = userProfileSnapshot.data();
    if (!userProfileData) {
      return null;
    }

    return {
      userProfileId: userProfileRef.id,
      userProfile: userProfileData,
    };
  }
);

export const updateUserProfile = createAsyncThunk<{ userProfileId: string; userProfile: UserProfile } | null, { userProfileId: string; userProfile: Required<Pick<UserProfile, 'ageGroup' | 'name'>>; }, { state: RootState }>(
  'userProfiles/updateUserProfile',
  async ({ userProfileId, userProfile }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId } = getState().userProfilesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { uid } = user;
    const userProfileRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles`))
      .withConverter(userProfileConverter)
      .doc(userProfileId);

    await userProfileRef.update(userProfile);
    const userProfileSnapshot = await userProfileRef.get();
    const userProfileData = userProfileSnapshot.data();
    if (!userProfileData) {
      return null;
    }

    return {
      userProfileId: userProfileRef.id,
      userProfile: userProfileData,
    };
  }
);

export const updateUserProfileTopics = createAsyncThunk<{ userProfileId: string; userProfile: UserProfile } | null, { userProfileId: string; topics: string[]; }, { state: RootState }>(
  'userProfiles/updateUserProfileTopics',
  async ({ userProfileId, topics }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId } = getState().userProfilesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { uid } = user;
    const userProfileRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles`))
      .withConverter(userProfileConverter)
      .doc(userProfileId);

    await userProfileRef.update({ topics });
    const userProfileSnapshot = await userProfileRef.get();
    const userProfileData = userProfileSnapshot.data();
    if (!userProfileData) {
      return null;
    }

    return {
      userProfileId: userProfileRef.id,
      userProfile: userProfileData,
    };
  }
);

export const fetchUserProfileAsset = createAsyncThunk<{ blobUrl: string } | null, { userProfileId: string; assetName: 'image/id-badge-selfie'; }, { state: RootState }>(
  'userProfiles/fetchUserProfileAsset',
  async ({ assetName, userProfileId }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { userProfiles } = getState().userProfilesState;
    if (!userProfiles || !userProfiles[userProfileId]) {
      return null;
    }
    const selectedProfile = userProfiles[userProfileId];
    const { currentRequestId, loading } = selectedProfile[assetName];
    if (loading !== 'pending' || currentRequestId !== requestId || selectedProfile['image/id-badge-selfie'].blobUrl) {
      return null;
    }
    const { uid } = user;
    const assetPath = `${uid}/${userProfileId}/${assetName}`;
    switch (assetName) {
      case 'image/id-badge-selfie': {
        const badgeFolderRef = app().storage('gs://tappity-user-uploads').ref().child(assetPath);
        const files = await badgeFolderRef.list();
        const items = (await Promise.all(files.items.map(async (item) => {
          const metadata: FileMetaData = await item.getMetadata();
          return metadata;
        }))).sort(({ timeCreated: timeCreatedA }, { timeCreated: timeCreatedB }) => dayjs(timeCreatedA).valueOf() - dayjs(timeCreatedB).valueOf());
        const firstItem = items.shift();
        const assetFetcher = new AssetFetcher();
        if (firstItem) {
          const { fullPath } = firstItem;
          try {
            const blobUrl = await assetFetcher.getAsset(fullPath, true);
            return { blobUrl };
          } catch (error) {
            console.log('error', error);
            return null;
          }
        } else {
          return null;
        }
        break;
      }
      default: {
        return null;
      }
    }
  }
);


interface StoryProgessUpdate {
  storyId: string;
  completed: false;
  nextNodeId: string;
  completedNodePoints: number;
  nodeSelection?: { [nodeId: string]: NodeSelection };
}

interface StoryProgressCompleted {
  storyId: string;
  completed: true;
}


export const updateProfileStoryProgress = createAsyncThunk<{ userProfile: UserProfile } | null, StoryProgessUpdate | StoryProgressCompleted, { state: RootState }>(
  'userProfiles/updateProfileStoryProgress',
  async (thunk, { getState }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { selectedProfileId, userProfiles } = getState().userProfilesState;
    if (!selectedProfileId || !userProfiles || !userProfiles[selectedProfileId]) {
      return null;
    }
    const { uid } = user;
    const { completed, storyId } = thunk;
    const selectedProfile = userProfiles[selectedProfileId];
    const { storyProgress } = selectedProfile;
    let progress: StoryProgress = {
      completed: false,
      completions: 0,
      updatedAt: dayjs().valueOf(),
      nodes: {},
    };

    if (storyProgress[storyId]) {
      progress = { ...storyProgress[storyId] };
    }
    const batch = firestore().batch();

    const userProfileRef = firestore()
      .collection(generateFirestorePath(`appUsers/${uid}/profiles`))
      .withConverter(userProfileConverter)
      .doc(selectedProfileId);

    if (thunk.completed) {
      const profilePoints = (selectedProfile.points || 0) + (progress.score || 0);
      progress.completed = completed;
      progress.completions = (progress.completions || 0) + 1;
      progress.nodes = {};
      delete progress.score;
      delete progress.lastVisitedNodeId;
      batch.update(userProfileRef, {
        points: profilePoints,
      });
    } else {
      const { completedNodePoints, nextNodeId, nodeSelection } = thunk;
      progress.completed = completed;
      progress.lastVisitedNodeId = nextNodeId;
      progress.score = (progress.score || 0) + completedNodePoints;

      if (nodeSelection) {
        progress.nodes = nodeSelection;
      }
    }

    batch.update(userProfileRef, {
      [`storyProgress.${storyId}`]: progress,
    });

    await batch.commit();

    const userProfileSnapshot = await userProfileRef.get();

    const userProfile = userProfileSnapshot.data();

    if (!userProfile) {
      return null;
    }

    return { userProfile };
  }
);

const userProfilesSlice = createSlice({
  name: 'userProfiles',
  initialState,
  reducers: {
    setSelectedUserProfile(state, { payload }: PayloadAction<{ userProfileId: string }>) {
      state.selectedProfileId = payload.userProfileId;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfiles.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchUserProfiles.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.loading === 'pending' && state.currentRequestId === requestId) {
        state.selectedProfileId = payload.selectedProfileId;
        state.userProfiles = payload.profiles;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(fetchUserProfiles.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(addUserProfile.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(addUserProfile.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.userProfiles && state.loading === 'pending' && state.currentRequestId === requestId) {
        const { userProfile, userProfileId } = payload;
        state.userProfiles[userProfileId] = {
          ...userProfile,
          'image/id-badge-selfie': {
            loading: 'idle',
            blobUrl: null,
            currentRequestId: undefined,
            error: null,
          },
        };
        if (state.selectedProfileId === null) {
          state.selectedProfileId = userProfileId;
        }
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(addUserProfile.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(updateUserProfile.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.userProfiles && state.loading === 'pending' && state.currentRequestId === requestId) {
        const { userProfile, userProfileId } = payload;
        state.userProfiles[userProfileId].name = userProfile.name;
        state.userProfiles[userProfileId].ageGroup = userProfile.ageGroup;
        if (state.selectedProfileId === null) {
          state.selectedProfileId = userProfileId;
        }
      }
      state.currentRequestId = undefined;
      state.loading = 'idle';
      state.error = null;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(updateUserProfileTopics.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(updateUserProfileTopics.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.userProfiles && state.loading === 'pending' && state.currentRequestId === requestId) {
        const { userProfile, userProfileId } = payload;
        state.userProfiles[userProfileId].topics = userProfile.topics;
        if (state.selectedProfileId === null) {
          state.selectedProfileId = userProfileId;
        }
      }
      state.currentRequestId = undefined;
      state.loading = 'idle';
      state.error = null;
    });
    builder.addCase(updateUserProfileTopics.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchUserProfileAsset.pending, (state, action) => {
      const {
        meta: {
          requestId,
          arg: {
            assetName,
            userProfileId,
          }
        },
      } = action;

      let profileAsset: ProfileAsset | null = null;
      if (state.userProfiles) {
        profileAsset = state.userProfiles[userProfileId][assetName];
      }
      if (profileAsset && profileAsset.loading === 'idle') {
        profileAsset.loading = 'pending';
        profileAsset.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchUserProfileAsset.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
          arg: {
            assetName,
            userProfileId,
          },
        },
        payload,
      } = action;

      let profileAsset: ProfileAsset | null = null;
      if (state.userProfiles) {
        profileAsset = state.userProfiles[userProfileId][assetName];
      }
      if (payload && profileAsset && profileAsset.loading === 'pending' && profileAsset.currentRequestId === requestId) {
        profileAsset.blobUrl = payload.blobUrl;
        profileAsset.loading = 'idle';
        profileAsset.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchUserProfileAsset.rejected, (state, action) => {
      const {
        meta: {
          requestId,
          arg: {
            assetName,
            userProfileId,
          }
        },
        error,
      } = action;

      console.log('fetchUserProfileAsset.rejected');
      let profileAsset: ProfileAsset | null = null;
      if (state.userProfiles?.[userProfileId]?.[assetName]) {
        profileAsset = state.userProfiles[userProfileId][assetName];
      }

      if (profileAsset && profileAsset.loading === 'pending' && profileAsset.currentRequestId === requestId) {
        profileAsset.loading = 'idle';
        profileAsset.error = error;
        profileAsset.currentRequestId = undefined;
      }
    });
    builder.addCase(updateProfileStoryProgress.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(updateProfileStoryProgress.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.userProfiles && state.loading === 'pending' && state.currentRequestId === requestId) {
        const { userProfile } = payload;
        if (state.selectedProfileId) {
          state.userProfiles[state.selectedProfileId] = {
            ...state.userProfiles[state.selectedProfileId],
            ...userProfile,
          };
          state.currentRequestId = undefined;
          state.loading = 'idle';
          state.error = null;
        }
      }
    });
    builder.addCase(updateProfileStoryProgress.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { setSelectedUserProfile } = userProfilesSlice.actions;

export default userProfilesSlice.reducer;