import React from 'react';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import { emailFrequencySettingOptions } from 'src/helpers';
import { userConverter } from 'src/features/authentication/helpers';
import './index.css';
/** import types */



const ProgressUpdateEmailsManager: React.FC = () => {
  const { firestore, auth } = firebase;
  const uid = auth().currentUser?.uid;
  const db = firestore();
  const userRef = (uid) ? db.collection('appUsers').withConverter(userConverter).doc(uid) : null;
  const [emailFreq, setEmailFreq] = React.useState<typeof emailFrequencySettingOptions[keyof typeof emailFrequencySettingOptions] | null>(null);
  const [loading, setLoading] = React.useState<'loading' | 'idle'>('idle');
  React.useEffect(() => {
    let unsub: (() => void) | undefined;
    if (userRef) {
      unsub = userRef.onSnapshot((snapShot) => {
        const data = snapShot.data();
        if (data) {
          setEmailFreq(data.emailFrequencySetting);
        }
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [userRef]);
  if (!uid || !userRef || !emailFreq) {
    return (
      <div className={'progress-update-emails-manager-container'}>
        <div className={'tappity-header'}>Loading...</div>
        
      </div>
    );
  }

  return (
    <div className={'progress-update-emails-manager-container'}>
      <div className={'tappity-header'}>Progress update emails</div>
      <div>How frequently would you like to receive email updates about your child&apos;s progress?</div>
      {<select
        className={'email-frequency-dropdown'}
        disabled={loading === 'loading'}
        onChange={async (data) => {
          try {
            setLoading('loading');
            const updatedEmailFreq = Number(data.target.value);
            await userRef.update({
              emailFrequencySetting: updatedEmailFreq,
            });
            setLoading('idle');
          } catch (error) {
            console.error(error);
          }
        }}
        value={(loading === 'idle') ? emailFreq : undefined}
      >
        {Object.entries(emailFrequencySettingOptions).map(([optionKey, optionValue], index) => {
          return (<option key={index} value={optionValue}>{optionKey}</option>);
        })}
      </select>}
      
    </div>
  );
};

export default ProgressUpdateEmailsManager;