import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import 'firebase/firestore';
import { characterProfileConverter, transformCharacterProfile } from './helpers';
import { generateFirestorePath } from 'src/helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { CharacterProfile } from 'types';



export interface CharacterProfileState {
  characterProfileId: string;
  characterProfile: CharacterProfile;
  characterProfileWithAssets: null | CharacterProfile;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

interface CharacterProfilesState {
  byCharacterProfileId: { [characterProfileId: string]: CharacterProfileState } | null;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: CharacterProfilesState = {
  byCharacterProfileId: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchCharacterProfiles = createAsyncThunk<{ [characterProfileId: string]: CharacterProfileState } | null, void, { state: RootState }>(
  'characterProfiles/fetchCharacterProfiles',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { loading, currentRequestId } = getState().characterProfilesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const characterProfiles: { [characterProfileId: string]: CharacterProfileState } = {};
    const characterProfilesSnapshot = await firebase.firestore()
      .collection(generateFirestorePath('characterProfiles'))
      .withConverter(characterProfileConverter)
      .orderBy('createdAt', 'desc')
      .get();

    characterProfilesSnapshot.forEach((characterProfile) => {
      if (characterProfile.exists) {
        characterProfiles[characterProfile.id] = {
          characterProfileId: characterProfile.id,
          characterProfile: characterProfile.data(),
          characterProfileWithAssets: null,
          loading: 'idle',
          currentRequestId: undefined,
          error: null,
        };
      }
    });

    return characterProfiles;
  }
);

export const fetchCharacterProfileAssets = createAsyncThunk<CharacterProfile | null, { characterProfileId: string }, { state: RootState }>(
  'characterProfiles/fetchCharacterProfileAssets',
  async ({ characterProfileId }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { byCharacterProfileId } = getState().characterProfilesState;
    const characterProfileRef = byCharacterProfileId?.[characterProfileId];
    if (characterProfileRef === undefined || characterProfileRef.loading !== 'pending' || requestId !== characterProfileRef.currentRequestId) {
      return null;
    }
    const characterProfileWithAssets = await transformCharacterProfile(characterProfileRef.characterProfile);
    return characterProfileWithAssets;
  }
);

const characterProfilesSlice = createSlice({
  name: 'characterProfiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCharacterProfiles.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchCharacterProfiles.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.loading === 'pending' && state.currentRequestId === requestId) {
        state.byCharacterProfileId = payload;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(fetchCharacterProfiles.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });

    builder.addCase(fetchCharacterProfileAssets.pending, (state, action) => {
      const {
        meta: {
          arg: { characterProfileId },
          requestId,
        },
      } = action;
      const characterProfileRef = (state.byCharacterProfileId) ? state.byCharacterProfileId[characterProfileId] : null;
      if (characterProfileRef?.loading === 'idle') {
        characterProfileRef.loading = 'pending';
        characterProfileRef.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchCharacterProfileAssets.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { characterProfileId },
          requestId,
        },
        payload,
      } = action;
      const characterProfileRef = (state.byCharacterProfileId) ? state.byCharacterProfileId[characterProfileId] : null;
      if (payload && characterProfileRef?.currentRequestId === requestId) {
        characterProfileRef.characterProfileWithAssets = payload;
        characterProfileRef.loading = 'idle';
        characterProfileRef.error = null;
        characterProfileRef.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchCharacterProfileAssets.rejected, (state, action) => {
      const {
        meta: {
          arg: { characterProfileId },
          requestId,
        },
        error,
      } = action;
      const characterProfileRef = (state.byCharacterProfileId) ? state.byCharacterProfileId[characterProfileId] : null;

      if (characterProfileRef?.loading === 'pending' && characterProfileRef.currentRequestId === requestId) {
        characterProfileRef.loading = 'idle';
        characterProfileRef.error = error;
        characterProfileRef.currentRequestId = undefined;
      }
    });

  },
});

export default characterProfilesSlice.reducer;