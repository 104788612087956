import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubscriptionManager from 'src/components/ParentMenu/SubscriptionManager';
import CTAForm from './CTAForm';
import './Landing.css';
import background1 from './assets/background1.jpg';
import background2 from './assets/background2.jpg';
import woodBackground from './assets/wood-bg.jpg';

import ClassCarousel from './ClassCarousel';
interface Props {
  setEmail: (email: string) => void;
}

const Landing: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');
  const signInUrl = promoCode ? `/sign-in?promo=${promoCode}` : '/sign-in';

  if (promoCode) {
    return (
      <>
        <div className={'onboarding-logo'}> <img className={'logo'} src='/logo-purple.png' alt='logo' width="140" /></div>
        <SubscriptionManager />
      </>
    );
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const form = e.target as typeof e.target & {
      elements: { email: { value: string } }
    };
    props.setEmail(form.elements.email.value);
  };

  return (
    <div id="landing">
      <header style={{ backgroundImage: `url(${background1}), url(${background2})` }}>
        <nav>
          <h1 className="logo">Tappity</h1>
          <Link className="login" to={signInUrl}>Log in</Link>
        </nav>
        <div className="main-heading">
          <div className="hero-text">
            <h1>Every Science Topic in One Place!</h1>
            <p>Over 100,000 kids ages 4-10 love Tappity&apos;s interactive lessons on topics they are curious about.</p>
          </div>
        </div>
      </header>

      <div className="signup-box">
        <p>Sign-up for a 2-week free trial.</p>
        <CTAForm onSubmit={onSubmit} />
      </div>

      <section className="stats" style={{ backgroundImage: `url(${woodBackground})` }}>
        <div className="inner-grid">
          <div>
            <span className="stat">4000+ </span>
            <span className="text">5 Star Reviews</span>
          </div>
          <div>
            <span className="stat">30 Million </span>
            <span className="text">Classes completed</span>
          </div>
          <div>
            <span className="stat">1000+ </span>
            <span className="text">Different classes</span>
          </div>
          <div>
            <span className="stat">10-30 </span>
            <span className="text">Minutes per class</span>
          </div>
        </div>
      </section>

      <section className="classes">
        <h2>Out of this world interactive lessons</h2>
        <p>Our lessons are designed in house by experienced educators AND entertainers. Youll only find amazing interactive lessons like ours in the Tappity app.</p>
        <div className="class-carousel">
          <ClassCarousel />
        </div>
      </section>

      <section className="promise">
        <div>
          <h2>The Tappity Promise</h2>
          <p>If we don&apos;t have your child&apos;s favorite topic, contact us and we will make a class on it within 90 days. Seriously!</p>
        </div>
      </section>

      <section className="praise">
        <div className="inner-grid">
          <div className="reviewList">
            <div className="review">
              <div className="author">
                <img src="parent-beth.jpg" />
                <span className="firstline">
                  <span className="parentname">Beth Goodwin</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">April 14th</span>
              </div>
              <div className="content">
                My kids have completely fallen in love with science thanks to Tappity and all the amazing teachers they have.
              </div>
              <div className="likes">
                163
              </div>
            </div>
            <div className="review">
              <div className="author">
                <img src="parent-michael.jpg" />
                <span className="firstline">
                  <span className="parentname">Michael Williams</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">Jan 28th</span>
              </div>
              <div className="content">
                Tappity is like having a personal science teacher for your child. My 5 and 7 year olds are still enthralled after a full year.
              </div>
              <div className="likes">
                194
              </div>
            </div>
            <div className="review">
              <div className="author">
                <img src="parent-audra.jpg" />
                <span className="firstline">
                  <span className="parentname">Audra</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">Jan 4th</span>
              </div>
              <div className="content">
                My kid is obsessed with the interactive features, and he has learned and retained a truly shocking amount of info.
              </div>
              <div className="likes">
                387
              </div>
            </div>
          </div>
          <div>
            <h2>Loved by thousands of families around the 🌎</h2>
            <p>Every week over 100,000 lessons are completed in Tappity.</p>
          </div>
        </div>
      </section>
      <section className="lastcall">
        <h2>Sign-up for a 2-week free trial.</h2>
        <p>Limited time only.</p>
        <CTAForm onSubmit={onSubmit} />
      </section>
      <footer>
        <h1 className="logo">Tappity</h1>
        <nav>
          <Link className="login" to={signInUrl}>Log in</Link>
          <a href="mailto:hey@tappityapp.com">Contact</a>
        </nav>
        <p>Copyright &copy; 2021 Tappity, Inc. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Landing;