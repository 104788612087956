import React from 'react';
import StoriesList from 'src/components/stories/StoryList';
import SeasonPage from 'src/components/seasons/SeasonPage';
import {
  Route,
  Switch,
} from 'react-router-dom';
import './index.css';
/** import types */
import type { UserProfile } from 'types';

interface Props {
  selectedProfile?: UserProfile;
}

const Home: React.FC<Props> = ({ selectedProfile }: Props) => {
  return (
    <div id='home-container'>
      <Switch>
        <Route path={'/seasons/:seasonId'}>
          <SeasonPage />
        </Route>
        <Route exact path={['/', '/seasons']}>
          <StoriesList selectedProfile={selectedProfile}/>
        </Route>
      </Switch>
    </div>
  );
};

export default Home;