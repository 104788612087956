import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import 'firebase/firestore';
import { generateFirestorePath } from 'src/helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { TagDefinition, TagDefinitionSnapshot, SnapshotOptions } from 'types';

interface TagsState {
  tags: { [tagId: string]: TagDefinition } | null;
  currentRequestId: undefined | string,
  loading: 'idle' | 'pending';
  error: null | SerializedError;
}

const initialState: TagsState = {
  tags: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchTags = createAsyncThunk<{ [tagId: string]: TagDefinition } | null, void, { state: RootState }>(
  'tags/fetchTags',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { currentRequestId, loading } = getState().tagsState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const tags: { [tagId: string]: TagDefinition } = {};
    const tagsColSnapshot = await firebase.firestore()
      .collection(generateFirestorePath('tagDefinitions'))
      .withConverter(tagConverter)
      .where('isUserFacing', '==', true)
      .get();

    tagsColSnapshot.forEach((tagSnapshot) => {
      if (tagSnapshot.exists) {
        const id = tagSnapshot.id;
        const tag = tagSnapshot.data();
        tags[id] = tag;
      }
    });

    return tags;
  }
);


const tagConverter: firebase.firestore.FirestoreDataConverter<TagDefinition> = {
  toFirestore(tag: TagDefinition) {
    const snapshot = {
      ...tag,
      createdAt: firebase.firestore.Timestamp.fromMillis(tag.createdAt),
      updatedAt: (tag.updatedAt !== undefined) ? firebase.firestore.Timestamp.fromMillis(tag.updatedAt) : undefined,
    };

    if (snapshot.updatedAt === undefined) {
      delete snapshot.updatedAt;
    }

    return snapshot;
  },
  fromFirestore(snapshot: TagDefinitionSnapshot, options: SnapshotOptions): TagDefinition {
    const data = snapshot.data(options);
    const tag: TagDefinition = {
      ...data,
      createdAt: data.createdAt.toMillis(),
      updatedAt: data.updatedAt?.toMillis(),
    };
    return tag;
  },
};


const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.tags = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchTags.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default tagsSlice.reducer;