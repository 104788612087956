import React from 'react';
import { useSelector } from 'react-redux';
import {
  StoryState,
} from 'src/features/stories/storiesSlice';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import './StoryCard.css';

interface Props {
  storyState: StoryState;
  storyId: string;
  seasonId: string;
}

const StoryCard: React.FC<Props> = ({ seasonId, storyId, storyState }: Props) => {
  const history = useHistory();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { story } = storyState;
  const { byCharacterProfileId } = useSelector((state: RootState) => state.characterProfilesState);
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');

  const storyCardStyle: React.CSSProperties = {
    backgroundImage: `url(https://assetcdn.tappityapp.com/${story.coverImage}`,
    backgroundSize: 'contain',
    backgroundPosition: 'top',
  };

  function handleClick() {
    let storyUrl = `/seasons/${seasonId}/stories/${storyId}`;
    if (promoCode) {
      storyUrl = storyUrl + `?promo=${promoCode}`;
    }
    history.push(storyUrl);
  }
  function getLessonTypeIcon() {
    if (story.type === 'live') {
      const imgLookup: Record<typeof story['subType'], string> = {
        'trivia': 'lesson-type-icons/trivia-icon.png',
        'mission': 'lesson-type-icons/mission-icon.png',
        'build_a_blank': 'lesson-type-icons/build-a-icon.png',
        'guess_who': 'lesson-type-icons/gw-icon.png',
        'sos': 'lesson-type-icons/sos-icon.png',
        'field_trip': 'lesson-type-icons/field-trip-icon.png',
        'drawing': 'lesson-type-icons/drawing-icon.png',
        // these are set to defaults
        'activity': 'lesson-type-icons/mission-icon.png',
        'narrative': 'lesson-type-icons/mission-icon.png',
        'badge': 'lesson-type-icons/mission-icon.png',
      };
      if (story.subType in imgLookup) {
        return imgLookup[story.subType];
      } else {
        return 'lesson-type-icons/mission-icon.png';
      }
    } else {
      return 'lesson-type-icons/build-a-icon.png';
    }
  }

  function getLessonTinkererImage() {
    if (story.type === 'live') {
      const characterProfileState = byCharacterProfileId?.[story.characterProfileId];
      return `https://assetcdn.tappityapp.com/${characterProfileState?.characterProfile?.profileImage ?? 'lesson-type-icons/gw-icon.png'}`;
    }
    return 'lesson-type-icons/gw-icon.png';
  }

  switch (story.type) {
    case 'feature': {
      return (
        <div
          className='story-card feature-story'
          id={storyId}
          style={storyCardStyle}
          ref={ref}
          onClick={handleClick}
        >
          <div className={'story-header'}><b>Tappity</b> ORIGINAL</div>
          <img className={'play-button'} src='/play_button_transparent.png' alt='play' />
          <div className={'story-title'}>{story.title}</div>
        </div>
      );
    }
    case 'mini': {
      return (
        <div
          className='story-card mini-story'
          style={storyCardStyle}
          id={storyId}
          ref={ref}
          onClick={handleClick}
        >
          <img className={'play-button'} src='/simple_play.svg' alt='play' />
          <div className={'story-title'}>{story.title}</div>
        </div>
      );
    }
    case 'checkpoint': {
      return (
        <div
          className='story-card checkpoint-story'
          ref={ref}
          id={storyId}
          onClick={handleClick}
        >
          <img className={'play-button'} src='/play_button_transparent.png' alt='play' />
          <div className={'story-title'}>{story.title}</div>
        </div>
      );
    }
    case 'live': {
      return (
        <div
          className='story-card live-story'
          style={storyCardStyle}
          id={storyId}
          ref={ref}
          onClick={handleClick}
        >
          <img className={'play-button'} src='/play_button_transparent.png' alt='play' />
          <div className={'story-card-info-container'}>
            <img className={'profile-pic'} src={getLessonTinkererImage()} alt='tinkerer' />
            <img className={'lesson-type-icon'} src={getLessonTypeIcon()} alt='lesson type' />
            <div className={'story-title'}>{story.title}</div>
          </div>

        </div>
      );
    }
    default: {
      return <div>hmmm something is missing</div>;
    }
  }
};

export default StoryCard;