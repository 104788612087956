import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNodesBySeasonByStory } from 'src/features/nodes/nodesSlice';
import { fetchCharacterProfiles } from 'src/features/characterProfiles/characterProfilesSlice';
import Chip from 'src/components/chip';
import LAMCQuestion from '../nodes/LAMCQuestion';
import Video from '../nodes/Video';
import Phone from '../nodes/Phone';
import Selfie from '../nodes/Selfie';
import ScreenCleaning from '../nodes/ScreenCleaning';
import DisplayIdBadge from '../nodes/DisplayIdBadge';
import Pulley from '../nodes/Pulley';
import PointsDisplay from '../nodes/PointsDisplay';
import StoryPoints from '../StoryPoints';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  VideoNode,
  IdBadgeNode,
  LAMCQuestionNode,
  PhoneNode,
  NodeState,
  SelfieNode,
  ScreenCleaningNode,
  PulleyNode,
  PointsDisplayNode,
} from 'types';




interface Props {
  seasonId: string;
  storyId: string;
  nodeId?: string;
}
const StoryPlaybackPlayer: React.FC<Props> = ({ seasonId, storyId }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    byCharacterProfileId,
  } = useSelector((state: RootState) => state.characterProfilesState);
  const {
    bySeasonId,
  } = useSelector((state: RootState) => state.nodesState);
  const {
    selectedProfileId,
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);
  const storyRef = bySeasonId[seasonId]?.byStoryId[storyId];
  React.useEffect(() => {
    if (!storyRef) {
      dispatch(fetchNodesBySeasonByStory({ seasonId, storyId }));
    }
  }, [dispatch, seasonId, storyId, storyRef]);

  const profilesLoaded = byCharacterProfileId !== null;
  React.useEffect(() => {
    if (!profilesLoaded) {
      dispatch(fetchCharacterProfiles());
    }
  }, [dispatch, profilesLoaded]);


  const story = storyRef?.story;
  const startingNodeId = story?.startingNodeId;

  const nodes = storyRef?.nodes;
  const selectedProfile = userProfiles && selectedProfileId && userProfiles[selectedProfileId];

  if (!nodes || !startingNodeId || !profilesLoaded || !story || !selectedProfile) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Chip />
      <StoryPoints score={(selectedProfile.storyProgress[storyId]) ? selectedProfile.storyProgress[storyId].score : 0} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes[startingNodeId] as NodeState<LAMCQuestionNode>} />
      <Video seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['a7329218-107b-4934-a446-cb6bbcce89e0'] as NodeState<VideoNode>} />
      <Phone seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['bde4421a-a026-4700-8815-0b48b2dea2a5'] as NodeState<PhoneNode>} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['173fc869-8653-4072-b2bb-bbc2d9fd7737'] as NodeState<LAMCQuestionNode>} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['c986bd2b-0fc2-481d-b16a-adb924da9cce'] as NodeState<LAMCQuestionNode>} />
      <Selfie seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['69bfe591-699f-4236-b0e0-da3fbcf79b8b'] as NodeState<SelfieNode>} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['a6f928b9-b8b0-47e6-b2f1-809bb85a3513'] as NodeState<LAMCQuestionNode>} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['e25dceaa-9fa6-4727-be7b-ffc1d1d112cd'] as NodeState<LAMCQuestionNode>} />
      <LAMCQuestion seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['02dec704-611a-400a-bff7-7881d17af36a'] as NodeState<LAMCQuestionNode>} />
      <ScreenCleaning seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['5cf554d6-211c-4da4-a36d-f91d97b6a42f'] as NodeState<ScreenCleaningNode>} />
      <DisplayIdBadge seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['fdab469f-855f-44a5-a92b-d572f924ae8b'] as NodeState<IdBadgeNode>} />
      <Pulley seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['a50da658-e72c-40ba-8891-e1ddb3e2f23a'] as NodeState<PulleyNode>} />
      <Phone seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['53ba42ef-251e-4f99-8beb-75b05567028c'] as NodeState<PhoneNode>} />
      <Video seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['80ba671b-eeff-4415-b46d-e4cacb5fc20c'] as NodeState<VideoNode>} />
      <Phone seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['32192bad-ac99-4d45-9c1c-89081147195c'] as NodeState<PhoneNode>} />
      <Video seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['c1e1c84a-30a8-48d0-9abe-56212336755f'] as NodeState<VideoNode>} />
      <PointsDisplay seasonId={seasonId} storyId={storyId} story={story} nodeState={nodes['468a27e3-c4ae-48c6-b8d1-08e07bb2835c'] as NodeState<PointsDisplayNode>} />
    </React.Fragment>
  );
};

export default StoryPlaybackPlayer;