import firebase from 'firebase';
import { SeasonSnapshot, SnapshotOptions, Season } from 'types';

export const seasonConverter: firebase.firestore.FirestoreDataConverter<Season> = {
  toFirestore(season: Season) {
    const snapshot = {
      ...season,
      createdBy: firebase.firestore().doc('adminUsers/' + season.createdBy),
      createdAt: firebase.firestore.Timestamp.fromMillis(season.createdAt),
      updatedAt: (season.updatedAt !== undefined) ? firebase.firestore.Timestamp.fromMillis(season.updatedAt) : undefined,
    };

    if (snapshot.updatedAt === undefined) {
      delete snapshot.updatedAt;
    }

    return snapshot;
  },
  fromFirestore(snapshot: SeasonSnapshot, options: SnapshotOptions): Season {
    const data = snapshot.data(options);
    const season: Season = {
      ...data,
      createdBy: data.createdBy.id,
      createdAt: data.createdAt.toMillis(),
      updatedAt: data.updatedAt?.toMillis(),
    };
    return season;
  },
};

