import React from 'react';
import './index.css';
/** import types */


const MembershipDetails: React.FC = () => {
  return (
    <div className={'customer-support-details-container'}>
      <div className='tappity-header customer-support-header'>Customer Support</div>
      <div className={'customer-support-details'}>
        <div className={'customer-support-details-row'}>
          <span className={'customer-support-row-header'}>Phone number:</span> 510.384.8049
        </div>
        <div className={'customer-support-details-row'}>
          <span className={'customer-support-row-header'}>Email:</span> hey@TappityApp.com
        </div>
      </div>
    </div>
  );
};


export default MembershipDetails;