import React from 'react';
import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import dayjs from 'dayjs';
import './index.css';
/** import types */
import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';
import type { RCSubscriber } from 'types';


const MembershipDetails: React.FC = () => {
  // const dispatch = useDispatch<AppDispatch>();
  const {
    subscriber,
  } = useSelector((state: RootState) => state.revenuecatState);

  let subscription: RCSubscriber['subscriptions'][string] | undefined;
  let productId: string | undefined;
  if (subscriber?.subscriptions && Object.keys(subscriber.subscriptions).length > 0) {
    [[productId, subscription]] = Object.entries(subscriber.subscriptions);
  }
  const Details: React.FC<{ subscription: RCSubscriber['subscriptions'][string] }> = ({ subscription }: { subscription: RCSubscriber['subscriptions'][string] }) => {
    const expireDate = dayjs(subscription.expires_date);
    const active = dayjs().isBefore(expireDate);
    let membershipActive = 'membership-inactive';
    if (active) {
      membershipActive = 'membership-active';
    }

    return (
      <div className={'membership-details'}>
        <div className={`membership-details-row ${membershipActive}`}>Membership status: {(active) ? 'Active' : 'Inactive'}</div>
        <div className={'membership-details-row'}>Expire date: {expireDate.format('MM/DD/YYYY')}</div>
      </div>
    );
  };

  return (
    <div className={'membership-details-container'}>
      <div className='tappity-header membership-header'>Membership Details</div>
      {subscriber ? (
        <React.Fragment>
          {(productId && subscription) && (
            <Details subscription={subscription} />
          )}
        </React.Fragment>
      ) : (
        <div className={'membership-details'}>
          <div className={'membership-details-row'}>
            Loading...
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipDetails;