import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRect } from 'src/RectHook';
import useInterval from '@use-it/interval';
import { shiftJobs } from 'src/features/chip/chipSlice';
import { fetchChipAssets } from 'src/features/chip/chipAssetsSlice';
import { Motion, spring } from 'react-motion';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


const Chip: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const maxWait = 10000;
  const audioElRef = React.useRef<HTMLAudioElement>(null);
  const [blinking, setBlinking] = React.useState(true);
  const [currentLocation, setCurrentLocation] = React.useState<{ left: number; top: number; }>({ left: 100, top: 0 });
  const [nextLocation, setNextLocation] = React.useState<{ left: number; top: number; }>({ left: 250, top: 40 });
  const [speaking, setSpeaking] = React.useState<boolean>(false);
  const [rand, setRand] = React.useState((Math.random() * maxWait) + 1000);
  const chipContainer = React.useRef<HTMLDivElement>(null);
  const chipMouthRef = React.useRef<HTMLDivElement>(null);
  const chipMouthRect = useRect(chipMouthRef);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const leftEyeRef = React.useRef<HTMLDivElement>(null);
  const volumeLevel = 0.1;

  useInterval(() => {
    setBlinking(true);
  }, rand);

  const {
    jobs,
  } = useSelector((state: RootState) => state.chipState);
  const [currentJob] = jobs;

  const {
    assets,
  } = useSelector((state: RootState) => state.chipAssetsState);

  React.useEffect(() => {
    if (assets === null) {
      dispatch(fetchChipAssets());
    }
  }, [dispatch, assets]);

  React.useEffect(() => {
    const leftEye = leftEyeRef.current;
    if (leftEye) {
      leftEye.onanimationend = () => {
        setBlinking(false);
        setRand((Math.random() * maxWait) + 1000);
      };
    }
  }, [leftEyeRef]);


  React.useEffect(() => {
    function handleGlobalClick(this: Window, ev: MouseEvent) {
      setNextLocation({
        left: ev.pageX,
        top: ev.pageY,
      });
    }
    window.addEventListener('click', handleGlobalClick);
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);


  React.useEffect(() => {
    const audioEl = audioElRef.current;
    if (currentJob && audioEl) {
      if (audioEl.volume !== volumeLevel) {
        audioEl.volume = volumeLevel;
      }
      audioEl.src = currentJob.audioClip;
      const location = currentJob.location;
      if (location) {
        setNextLocation(location);
      }
      audioEl.onended = async () => {
        console.log('finished audio clip', 'should remove');
        dispatch(shiftJobs());
        setSpeaking(false);
      };
      audioEl.play().then(() => {
        setSpeaking(true);
      }).catch((error) => {
        console.log('error in attempting to play audio', error);
      });
      audioEl.onplay = () => {
        console.log('started playing');
      };
    }
  }, [dispatch, currentJob, audioElRef]);

  React.useEffect(() => {
    const img = new Image();
    function computeFrame() {
      const canvasEl = canvasRef.current;
      const audioEl = audioElRef.current;
      const currentContainer = chipMouthRef.current;
      if (canvasEl && audioEl && currentContainer && assets && speaking) {
        const ctx = canvasEl.getContext('2d');
        const containerRect = currentContainer.getBoundingClientRect();

        if (ctx) {
          try {
            const { width, height } = containerRect;
            // calculated which image should be used based on volume of chip speaking
            const imgSrc = assets['BundledRemoteAssets/image/Chip/spud-mouth-talking-frame4.png'];
            img.src = imgSrc;
            img.onload = () => {
              const hRatio = width / img.width;
              const vRatio = height / img.height;
              const ratio = Math.min(hRatio, vRatio);
              const centerShift_x = (width - img.width * ratio) / 2; // The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
              const centerShift_y = (height - img.height * ratio) / 2; // The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
              ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
              setTimeout(() => {
                /**
                 * This is important for resizing events. If you don't stop the recursion
                 * every time the user changes the size of the screen a "new thread" of computeFrame is started
                 */
                if (!audioEl.paused) {
                  computeFrame();
                } else {
                  console.log('stop recursion');
                }
              }, 0);
            };
          } catch (error) {
            console.error(error);
          }

        } else {
          console.log("couldn't find context");
        }
      }
    }
    if (speaking) {
      console.log('start recursion');
      computeFrame();
    }
  }, [canvasRef, speaking, audioElRef, chipMouthRef, assets]);

  let leftEyeClass = '';
  let rightEyeClass = '';
  if (blinking) {
    leftEyeClass = 'chip-left-eye-is-blinking';
    rightEyeClass = 'chip-right-eye-is-blinking';
  }
  let canvasHidden = 'hidden';
  let mouthIsBreathing = 'chip-is-breathing';
  if (speaking) {
    canvasHidden = '';
    mouthIsBreathing = '';
  }


  return (
    <Motion
      defaultStyle={currentLocation}
      style={{ left: spring(nextLocation.left), top: spring(nextLocation.top) }}
      onRest={() => {
        setCurrentLocation(nextLocation);
      }}
    >
      {(interpolatingStyle) => (
        <div style={interpolatingStyle} ref={chipContainer} className={'chip'}>
          <div className={'chip-body'}>
            <div className={'chip-face'}>
              <div className={'chip-eyes'}>
                <div className={'chip-left-eye'}>
                  <div ref={leftEyeRef} className={`chip-left-eye-lid ${leftEyeClass}`}></div>
                </div>
                <div className={'chip-right-eye'}>
                  <div className={`chip-right-eye-lid ${rightEyeClass}`}></div>
                </div>
              </div>
              <div className={'chip-body'}>
                
              </div>
              <div ref={chipMouthRef} className={`chip-mouth ${mouthIsBreathing}`}>
                <canvas
                  ref={canvasRef}
                  className={canvasHidden}
                  width={chipMouthRect.width}
                  height={chipMouthRect.height}
                />
              </div>
            </div>
          </div>
          <div className={'chip-propeller'}></div>
          <audio
            ref={audioElRef}
          />
        </div>
      )}
    </Motion>
  );
};

export default Chip;