import firebase from 'firebase';
/** type imports */
import type { SnapshotOptions, UserProfile } from 'types';


export const userProfileConverter: firebase.firestore.FirestoreDataConverter<UserProfile> = {
  toFirestore(userProfile: UserProfile): UserProfile {
    return userProfile;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<UserProfile>, options: SnapshotOptions): UserProfile {
    const data = snapshot.data(options);
    if (data.topics === undefined) {
      data.topics = [];
    }
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return data;
  },
};