import AssetFetcher from 'src/AssetFetcher';
/** import types */
import type firebase from 'firebase';
import type {
  CharacterProfile,
  SnapshotOptions,
} from 'types';

type CharacterProfileSnapshot = firebase.firestore.QueryDocumentSnapshot<CharacterProfile>;

export const characterProfileConverter: firebase.firestore.FirestoreDataConverter<CharacterProfile> = {
  toFirestore(characterProfile: CharacterProfile): CharacterProfile {
    return characterProfile;
  },
  fromFirestore(snapshot: CharacterProfileSnapshot, options: SnapshotOptions): CharacterProfile {
    const data = snapshot.data(options);
    return data;
  },
};


type MediaFields = keyof Pick<CharacterProfile, 'backgroundImage' | 'profileBackgroundImage' | 'profileImage' | 'ringAudio'>;
export const mediaFields: MediaFields[] = ['backgroundImage', 'profileBackgroundImage', 'profileImage', 'ringAudio'];

export async function transformCharacterProfile(characterProfile: CharacterProfile): Promise<CharacterProfile> {
  const assetFetcher = new AssetFetcher();
  await assetFetcher.init();
  const mediaAssets = await Promise.all(mediaFields.map(async (field) => {
    const objUrl = await assetFetcher.getAsset(characterProfile[field]);
    return [field, objUrl];
  }));

  const transformed: CharacterProfile = {
    ...characterProfile,
    ...Object.fromEntries(mediaAssets),
  };

  return transformed;
}
