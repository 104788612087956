import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';
import 'firebase/firestore';
import { storyConverter } from './helpers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { Story } from 'types';

export interface StoryCollection {
  byTagId: {
    [tagId: string]: string[]
  },
  bySeason: {
    [tagId: string]: string[]
  },
  byId: {
    [storyId: string]: StoryState;
  },
}

export interface StoryState {
  storyId: string;
  seasonId: string;
  story: Story;
  storyWithAssets: null | Story;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

interface StoriesState {
  stories: StoryCollection | null;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: StoriesState = {
  stories: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null
};

export const fetchStories = createAsyncThunk<StoryCollection | null, void, { state: RootState }>(
  'stories/fetchStories',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }

    const { loading, currentRequestId } = getState().storiesState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }

    const stories: StoryCollection = {
      byTagId: {},
      bySeason: {},
      byId: {}
    };

    const storiesSnapshot = await firebase.firestore()
      .collectionGroup("stories")
      .withConverter(storyConverter)
      .where('status', '==', 'published')
      .orderBy('order', 'asc')
      .get();

    storiesSnapshot.forEach((storySnapshot) => {
      if (storySnapshot.exists) {

        let reg = /^seasons\/(.*)\/stories/i;
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          reg = /^testData\/1\/seasons\/(.*)\/stories/i;
        }
        const pathComponents = reg.exec(storySnapshot.ref.path);
        if (!pathComponents) {
          return;
        }

        const storyId = storySnapshot.id;
        const seasonId = pathComponents[1];
        const story = storySnapshot.data();

        // filtering out live stories in code, rather than the query, so we don't have to change the index
        if (story.type !== "live" || !story.characterProfileId) {
          return;
        }


        stories.byId[storyId] = {
          storyId,
          seasonId,
          story,
          storyWithAssets: null,
          loading: 'idle',
          currentRequestId: undefined,
          error: null,
        };


        // NOTE: storing this here lets us keep a "season" page around,
        // however, that page is going to load all stories just to show itself,
        // which is obviously not very efficient. If season page actually ends
        // up getting used a lot, we should bring back an explicit way to fetch
        // stories by season rather than use this.
        stories.bySeason[seasonId] = stories.bySeason[seasonId] || [];
        stories.bySeason[seasonId].push(storyId);

        for (const tag of story.tags) {
          if (stories.byTagId !== null) {
            stories.byTagId[tag] = stories.byTagId[tag] || [];
            stories.byTagId[tag].push(storyId);
          }
        }

      }
    });

    return stories;
  }
);

const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStories.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchStories.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = null;
        state.currentRequestId = undefined;
        state.stories = payload;
      }
    });
    builder.addCase(fetchStories.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default storiesSlice.reducer;