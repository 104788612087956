import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { axiosInstance } from 'src/helpers';
/** import types */


const ParentMenu: React.FC = () => {
  return (
    <div className={'parent-menu-container'}>
      <div className={'tappity-header'}>Parent menu</div>
      <div className={'tappity-sub-header'}>ACCOUNT</div>
      <Link className={`sidebar-menu-item`} to='/profiles'>Child profiles</Link>
      <Link className={`sidebar-menu-item`} to='/progress_update_emails'>Progress update emails</Link>
      <div className='tappity-sub-header'>MEMBERSHIP</div>
      <button
            className={`tappity-form-submit-button`}
            onClick={async () => {
              try {
                const { data } = await axiosInstance.post<{ url: string }>('/create-customer-portal-session');
                window.location.replace(data.url);
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {'Manage your subscription'}
          </button>
      <Link className={`sidebar-menu-item`} to='/subscription'>Your subscription</Link>
      <div className={'tappity-sub-header'}>MORE</div>
      <Link className={`sidebar-menu-item`} to='/change_password'>Change password</Link>
      <Link className={`sidebar-menu-item`} to='/support'>Customer support</Link>
    </div>
  );
};

export default ParentMenu;