import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { useChip } from 'src/components/chip/ChipHook';
import { updateProfileStoryProgress } from 'src/features/userProfiles/userProfilesSlice';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import './index.css';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type {
  Story,
  ScreenCleaningNode,
  NodeState,
} from 'types';



interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  nodeState: NodeState<ScreenCleaningNode>;
}


type ScreenCleaningVideoKeys = keyof Pick<ScreenCleaningNode, 'backgroundVideo' | 'waitingLoopingVideo'>;
const ScreenCleaning: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const [scoreTotal, setScoreTotal] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const [addJob] = useChip();
  const itemsRef = React.useRef<(HTMLDivElement | null)[]>([]);
  const history = useHistory();
  const { nodeState, seasonId, storyId } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  const [playingKey, setPlayingKey] = React.useState<ScreenCleaningVideoKeys | string | null>('backgroundVideo');
  const [nextNodeId, setNextNodeId] = React.useState<string | null>(null);
  const optionDefs = Object.entries(node.screenCleanerOptions).filter(([, { isDeleted }]) => !isDeleted);
  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, optionDefs.length);
  }, [optionDefs]);

  React.useEffect(() => {
    if (nodeWithAssets) {
      if (playingKey === 'waitingLoopingVideo' && selectedOptions.length === 0) {
        const questionAudio = nodeWithAssets.botAudio;
        if (questionAudio) {
          addJob({
            audioClip: questionAudio,
          });
        }
        itemsRef.current.forEach((divRef) => {
          if (divRef) {
            const optionAudio = divRef.getAttribute('option-audio');
            console.log(optionAudio);
            const rect = divRef.getBoundingClientRect();
            if (optionAudio) {
              addJob({
                audioClip: optionAudio,
                location: {
                  left: rect.left - 120,
                  top: rect.top,
                }
              });
            }
          }
        });
      }

      itemsRef.current.forEach((divRef) => {
        if (divRef) {
          const responseBackgroundVideoKey = divRef.getAttribute('response-background-video-key');
          const responseBotAudio = divRef.getAttribute('response-bot-audio');
          if (responseBackgroundVideoKey === playingKey && responseBotAudio) {
            addJob({
              audioClip: responseBotAudio,
            });
          }
        }
      });

    }
  }, [addJob, playingKey, nodeWithAssets, selectedOptions]);

  if (!nodeWithAssets) {
    return <div>Loading....</div>;
  }

  const optionIdTargetNodeMaping = Object.entries(node.edges).reduce((accumulator: { [optionId: string]: string }, [, { targetNodeId, context }]) => {
    if (context) {
      const { optionIds } = context;
      optionIds?.forEach((optionId) => {
        accumulator[optionId] = targetNodeId;
      });
    }
    return accumulator;
  }, {});

  function nextNode(): void {
    console.log('nextNode function called');
    if (nextNodeId) {
      console.log('going to nextNode', nextNodeId);
      dispatch(updateProfileStoryProgress({
        storyId,
        completed: false,
        nextNodeId,
        completedNodePoints: scoreTotal,
      }));
      history.replace(`/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`);
    } else {
      history.replace(`/seasons/${seasonId}`);
    }
  }


  const active = nodeId === urlNodeId;
  const players: { [key: string]: JSX.Element } = {
    backgroundVideo: <ReactPlayer
      playing={'backgroundVideo' === playingKey && active}
      url={nodeWithAssets.backgroundVideo}
      width={'100%'}
      height={'auto'}
      onEnded={() => setPlayingKey('waitingLoopingVideo')}
    />,
    waitingLoopingVideo: <ReactPlayer
      playing={'waitingLoopingVideo' === playingKey && active}
      url={nodeWithAssets.waitingLoopingVideo}
      width={'100%'}
      height={'auto'}
      loop={true}
    />
  };

  const options = Object.entries(nodeWithAssets.screenCleanerOptions).filter(([, { isDeleted }]) => !isDeleted);
  const optionElements: JSX.Element[] = [];
  options.forEach(([optionId, option], index) => {
    const {
      botAudio,
      responseVideo,
      responseBotAudio,
      image,
      isCorrect,
      points,
    } = option;
    let handleSelect = () => {
      if (optionId in optionIdTargetNodeMaping) {
        setNextNodeId(optionIdTargetNodeMaping[optionId]);
      }
      if (points) {
        setScoreTotal((prev) => {
          return prev + points;
        });
      }
      if (isCorrect) {
        nextNode();
      } else {
        setPlayingKey('waitingLoopingVideo');
      }
    };
    const responseVideoKey = `${optionId}.responseVideo`;
    handleSelect = () => {
      if (optionId in optionIdTargetNodeMaping) {
        setNextNodeId(optionIdTargetNodeMaping[optionId]);
      }
      if (points) {
        setScoreTotal((prev) => {
          return prev + points;
        });
      }
      setSelectedOptions((options) => {
        options.push(responseVideoKey);
        return options;
      });
      setPlayingKey(responseVideoKey);
    };
    const isPlaying = playingKey === responseVideoKey && active;


    players[responseVideoKey] = (
      <ReactPlayer
        playing={isPlaying}
        url={responseVideo}
        width={'100%'}
        height={'auto'}
        onEnded={() => {
          if (isCorrect) {
            nextNode();
          } else {
            setPlayingKey('waitingLoopingVideo');
          }
        }}
      />
    );


    const optionCardStyle: React.CSSProperties = {
      backgroundImage: `url(${image})`,
    };
    if (playingKey !== 'waitingLoopingVideo' || selectedOptions.includes(responseVideoKey)) {
      optionCardStyle.display = 'none';
    }
    optionElements.push(
      <div
        key={optionId}
        className={`option-item`}
        ref={el => itemsRef.current[index] = el}
        option-audio={botAudio}
        response-background-video-key={responseVideoKey}
        response-bot-audio={responseBotAudio}
        style={optionCardStyle}
        onClick={handleSelect}
      >
      </div>
    );
  });
  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  return (
    <div
      className={`node-container ${nodeContainerHidden}`}
    >
      {Object.entries(players).map(([key, player]) => {
        let videoContainerHidden = 'hidden';
        if (playingKey === key) {
          videoContainerHidden = '';
        }
        return (
          <div
            className={`node-video-container ${videoContainerHidden}`}
            key={key}
          >
            {player}
          </div>
        );
      })}
      <div className={`node-interaction-panel`}>
        {optionElements.map(optionEl => optionEl)}
      </div>
    </div>
  );
};

export default ScreenCleaning;