import React from 'react';
import { useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useChip } from 'src/components/chip/ChipHook';
import { addProfileActivity } from 'src/features/profileActivities/profileActivitiesSlice';
import './index.css';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type {
  BadgeEarningNode,
  NodeState,
  Story,
  Activity,
} from 'types';


interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  activeOverride?: boolean;
  nodeState: NodeState<BadgeEarningNode>;
}
const BadgeEarning: React.FC<Props> = ({ activeOverride = true, ...props }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const [hideBadge, setHideBadge] = React.useState<boolean>(true);
  const [playCount, setPlayCount] = React.useState<number>(0);
  const [buttonClicked, updatedButtonClicked] = React.useState<boolean>(false);
  const [addJob] = useChip();
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node } = nodeState;
  const active = (nodeId === urlNodeId) && activeOverride;


  React.useEffect(() => {
    if (active && playCount === 0) {
      addJob({
        audioClip: `https://assetcdn.tappityapp.com/${node.botAudio}`,
        onFinish: async () => {
          setHideBadge(false);
        },
      });
      setPlayCount(1);
    }
  }, [active, node.botAudio, playCount, addJob]);

  let nextNodeId: string | null = null;
  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }


  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  return (
    <div node-id={nodeState.nodeId} node-type={node.type} className={`node-container full-node-container ${nodeContainerHidden}`}>
      <div
        className={'badge-earning-background-image'}
        style={{
          backgroundImage: `url(https://assetcdn.tappityapp.com/${node.backgroundImage})`
        }}
      >
        <img className={'badge-image'} src={`https://assetcdn.tappityapp.com/${node.image}`} alt='badge' />
      </div>
      <div className='live-node-interaction-panel'>
        <div
          className={`option-item true-option centered-option`}
          style={{
            backgroundImage: 'url(/thumbs-up-button.png)',
            display: (hideBadge) ? 'none' : 'inline-block',
          }}
          onClick={async () => {
            try {
              if (!buttonClicked) {
                updatedButtonClicked(true);
                const activity: Omit<Activity, 'id' | 'createdAt' | 'updatedAt'> = {
                  title: node.type,
                  description: node.type,
                  seasonId,
                  storyId,
                  nodeId,
                  imageAsset: node.image,
                  activityType: 'badge',
                  tags: [],
                };
                dispatch(addProfileActivity(activity));
              }
              let nextNodeUrl = '/';
              if (nextNodeId) {
                nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
              }
              history.replace(nextNodeUrl);
            } catch (error) {
              console.log(error);
            }
          }}
        ></div>
      </div>
    </div>
  );
};

export default BadgeEarning;