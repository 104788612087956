import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import './index.css';
/** type imports */
import type { AppDispatch } from 'src/app/store';

import type {
  VideoNode,
  NodeState,
  Story,
} from 'types';

interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  nodeState: NodeState<VideoNode>;
}

const Video: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  let nextNodeId: string | null = null;
  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }

  if (!nodeWithAssets) {
    return <div>Loading....</div>;
  }

  const active = nodeId === urlNodeId;
  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  return (
    <div className={`node-container ${nodeContainerHidden}`}>
      <div className={`node-video-container`}>
        <ReactPlayer
          playing={active}
          width={'100%'}
          height={'auto'}
          url={nodeWithAssets.video}
          onEnded={() => {
            if (nextNodeId) {
              const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
              history.replace(nextNodeUrl);
            }
          }}
        />
      </div>
      <div className='node-interaction-panel'>
      </div>
    </div>
  );
};

export default Video;