import React from 'react';
import './Carousel.css';
/** import types */

interface PrivateProps {
  children: React.ReactNode;
}

const Carousel: React.FC<PrivateProps> = (props: PrivateProps) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const scroll = scrollRef.current;
    let eventListener: () => void | undefined;
    if (scroll) {
      eventListener = () => {
        setScrollPosition(scroll.scrollLeft);
      };
      scroll.addEventListener("scroll", eventListener);
      setScrollWidth(scroll.scrollWidth);
      setWidth(scroll.offsetWidth);
    }
    return () => {
      if (scroll && eventListener) {
        scroll.removeEventListener("scroll", eventListener);
      }
    };
  }, [props.children]);

  const showRight = (scrollWidth - scrollPosition - 50) > width;
  const showLeft = scrollPosition >= 50;
  const scrollLeft = () => { scrollRef.current?.scroll(scrollPosition - width, 0); };
  const scrollRight = () => { scrollRef.current?.scroll(scrollPosition + width, 0); };

  return (
    <div className='carousel'>
      {showLeft && <div className="scroll-left" onClick={scrollLeft}>←</div>}
      <div className='carousel-wrapper' ref={scrollRef}>
        {props.children}
      </div>
      {showRight && <div className="scroll-right" onClick={scrollRight}>→</div>}
    </div>

  );
};

export default Carousel;