import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import { updateProfileStoryProgress } from 'src/features/userProfiles/userProfilesSlice';
import { useChip } from 'src/components/chip/ChipHook';
import { useRect } from 'src/RectHook';
import {
  useParams,
  useHistory,
} from 'react-router-dom';

// import {
//   fetchStory,
// } from 'src/features/stories/singleStorySlice';
// import {
//   fetchNodeAssets,
// } from 'src/features/nodes/assetsSlice';
import './index.css';
/** type imports */
// import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  Story,
  LAMCQuestionNode,
  NodeState,
} from 'types';



interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  nodeState: NodeState<LAMCQuestionNode>;
}


type LAMCQuestionVideoKeys = keyof Pick<LAMCQuestionNode, 'questionVideo' | 'waitingLoopingVideo' | 'transitionVideo'>;
const LAMCQuestion: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [addJob] = useChip();
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const containerRect = useRect(containerRef);
  const [scoreTotal, setScoreTotal] = React.useState(0);
  const itemsRef = React.useRef<(HTMLDivElement | null)[]>([]);
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const history = useHistory();
  const { nodeState, seasonId, storyId } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  const active = nodeId === urlNodeId;
  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  const [playingKey, setPlayingKey] = React.useState<LAMCQuestionVideoKeys | string | null>('questionVideo');
  const [nextNodeId, setNextNodeId] = React.useState<string | null>(null);

  const optionDefs = Object.entries(node.lamcAnswers).filter(([, { isDeleted }]) => !isDeleted);
  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, optionDefs.length);
  }, [optionDefs]);
  /**
  * this is used to set the CSS variable that controles the width of the video contianer
  */
  React.useEffect(() => {
    const container = containerRef.current;
    if (container && active) {
      const cssVariableContainerTop = '--node-container-top';
      const cssVariableContainerLeft = '--node-container-left';
      const { top, left, width } = containerRect;
      document.documentElement.style.setProperty(cssVariableContainerTop, top + 10 + "px");
      document.documentElement.style.setProperty(cssVariableContainerLeft, left + width - 150 + "px");
    }
  }, [containerRect, containerRef, active]);


  React.useEffect(() => {
    if (active) {
      if (nodeWithAssets) {
        if (playingKey === 'waitingLoopingVideo' && selectedOptions.length === 0) {
          const questionAudio = nodeWithAssets.questionAudio;
          if (questionAudio) {
            console.log(questionAudio);
            addJob({
              audioClip: questionAudio,
            });
          }
          itemsRef.current.forEach((divRef) => {
            if (divRef) {
              const optionAudio = divRef.getAttribute('option-audio');
              const rect = divRef.getBoundingClientRect();
              if (optionAudio) {
                addJob({
                  audioClip: optionAudio,
                  location: {
                    left: rect.left - 120,
                    top: rect.top,
                  }
                });
              }
            }
          });
        }

        itemsRef.current.forEach((divRef) => {
          if (divRef) {
            const responseBackgroundVideoKey = divRef.getAttribute('response-background-video-key');
            const responseBotAudio = divRef.getAttribute('response-bot-audio');
            if (responseBackgroundVideoKey === playingKey && responseBotAudio) {
              addJob({
                audioClip: responseBotAudio,
              });
            }
          }
        });
      }
    }
  }, [addJob, playingKey, nodeWithAssets, active, selectedOptions]);


  if (!nodeWithAssets) {
    return <div>Loading....</div>;
  }
  const options = Object.entries(nodeWithAssets.lamcAnswers).filter(([, { isDeleted }]) => !isDeleted);

  const optionIdTargetNodeMaping = Object.entries(node.edges).reduce((accumulator: { [optionId: string]: string }, [, { targetNodeId, context }]) => {
    if (context) {
      const { optionIds } = context;
      optionIds?.forEach((optionId) => {
        accumulator[optionId] = targetNodeId;
      });
    }
    return accumulator;
  }, {});



  function nextNode(): void {
    if (nextNodeId) {
      dispatch(updateProfileStoryProgress({
        storyId,
        completed: false,
        nextNodeId,
        completedNodePoints: scoreTotal,
      }));
      history.replace(`/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`);
    } else {
      history.replace(`/seasons/${seasonId}`);
    }
  }

  const players: { [key: string]: JSX.Element } = {
    questionVideo: <ReactPlayer
      playing={'questionVideo' === playingKey && active}
      url={nodeWithAssets.questionVideo}
      width={'100%'}
      height={'auto'}
      onEnded={() => setPlayingKey('waitingLoopingVideo')}
    />,
    waitingLoopingVideo: <ReactPlayer
      playing={'waitingLoopingVideo' === playingKey && active}
      url={nodeWithAssets.waitingLoopingVideo}
      width={'100%'}
      height={'auto'}
      loop={true}
    />
  };
  if (nodeWithAssets.transitionVideo) {
    players.transitionVideo = (
      <ReactPlayer
        playing={'transitionVideo' === playingKey && active}
        width={'100%'}
        height={'auto'}
        url={nodeWithAssets.transitionVideo}
        onEnded={() => {
          nextNode();
        }}
      />
    );
  }
  const optionElements: JSX.Element[] = [];
  options.forEach(([optionId, option], index) => {
    const { responseBotAudio, responseBackgroundVideo, isCorrect, answerAudio, points, } = option;
    let handleSelect = () => {
      if (points) {
        setScoreTotal((prev) => {
          return prev + points;
        });
      }
      if (optionId in optionIdTargetNodeMaping) {
        setNextNodeId(optionIdTargetNodeMaping[optionId]);
      }
      if (isCorrect || nodeWithAssets.continueIfWrong) {
        nextNode();
      } else {
        setPlayingKey('waitingLoopingVideo');
      }
    };

    const responseBackgroundVideoKey = (responseBackgroundVideo) ? `${optionId}.responseBackgroundVideo` : null;
    if (responseBackgroundVideoKey && responseBackgroundVideo) {
      const isPlaying = playingKey === responseBackgroundVideoKey && active;

      handleSelect = () => {
        console.log('second handleSelect fired');
        if (points) {
          setScoreTotal((prev) => {
            prev = prev + points;
            return prev;
          });
        }
        if (optionId in optionIdTargetNodeMaping) {
          setNextNodeId(optionIdTargetNodeMaping[optionId]);
        }
        setSelectedOptions((options) => {
          options.push(responseBackgroundVideoKey);
          return options;
        });
        setPlayingKey(responseBackgroundVideoKey);
      };
      players[responseBackgroundVideoKey] = (
        <ReactPlayer
          playing={isPlaying}
          url={responseBackgroundVideo}
          width={'100%'}
          height={'auto'}
          onEnded={() => {
            if (isCorrect || nodeWithAssets.continueIfWrong) {
              if (nodeWithAssets.transitionVideo) {
                setPlayingKey('transitionVideo');
              } else {
                nextNode();
              }
            } else {
              setPlayingKey('waitingLoopingVideo');
            }
          }}
        />
      );
    }

    const optionCardStyle: React.CSSProperties = {
      backgroundImage: `url(${option.answerImage})`,
    };

    if (playingKey !== 'waitingLoopingVideo' || (responseBackgroundVideoKey && selectedOptions.includes(responseBackgroundVideoKey))) {
      optionCardStyle.display = 'none';
    }
    optionElements.push(
      <div
        key={optionId}
        className={`option-item`}
        ref={el => itemsRef.current[index] = el}
        option-audio={answerAudio}
        response-background-video-key={responseBackgroundVideoKey}
        response-bot-audio={responseBotAudio}
        style={optionCardStyle}
        onClick={handleSelect}
      >
      </div>
    );
  });
  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  return (
    <div
      node-id={nodeState.nodeId}
      node-type={node.type}
      className={`node-container ${nodeContainerHidden}`}
    >
      {Object.entries(players).map(([key, player]) => {
        let videoContainerHidden = 'hidden';
        if (playingKey === key) {
          videoContainerHidden = '';
        }
        return (
          <div
            className={`node-video-container ${videoContainerHidden}`}
            key={key}
            ref={el => {
              if (videoContainerHidden === '' && el) {
                containerRef.current = el;
              }
            }}
          >
            {player}
          </div>
        );
      })}
      <div className={`node-interaction-panel`}>
        {optionElements.map(optionEl => optionEl)}
      </div>
    </div>
  );
};

export default LAMCQuestion;