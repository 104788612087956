import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNodesBySeasonByStory } from 'src/features/nodes/nodesSlice';
import { fetchCharacterProfiles } from 'src/features/characterProfiles/characterProfilesSlice';
import posthog from 'posthog-js';
import StoryPoints from './StoryPoints';
import LiveStoryPlaybackPlayer from './LiveStoryPlaybackPlayer';
import StoryDetails from './StoryDetails';
import StoryPlaybackPlayer from './StoryPlaybackPlayer';
import {
  useHistory,
} from 'react-router-dom';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';
import type {
  StoryState,
  LiveStory,
  StoryStartedEvent,
} from 'types';



interface Props {
  seasonId: string;
  storyId: string;
  nodeId?: string;
}
const StoryPlayer: React.FC<Props> = ({ seasonId, storyId, nodeId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { byCharacterProfileId } = useSelector((state: RootState) => state.characterProfilesState);
  const { bySeasonId } = useSelector((state: RootState) => state.nodesState);
  const { selectedProfileId, userProfiles } = useSelector((state: RootState) => state.userProfilesState);
  const storyRef = bySeasonId[seasonId]?.byStoryId[storyId];

  React.useEffect(() => {
    if (!storyRef) {
      dispatch(fetchNodesBySeasonByStory({ seasonId, storyId }));
    }
  }, [dispatch, seasonId, storyId, storyRef]);

  React.useEffect(() => {
    /**
     * logic to handle if the person should resume from last node
     */
    if (selectedProfileId && userProfiles && storyRef && storyRef.story) {
      const selectedProfile = userProfiles[selectedProfileId];
      if (!nodeId) {
        const story = storyRef.story;
        const startingNodeId = storyRef.story.startingNodeId;
        if (storyRef.storyId in selectedProfile.storyProgress) {
          const storyProgress = selectedProfile.storyProgress[storyRef.storyId];
          if (storyProgress.completed) {
            console.log('redirecting in StoryPlayer completed');
            const startingNodeUrl = `/seasons/${seasonId}/stories/${storyRef.storyId}/nodes/${startingNodeId}`;
            if (story.type === 'live' && byCharacterProfileId) {
              const startStoryEvent: StoryStartedEvent = {
                storyName: story.title,
                currentlyLive: false, //  (if story was live when kid started watching)
                storyType: story.type, // (type field on story (trivia | series | mini | feature | checkpoint | live | badge))
                subType: story.subType, // (subType field on story (case activity | badge | build_a_blank | drawing | mission | narrative))
                percentStartedAt: 0, // (If resuming a story percent of nodes complete out of total nodes)
                characterProfileId: story.characterProfileId, // (character profile id on the story)
                characterDisplayName: byCharacterProfileId[story.characterProfileId].characterProfile.displayName,
              };
              console.log('storyStarted', 'previous story completed starting again', startStoryEvent);
              posthog.capture('storyStarted', startStoryEvent);
            }
            history.replace(startingNodeUrl);
          } else {

            if (storyProgress.lastVisitedNodeId) {
              const startingNodeUrl = `/seasons/${seasonId}/stories/${storyRef.storyId}/nodes/${storyProgress.lastVisitedNodeId}`;
              console.log('redirecting in StoryPlayer not completed');
              if (story.type === 'live' && byCharacterProfileId) {
                const nodes = storyRef.nodes;
                const nodeArr = Object.entries(nodes).filter(([, { node }]) => !node.isDeleted);
                const totalNodes = nodeArr.length;

                const currentNode = nodes[storyProgress.lastVisitedNodeId];
                let node = nodes[startingNodeId];
                let count = 0;
                while (node.nodeId !== currentNode.nodeId) {
                  count++;
                  const nextNodeId = Object.values(node.node.edges)[0].targetNodeId;
                  node = nodes[nextNodeId];
                }
                const complete = (count / totalNodes) * 100;
                const startStoryEvent: StoryStartedEvent = {
                  storyName: story.title,
                  currentlyLive: false, //  (if story was live when kid started watching)
                  storyType: story.type, // (type field on story (trivia | series | mini | feature | checkpoint | live | badge))
                  subType: story.subType, // (subType field on story (case activity | badge | build_a_blank | drawing | mission | narrative))
                  percentStartedAt: complete, // (If resuming a story percent of nodes complete out of total nodes)
                  characterProfileId: story.characterProfileId, // (character profile id on the story)
                  characterDisplayName: byCharacterProfileId[story.characterProfileId].characterProfile.displayName,
                };
                console.log('storyStarted', 'resuming story', startStoryEvent);
                posthog.capture('storyStarted', startStoryEvent);
              }
              history.replace(startingNodeUrl);
            }
          }
        } else {
          const startingNodeUrl = `/seasons/${seasonId}/stories/${storyRef.storyId}/nodes/${startingNodeId}`;
          if (story.type === 'live' && byCharacterProfileId) {
            const startStoryEvent: StoryStartedEvent = {
              storyName: story.title,
              currentlyLive: false, //  (if story was live when kid started watching)
              storyType: story.type, // (type field on story (trivia | series | mini | feature | checkpoint | live | badge))
              subType: story.subType, // (subType field on story (case activity | badge | build_a_blank | drawing | mission | narrative))
              percentStartedAt: 0, // (If resuming a story percent of nodes complete out of total nodes)
              characterProfileId: story.characterProfileId, // (character profile id on the story)
              characterDisplayName: byCharacterProfileId[story.characterProfileId].characterProfile.displayName,
            };
            console.log('starting story for first time');
            posthog.capture('storyStarted', startStoryEvent);
          }
          history.replace(startingNodeUrl);
        }
      }
    }
  }, [selectedProfileId, userProfiles, storyRef, seasonId, nodeId, history, byCharacterProfileId, storyId]);

  React.useEffect(() => {
    if (byCharacterProfileId === null) {
      dispatch(fetchCharacterProfiles());
    }
  }, [dispatch, byCharacterProfileId]);

  const story = storyRef?.story;
  const startingNodeId = story?.startingNodeId;

  const nodes = storyRef?.nodes;
  const selectedProfile = userProfiles && selectedProfileId && userProfiles[selectedProfileId];

  if (!nodes || !startingNodeId || !byCharacterProfileId || !story || !selectedProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div id='story-player'>
      {(storyRef && storyRef.story && storyRef.story.type === 'live') ? (
        <React.Fragment>
          <LiveStoryPlaybackPlayer
            storyRef={storyRef as StoryState<LiveStory>}
            currentProfile={selectedProfile}
          />
          <div className='story-details-container'>
            <StoryPoints score={(selectedProfile.storyProgress[storyId]) ? selectedProfile.storyProgress[storyId].score : 0} />
            <StoryDetails story={storyRef.story} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <StoryPlaybackPlayer seasonId={seasonId} storyId={storyId} nodeId={nodeId} />
        </React.Fragment>
      )}
    </div >
  );
};

export default StoryPlayer;