import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import firebase from 'firebase';
import { linkProvider, signIn } from 'src/features/authentication/userSlice';
import { Link, useLocation } from 'react-router-dom';
import { axiosInstance, ageGroup as ageGroupDefintions } from 'src/helpers';
import 'firebase/auth';
import './index.css';
/** type imports */
// import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


type FormState = {
  type: 'sign-up';
  email: string;
  password: string;
  parentName: string;
  name: string;
  ageGroup: keyof typeof ageGroupDefintions;
} | {
  type: 'sign-in';
  email: string;
  password: string;
};
interface Props {
  type: 'sign-in' | 'sign-up';
  email?: string;
}
const EmailPasswordAuthForm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [showLogin, setShowLogin] = React.useState<boolean>(false);
  const [resetEmail, setResetEmail] = React.useState<string | null>(null);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = React.useState<boolean | null>(null);
  const promoCode = (new URLSearchParams(location.search)).get('promo');

  React.useEffect(() => {
    async function addMailchimpContact() {
      try {
        if (props.type === 'sign-up' && props.email) {
          await axiosInstance.post('/mailchimp/contacts', { email: props.email });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).fbq('track', 'Lead');
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }
    addMailchimpContact();
  }, [props.email, props.type]);


  React.useEffect(() => {
    if (passwordResetEmailSent !== null) {
      setInterval(() => {
        setPasswordResetEmailSent(null);
        if (passwordResetEmailSent) {
          setResetEmail(null);
        }
      }, 3000);
    }
  }, [passwordResetEmailSent]);

  const formik = useFormik<FormState>({
    initialValues: (props.type === 'sign-up') ? {
      type: props.type,
      email: props.email || '',
      password: '',
      parentName: '',
      name: '',
      ageGroup: '5 and under',
    } : {
      type: props.type,
      email: '',
      password: '',
    },
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      const { email, password } = values;
      const { setSubmitting, setErrors } = formikHelpers;
      try {
        console.log('form submitted');
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        setSubmitting(true);
        let resultAction;
        if (values.type === 'sign-up') {
          resultAction = await dispatch(linkProvider({ credential, parentName: values.parentName, userProfile: { name: values.name, ageGroup: values.ageGroup } }));
          if (!props.email) {
            try {
              await axiosInstance.post('/mailchimp/contacts', { email });
            } catch (error) {
              if (error instanceof Error) {
                console.error(error.message);
              }
            }
          }
        } else {
          resultAction = await dispatch(signIn({ email, password }));
        }
        if (['user/signIn/rejected', 'user/convertUser/rejected'].includes(resultAction.type)) {
          const error = { ...resultAction.payload as { code: string; message: string } };
          if (error.code === 'auth/wrong-password') {
            setResetEmail(email);
          }
          if (error.code === 'auth/email-already-in-use') {
            // go to login page
            setShowLogin(true);
          }
          setErrors({ password: error.message });
          setSubmitting(false);
        }
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });
  const { handleChange, handleSubmit, isSubmitting, errors } = formik;

  return (
    <div className={'email-password-auth-form-container'}>
      <form className={'tappity-form'} onSubmit={handleSubmit}>
        <div className={'tappity-form-title'}>{(formik.values.type === 'sign-up') ? "Create an account" : "Sign-in"}</div>
        <label
          className={'tappity-form-label'}
          htmlFor='email'
        >
          Email
        </label>
        <input
          className={'tappity-form-input'}
          id={'email'}
          name={'email'}
          type={'email'}
          disabled={isSubmitting}
          onChange={handleChange}
          required
          value={formik.values.email}
        />
        <label
          className={'tappity-form-label'}
          htmlFor='password'
        >
          Password
        </label>
        <input
          className={'tappity-form-input'}
          id={'password'}
          disabled={isSubmitting}
          name={'password'}
          type={'password'}
          onChange={handleChange}
          required
          minLength={5}
          value={formik.values.password}
        />
        {(formik.values.type === 'sign-up') && (
          <React.Fragment>
            <label
              className={'tappity-form-label'}
              htmlFor='parentName'
            >
              Parent&apos;s first name
            </label>
            <input
              className={'tappity-form-input'}
              id={'parentName'}
              name={'parentName'}
              type={'text'}
              disabled={isSubmitting}
              onChange={handleChange}
              required
              minLength={3}
              value={formik.values.parentName}
            />
            <label
              className={'tappity-form-label'}
              htmlFor='name'
            >
              Child&apos;s first name or nickname
            </label>
            <input
              className={'tappity-form-input'}
              name={'name'}
              type={'text'}
              disabled={isSubmitting}
              onChange={handleChange}
              required
              value={formik.values.name}
            />
            <label
              className={'tappity-form-label'}
              htmlFor='ageGroup'
            >
              Child&apos;s age
            </label>
            <select
              className={'tappity-form-input'}
              disabled={isSubmitting}
              name={'ageGroup'}
              onChange={handleChange}
              required
              value={formik.values.ageGroup}
            >
              <option>{ageGroupDefintions['5 and under']}</option>
              <option>{ageGroupDefintions['6 7 8']}</option>
              <option>{ageGroupDefintions['9 and over']}</option>
            </select>
            {(!props.email) && (
              <div className="sign-up-terms-group">
                <input
                  defaultChecked={false}
                  required
                  type="checkbox"
                  name="terms-conditions"
                  value="terms"
                />
                <label
                  className="sign-up-terms-conditions-label"
                  htmlFor="terms-conditions"
                >
                  By continuing you agree to our <a href="https://www.tappityapp.com/terms" target="_blank" rel="noreferrer">Terms</a> and <a href="https://www.tappityapp.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
                </label>
              </div>
            )}
          </React.Fragment>
        )}
        <button
          className={'tappity-form-submit-button'}
          disabled={isSubmitting}
          type="submit"
        >
          {formik.values.type === 'sign-up' ? 'Sign-up' : 'Sign-in'}
        </button>
        {Object.values(errors).map((errorMessage, index) => {
          console.log('this is an errorMessage', errorMessage);
          return (<div key={index} className='tappity-form-error'>{errorMessage}</div>);
        })}
        {showLogin && <Link className={'registration-sign-in-link'} to={promoCode ? `/sign-in?promo=${promoCode}` : '/sign-in'}>Sign-in to existing account</Link>}
      </form>
        <button
          className={'reset-password-button'}
        onClick={async () => {
          if(formik.values.email) {
            try {
              await firebase.auth().sendPasswordResetEmail(formik.values.email);
              setPasswordResetEmailSent(true);
            } catch (error) {
              console.log(error);
              setPasswordResetEmailSent(false);
            }
            }
            
          }}
        >
          Reset password for <div>{formik.values.email}</div>
        </button>
      
      {(passwordResetEmailSent !== null && resetEmail !== null) && ((passwordResetEmailSent) ? (
        <div className={'reset-email-info reset-email-success'}>Yay! Check {resetEmail} for the email to reset your password.</div>
      ) : (
        <div className={'reset-email-info reset-email-error'}>Oh no! Something went wrong.</div>
      ))}
    </div>
  );
};

export default EmailPasswordAuthForm;