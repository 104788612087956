import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadNodeAsset } from 'src/features/assetUploads/assetsUploadsSlice';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type {
  NodeState,
  NodeSelection,
  StoryProgress,
  LiveStory,
  BuilderImageAssemblerNode,
} from 'types';


interface ImageInfo {
  x: number;
  y: number;
  adjWidth: number;
  adjHeight: number;
  inCanvas: boolean;
}

interface Props {
  seasonId: string;
  storyId: string;
  story: LiveStory
  nodesProgress: StoryProgress['nodes'];
  nodeState: NodeState<BuilderImageAssemblerNode>;
  activeOverride?: boolean;
  videoWidth: number;
}

const BuilderImageAssemblerPlayback: React.FC<Props> = ({ activeOverride = true, ...props }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { byNodeId } = useSelector((state: RootState) => state.assetUploadsState);
  const history = useHistory();
  const { nodeState, storyId, seasonId, videoWidth, nodesProgress, story } = props;
  const { node, nodeId } = nodeState;
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const nodesProgressEntries = Object.entries(nodesProgress).filter(([, { type }]) => type === "Live Builder Image Selector");
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const backgroundImageRef = React.useRef<HTMLImageElement | null>(null);
  const prevImagesRef = React.useRef<{ [nodeId: string]: [HTMLImageElement, ImageInfo, NodeSelection] }>({});
  const imagesOrderedByLayer = React.useRef<string[]>([]);
  const [ratio, setRatio] = React.useState(1);
  const [hovering, setIsHovering] = React.useState<{ id: string; type: 'new' | 'prev' } | null>(null);
  const [dragging, setDragging] = React.useState<{ id: string; offset: { x: number; y: number; }; type: 'new' | 'prev'; } | null>(null);
  const coordConst = 1000;
  const active = (nodeId === urlNodeId) && activeOverride;
  let nextNodeId: string | null = null;

  let nodeContainerHidden = 'hidden';
  /** once the user selects their choice this will deactivate the node */
  if (active) {
    nodeContainerHidden = '';
  }

  const assetUpload = byNodeId[nodeId];

  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }
  React.useEffect(() => {
    if (assetUpload) {
      const { loading } = assetUpload;
      if (loading === 'pending') {
        console.log('images upload is pending, moving on');
        if (nextNodeId) {
          const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
          console.log(nextNodeUrl);
          history.replace(nextNodeUrl);
        }
      }
    }
  }, [assetUpload, nextNodeId, history, seasonId, storyId]);


  React.useEffect(() => {
    function computeFrame() {
      const backgroundImage = backgroundImageRef.current;
      const currentCanvas = canvasRef.current;
      if (currentCanvas && backgroundImage) {
        const ctx = currentCanvas.getContext('2d');
        if (ctx) {
          try {
            const bgImgWidth = backgroundImage.naturalWidth;
            const bgImgHeight = backgroundImage.naturalHeight;
            const canvasWidth = ctx.canvas.width;
            const canvasHeight = ctx.canvas.height;

            /** this clears the canvas which is necessary if later images are transparent */
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            const sx = 0; // The x-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            const sy = 0; // The y-axis coordinate of the top left corner of the sub-rectangle of the source image to draw into the destination context.
            const sWidth = bgImgWidth; // The width of the sub-rectangle of the source image to draw into the destination context. If not specified, the entire rectangle from the coordinates specified by sx and sy to the bottom-right corner of the image is used.
            const sHeight = bgImgHeight; // The height of the sub-rectangle of the source image to draw into the destination context.
            const dx = 0; // The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            const dy = 0; // The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
            const dWidth = canvasWidth; // The width to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in width when drawn.
            const dHeight = canvasHeight; // The height to draw the image in the destination canvas. This allows scaling of the drawn image. If not specified, the image is not scaled in height when drawn.


            ctx.drawImage(backgroundImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

            for (const id of imagesOrderedByLayer.current) {
              const [imageEl, { x, y, adjWidth, adjHeight }] = prevImagesRef.current[id];
              ctx.drawImage(
                imageEl, // source image
                0, // sx
                0, // sy
                imageEl.naturalWidth, // sWidth
                imageEl.naturalHeight, // sHeight
                x - (adjWidth / 2), // dx
                y - (adjHeight / 2), // dy
                adjWidth, // dWidth
                adjHeight // dHeight
              );
            }

            /**
             * this is how we recursively redraw each frame
             */
            setTimeout(() => {
              computeFrame();
            }, 0);
          } catch (error) {
            console.log(error);
          }

        } else {
          console.log("couldn't find context");
        }
      }
    }

    if (active) {
      computeFrame();
    }
  }, [active]);

  /** this should update the ratio based on background video resize events */
  React.useEffect(() => {
    if (videoWidth) {
      const r = videoWidth / coordConst;
      setRatio(r);
    }
  }, [backgroundImageRef, videoWidth]);


  const nodesProgressLength = nodesProgressEntries.length;
  const prevImagesLength = Object.keys(prevImagesRef.current).length;
  /**
   * This initializes values for references to previous user selections
   */
  React.useEffect(() => {
    const backgroundImage = backgroundImageRef.current;
    if (active && backgroundImage && nodesProgressLength === prevImagesLength) {
      const { naturalWidth: bgImgWidth, naturalHeight: bgImgHeight } = backgroundImage;
      for (const [, [{ naturalHeight, naturalWidth }, info]] of Object.entries(prevImagesRef.current)) {
        info.adjWidth = coordConst * (naturalWidth / bgImgWidth);
        info.adjHeight = coordConst * (naturalHeight / bgImgHeight);
      }
      imagesOrderedByLayer.current = Object.entries(prevImagesRef.current)
        .sort(([, [, , { optionSelected: { layer: layerA } }]], [, [, , { optionSelected: { layer: layerB } }]]) => layerA - layerB)
        .map(([id]) => id);
    }
  }, [active, nodesProgressLength, prevImagesLength]);

  /** this updates the position after each selection in previous nodes */
  React.useEffect(() => {
    if (!active) {
      for (const [id, [, info]] of Object.entries(prevImagesRef.current)) {
        const progress = nodesProgress[id];
        if (progress) {
          info.x = progress.positionX;
          info.y = progress.positionY;
        }
      }
    }
  }, [active, nodesProgress]);

  const canvasCss: React.CSSProperties = {};
  if (hovering) {
    canvasCss.cursor = 'pointer';
  }
  function handleMove(clientX: number, clientY: number, rect: DOMRect) {
    const pointerX = (clientX - rect.left) / ratio;
    const pointerY = (clientY - rect.top) / ratio;
    if (dragging) {
      const [, image] = prevImagesRef.current[dragging.id];
      image.x = pointerX + dragging.offset.x;
      image.y = pointerY + dragging.offset.y;
    } else {
      let shouldHover: { id: string; type: "new" | "prev" } | null = null;
      let currLayer = -1;
      for (const [id, [, { x, y, adjWidth, adjHeight }, { optionSelected: { layer } }]] of Object.entries(prevImagesRef.current)) {
        if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
          if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
            if (layer > currLayer) {
              shouldHover = { id, type: "prev" };
              currLayer = layer;
            }
          }
        }
      }
      if (shouldHover?.id !== hovering?.id) {
        setIsHovering(shouldHover);
      }
    }
  }

  function handleUp() {
    if (dragging) {
      setDragging(null);
    }
  }
  return (
    <div
      node-id={nodeState.nodeId}
      node-type={node.type}
      className={`live-builder-node-container ${nodeContainerHidden}`}
    >
      <canvas
        ref={canvasRef}
        className={'live-builder-node-canvas'}
        width={coordConst}
        height={coordConst}
        style={canvasCss}
        onMouseMove={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          handleMove(evt.clientX, evt.clientY, rect);
        }}
        onTouchMove={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const touch = evt.changedTouches[0];
          handleMove(touch.clientX, touch.clientY, rect);
        }}
        onMouseDown={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const pointerX = (evt.clientX - rect.left) / ratio;
          const pointerY = (evt.clientY - rect.top) / ratio;
          if (hovering) {
            const [, { x, y }] = prevImagesRef.current[hovering.id];
            setDragging({
              ...hovering,
              offset: {
                x: x - pointerX,
                y: y - pointerY,
              },
            });
          }
        }}
        onTouchStart={(evt) => {
          const rect = evt.currentTarget.getBoundingClientRect();
          const touch = evt.changedTouches[0];
          const pointerX = (touch.clientX - rect.left) / ratio;
          const pointerY = (touch.clientY - rect.top) / ratio;
          let shouldDrag: { id: string; type: "new" | "prev" } | null = null;
          let currLayer = -1;
          for (const [id, [, { x, y, adjWidth, adjHeight }, { optionSelected: { layer } }]] of Object.entries(prevImagesRef.current)) {
            if (x - (adjWidth / 2) < pointerX && pointerX < x + (adjWidth / 2)) {
              if (y - (adjHeight / 2) < pointerY && pointerY < y + (adjHeight / 2)) {
                if (layer > currLayer) {
                  shouldDrag = { id, type: "prev" };
                  currLayer = layer;
                }
                break;
              }
            }
          }
          if (shouldDrag) {
            const [, { x, y }] = prevImagesRef.current[shouldDrag.id];
            setDragging({
              ...shouldDrag,
              offset: {
                x: x - pointerX,
                y: y - pointerY,
              },
            });
          }
        }}
        onMouseUp={handleUp}
        onTouchEnd={handleUp}
        onMouseLeave={() => {
          if (hovering) {
            setIsHovering(null);
          }
          if (dragging) {
            setDragging(null);
          }
        }}
      />
      <img
        ref={backgroundImageRef}
        crossOrigin="anonymous"
        className="live-builder-node-background-image"
        src={`https://assetcdn.tappityapp.com/${node.backgroundImage}`}
      />
      {nodesProgressEntries.map(([nodeId, option]) => {
        return (
          <img
            key={nodeId}
            option-node-id={nodeId}
            option-type="prev-selection"
            className="prev-builder-selection"
            crossOrigin="anonymous"
            ref={(el) => {
              if (el) {
                if (!prevImagesRef.current[nodeId]) {
                  prevImagesRef.current[nodeId] = [el, { x: option.positionX, y: option.positionY, adjWidth: 0, adjHeight: 0, inCanvas: false }, option];
                }
              }
            }}
            src={`https://assetcdn.tappityapp.com/${option.optionSelected.image}`}
          />
        );
      })}
      <div className={'live-builder-node-interaction-panel'}>
        <div
          className={'live-builder-centered-option'}
          onClick={async () => {
            try {
              console.log('save picture and go to next node', `builder-${storyId}`);
              const canvasEl = canvasRef.current;
              if (canvasEl) {
                canvasEl.toBlob(async (blob) => {
                  try {
                    if (blob) {
                      await dispatch(uploadNodeAsset({ nodeId, fileName: `builder-${storyId}`, asset: blob }));
                    } else {
                      console.error("hmm, did not get an image blob");
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }, 'image/png');
              }
            } catch (error) {
              if (error instanceof Error) {
                console.error(error.message);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default BuilderImageAssemblerPlayback;