import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppLanding from './AppLanding';
import ProfileTopics from 'src/components/UserProfiles/ProfileTopics';
import SubscriptionManager from 'src/components/ParentMenu/SubscriptionManager';
import EmailPasswordAuthForm from 'src/components/auth/EmailPasswordAuthForm';
import SimpleAccountCreateForm from '../auth/SimpleAccountCreateForm';
/** type imports */
interface Props {
  step: 0 | 1 | 2;
  email: string;
  setEmail: (email: string) => void;
  selectedProfileId: string;
}

const AppLandingBody: React.FC<Props> = ({ step, email, setEmail, selectedProfileId }: Props) => {
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');
  switch (step) {
    case 0:
      return <AppLanding setEmail={setEmail} />;
    case 1:
      return <SimpleAccountCreateForm type={'sign-up'} email={email} />;
    case 2:
      return (
        <>
          <SubscriptionManager />
          {/*(promoCode === null) ? <div className='skip-container'><Link to={{ pathname: '/' }}>Skip</Link></div> : ''*/}
        </>
      );
  }
};

export default AppLandingBody;