import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { v1 as uuidV1 } from 'uuid';
import AssetFetcher from 'src/AssetFetcher';
/** type imports */
import type { RootState } from 'src/app/rootReducer';


interface AssetUpload {
  fileName: string;
  asset: Blob;
  downloadUrl: string | undefined;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

interface AssetUploadsState {
  byNodeId: {
    [nodeId: string]: AssetUpload | null;
  };
}

const initialState: AssetUploadsState = {
  byNodeId: {}
};

export const uploadNodeAsset = createAsyncThunk<string | null, { nodeId: string; fileName: string; asset: Blob }, { state: RootState }>(
  'assetUploads/uploadNodeAsset',
  async ({ nodeId, fileName, asset }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { uid } = user;
    const { selectedProfileId } = getState().userProfilesState;
    if (!selectedProfileId) {
      throw new Error("Oops! You haven't selected a profile");
    }
    const { byNodeId } = getState().assetUploadsState;

    const nodeAsset = byNodeId[nodeId];
    if (!nodeAsset || nodeAsset.loading !== 'pending' || requestId !== nodeAsset.currentRequestId) {
      return null;
    }
    const assetFetcher = new AssetFetcher();
    await assetFetcher.init();

    const fileId = uuidV1();
    const blobType = asset.type;
    if (blobType !== 'image/png' && blobType !== 'image/jpeg') {
      throw new Error(`Asset type of ${blobType} is not recognized`);
    }

    const [, fileExtension] = blobType.split('/');

    const assetPath = `${uid}/${selectedProfileId}/${fileName}/${fileId}.${fileExtension}`;

    const task = assetFetcher.initiateUserUploadAsset({ assetPath, asset });
    const snapShot = await task;
    const url: string = await snapShot.ref.getDownloadURL();
    console.log(url);
    return url;
  }
);

const assetUploadsSlice = createSlice({
  name: 'assetUploads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadNodeAsset.pending, (state, action) => {
      const {
        meta: {
          arg: { nodeId, asset, fileName },
          requestId,
        },
      } = action;
      if (!state.byNodeId[nodeId] || state.byNodeId[nodeId]?.loading === 'idle') {
        state.byNodeId[nodeId] = {
          fileName,
          asset,
          downloadUrl: undefined,
          loading: 'pending',
          currentRequestId: requestId,
          error: null,
        };
      }
    });
    builder.addCase(uploadNodeAsset.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { nodeId },
          requestId,
        },
        payload,
      } = action;
      const nodeAsset = state.byNodeId[nodeId];
      if (payload && nodeAsset && nodeAsset.loading === 'pending' && nodeAsset.currentRequestId === requestId) {
        nodeAsset.downloadUrl = payload;
        nodeAsset.loading = 'idle';
        nodeAsset.error = null;
        nodeAsset.currentRequestId = undefined;
      }
    });
    builder.addCase(uploadNodeAsset.rejected, (state, action) => {
      const {
        meta: {
          arg: { nodeId },
          requestId,
        },
        error,
      } = action;
      const nodeAsset = state.byNodeId[nodeId];
      if (nodeAsset && nodeAsset.loading === 'pending' && nodeAsset.currentRequestId === requestId) {
        nodeAsset.loading = 'idle';
        nodeAsset.error = error;
        nodeAsset.currentRequestId = undefined;
      }
    });
  },
});

export default assetUploadsSlice.reducer;