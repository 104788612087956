import React from 'react';
import {
  useSelector,
  // useDispatch,
} from 'react-redux';
import dayjs from 'dayjs';
import { useStripe } from '@stripe/react-stripe-js';
import { axiosInstance } from 'src/helpers';
import { useLocation, Link } from 'react-router-dom';
// import PriceSelector from './PriceSelector';
import CategorySelector from './CategorySelector';
import './index.css';
/** import types */
import type { RootState } from 'src/app/rootReducer';
// import type { AppDispatch } from 'src/app/store';
import type {
  SubscriptionResponse,
} from 'types';
import type { Stripe } from 'stripe';
import HomeschoolYearPlan from './CategorySelector/HomeschoolYearPlan';


function priceComparator(a: Stripe.Price, b: Stripe.Price): number {
  return (a.unit_amount ?? 0) - (b.unit_amount ?? 0);
}

const SubscriptionManager: React.FC = () => {
  // const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.userState);
  const [subscriberResInfo, setSubscriberResInfo] = React.useState<SubscriptionResponse | null>(null);
  const [stripePrices, setStripePrices] = React.useState<{ digital: Stripe.Price[]; withClasses: Stripe.Price[]; withFourClasses: Stripe.Price[]; homeschoolYearPlan: Stripe.Price[]; homeschoolFamilyYearPlan: Stripe.Price[]; } | null>(null);
  const [stripePromoCodes, setStripePromoCodes] = React.useState<Stripe.PromotionCode[] | null>(null);
  const stripe = useStripe();
  const [selectedPrice, setSelectedPrice] = React.useState<string | null>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const promoCode = params.get('promo');
  const prodegeTrxId = params.get("prodege_trx_id");
  
  React.useEffect(() => {
    async function fetchInfo() {
      const digital: Stripe.Price[] = [];
      const withClasses: Stripe.Price[] = [];
      const withFourClasses: Stripe.Price[] = [];
      const homeschoolYearPlan: Stripe.Price[] = [];
      const homeschoolFamilyYearPlan: Stripe.Price[] = [];

      try {
        const [{ data: { prices } }, promoCodesResult] = await Promise.all([
          axiosInstance.get<{ prices: Stripe.Price[] }>('/stripe/products/prod_JFuoakP7Ni6XqV/prices'),
          (promoCode) ? axiosInstance.get<{ promoCodes: Stripe.PromotionCode[] }>(`/stripe/promo_codes/${promoCode}`) : null,
        ] as const);

        for (const price of prices) {
          if (price.metadata.type === "digital") {
            digital.push(price);
          }
        }

        digital.sort(priceComparator);
        withClasses.sort(priceComparator);

        if (promoCodesResult?.data) {
          const { promoCodes } = promoCodesResult.data;
          setStripePromoCodes(promoCodes);
        }
      } catch (error) {
        console.error('something went wrong fetching cat', error);
      }

      try {
        const [{ data: { prices } }, promoCodesResult] = await Promise.all([
          axiosInstance.get<{ prices: Stripe.Price[] }>('/stripe/products/prod_LHMAelzcSU9I1H/prices'),
          (promoCode) ? axiosInstance.get<{ promoCodes: Stripe.PromotionCode[] }>(`/stripe/promo_codes/${promoCode}`) : null,
        ] as const);

        for (const price of prices) {
          withClasses.push(price);
        }

        withClasses.sort(priceComparator);


        if (promoCodesResult?.data) {
          const { promoCodes } = promoCodesResult.data;
          setStripePromoCodes(promoCodes);
        }
      } catch (error) {
        console.error('something went wrong fetching cat', error);
      }

      try {
        const [{ data: { prices } }, promoCodesResult] = await Promise.all([
          axiosInstance.get<{ prices: Stripe.Price[] }>('/stripe/products/prod_LbAkeyHDpoDaBG/prices'),
          (promoCode) ? axiosInstance.get<{ promoCodes: Stripe.PromotionCode[] }>(`/stripe/promo_codes/${promoCode}`) : null,
        ] as const);

        for (const price of prices) {
          withFourClasses.push(price);
        }
        withFourClasses.sort(priceComparator);

        if (promoCodesResult?.data) {
          const { promoCodes } = promoCodesResult.data;
          setStripePromoCodes(promoCodes);
        }
      } catch (error) {
        console.error('something went wrong fetching cat', error);
      }

      //homeschool plan
      try {
        const [{ data: { prices } }, promoCodesResult] = await Promise.all([
          axiosInstance.get<{ prices: Stripe.Price[] }>('/stripe/products/prod_M84XfBPHluXT6e/prices'),
          (promoCode) ? axiosInstance.get<{ promoCodes: Stripe.PromotionCode[] }>(`/stripe/promo_codes/${promoCode}`) : null,
        ] as const);

        for (const price of prices) {
          homeschoolYearPlan.push(price);
        }

        if (promoCodesResult?.data) {
          const { promoCodes } = promoCodesResult.data;
          setStripePromoCodes(promoCodes);
        }
      } catch (error) {
        console.error('something went wrong fetching cat', error);
      }

      //homeschool family plan
      try {
        const [{ data: { prices } }, promoCodesResult] = await Promise.all([
          axiosInstance.get<{ prices: Stripe.Price[] }>('/stripe/products/prod_M84iFZePudKtq3/prices'),
          (promoCode) ? axiosInstance.get<{ promoCodes: Stripe.PromotionCode[] }>(`/stripe/promo_codes/${promoCode}`) : null,
        ] as const);

        for (const price of prices) {
          homeschoolFamilyYearPlan.push(price);
        }

        setStripePrices({
          digital,
          withClasses,
          withFourClasses,
          homeschoolYearPlan,
          homeschoolFamilyYearPlan
        });

        if (promoCodesResult?.data) {
          const { promoCodes } = promoCodesResult.data;
          setStripePromoCodes(promoCodes);
        }
      } catch (error) {
        console.error('something went wrong fetching cat', error);
      }
    }
    fetchInfo();
  }, [promoCode]);
  React.useEffect(() => {
    async function fetchSubscriberInfo() {
      try {
        setSubscriberResInfo(null);
        if (!user?.isAnonymous) {
          const { data } = await axiosInstance.get<SubscriptionResponse>('/subscriptions');
          setSubscriberResInfo(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchSubscriberInfo();
  }, [user]);
  React.useEffect(() => {
    if (stripePrices && selectedPrice === null) {
      setSelectedPrice(stripePrices.digital[1].id);
    }
  }, [stripePrices, selectedPrice]);

  const selectedPromoCode = stripePromoCodes?.[0];

  if (!stripePrices || !user || (!user.isAnonymous && !subscriberResInfo) || !stripe || (promoCode && !selectedPromoCode)) {
    return (
      <div className={'subscription-manager-container'}>
        <div className={'tappity-header'}>Loading...</div>
      </div>
    );
  }

  if (!subscriberResInfo) {
    return (
      <CategorySelector
        digitalOnlyPrices={stripePrices.digital}
        withClassesPrices={stripePrices.withClasses}
        withFourClassesPrices={stripePrices.withFourClasses}
        homeschoolPlanPrices={stripePrices.homeschoolYearPlan}
        homeschoolFamilyPlanPrices={stripePrices.homeschoolFamilyYearPlan}
        promoCode={selectedPromoCode}
        prodegeTrxId={prodegeTrxId}
      />
    );
  }

  const {
    revenuecat: { subscriber },
  } = subscriberResInfo;
  const subscriberEntitlement = subscriber.entitlements.subscriber;


  if (!subscriberEntitlement || (selectedPromoCode?.code && selectedPromoCode?.code.includes('SUMMER')) || (selectedPromoCode?.code && selectedPromoCode?.code.includes('HOMESCHOOL'))) {
    return (
      <CategorySelector
        digitalOnlyPrices={stripePrices.digital}
        withClassesPrices={stripePrices.withClasses}
        withFourClassesPrices={stripePrices.withFourClasses}
        homeschoolPlanPrices={stripePrices.homeschoolYearPlan}
        homeschoolFamilyPlanPrices={stripePrices.homeschoolFamilyYearPlan}
        promoCode={selectedPromoCode}
        prodegeTrxId={prodegeTrxId}
      />
    );
  } else {
    const { product_identifier, expires_date } = subscriberEntitlement;
    const hasMainProduct = product_identifier === 'prod_JFuoakP7Ni6XqV';
    const hasClassProduct = product_identifier === 'prod_LbAkeyHDpoDaBG';
    const hasOtherProduct = product_identifier !== 'prod_JFuoakP7Ni6XqV' && product_identifier.startsWith('prod_');
    const hasPromo = product_identifier.startsWith('rc_promo');
    const expires = dayjs(expires_date);
    const isExpired = dayjs().isAfter(expires);

    return (
      <CategorySelector
        digitalOnlyPrices={stripePrices.digital}
        withClassesPrices={stripePrices.withClasses}
        withFourClassesPrices={stripePrices.withFourClasses}
        homeschoolPlanPrices={stripePrices.homeschoolYearPlan}
        homeschoolFamilyPlanPrices={stripePrices.homeschoolFamilyYearPlan}
        promoCode={selectedPromoCode}
        prodegeTrxId={prodegeTrxId}
      />

    );
  }
};

export default SubscriptionManager;