import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubscriptionManager from 'src/components/ParentMenu/SubscriptionManager';
import CTAForm from './CTAForm';
import './Landing.css';
import background1 from './assets/background1.jpg';
import background2 from './assets/background2.jpg';
import woodBackground from './assets/wood-bg.jpg';

import ClassCarousel from './ClassCarousel';
interface Props {
  setEmail: (email: string) => void;
}

const Landing: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');
  const signInUrl = promoCode ? `/sign-in?promo=${promoCode}` : '/sign-in';


  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const form = e.target as typeof e.target & {
      elements: { email: { value: string } }
    };
    props.setEmail(form.elements.email.value);
  };

  return (
    <div id="landing">
      <header style={{ backgroundImage: `url(${background1}), url(${background2})` }}>
        <nav>
        <img className={'logo'} src='/logo-updated.png' alt='logo' width="140" />
          <Link className="login" to={signInUrl}>Log in</Link>
        </nav>
        <div className="main-heading">
          <div className="hero-text">
            <h1>The #1 Science App For Kids</h1>
                      <p>From volcanoes to velociraptors, Tappity&apos;s interactive science lessons cover it all. For ages 4-10 on iPad/iPhone.</p>
          </div>
        </div>
      </header>

      <div className="signup-box">
        <p>Sign-up for a 2-week free trial.</p>
        <CTAForm onSubmit={onSubmit} />
      </div>

      <section className="stats" style={{ backgroundImage: `url(${woodBackground})` }}>
        <div className="inner-grid">
          <div>
            <span className="stat">4500+ </span>
            <span className="text">5 Star Reviews</span>
          </div>
          <div>
            <span className="stat">40 Million+ </span>
            <span className="text">Lessons Completed</span>
          </div>
          <div>
            <span className="stat">500+ </span>
            <span className="text">Lessons</span>
          </div>
          <div>
            <span className="stat">100,000+ </span>
            <span className="text">Kids Learning</span>
          </div>
        </div>
          </section>
          <section className={'logos'}>
          <img src='/featured-by-black.png' alt='logos' />
          </section>
          

      <section className="classes">
        <h2>Lessons That Are Out Of This World Fun</h2>
        <p>Enjoy awe-inspiring, interactive lessons designed by educators, built by technologists, and produced in Hollywood.</p>
        <div className="class-carousel">
          <ClassCarousel />
        </div>
      </section>

      <section className="praise">
        <div className="inner-grid">
          <div className="reviewList">
            <div className="review">
              <div className="author">
                <img src="parent-beth.jpg" />
                <span className="firstline">
                  <span className="parentname">Beth Goodwin</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">April 14th</span>
              </div>
              <div className="content">
                My kids have completely fallen in love with science thanks to Tappity and all the amazing teachers they have.
              </div>
              <div className="likes">
                163
              </div>
            </div>
            <div className="review">
              <div className="author">
                <img src="parent-michael.jpg" />
                <span className="firstline">
                  <span className="parentname">Michael Williams</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">Jan 28th</span>
              </div>
              <div className="content">
                Tappity is like having a personal science teacher for your child. My 5 and 7 year olds are still enthralled after a full year.
              </div>
              <div className="likes">
                194
              </div>
            </div>
            <div className="review">
              <div className="author">
                <img src="parent-audra.jpg" />
                <span className="firstline">
                  <span className="parentname">Audra</span>
                  &nbsp;recommends <span className="tappity">Tappity</span>
                </span>
                <span className="date">Jan 4th</span>
              </div>
              <div className="content">
                My kid is obsessed with the interactive features, and he has learned and retained a truly shocking amount of info.
              </div>
              <div className="likes">
                387
              </div>
            </div>
          </div>
          <div>
            <h2>Trusted by Families and Schools</h2>
            <p>Join 100,000+ families, teachers, and schools who trust Tappity&apos;s safe, ad-free platform to inspire curiosity in their kids</p>
          </div>
        </div>
      </section>
      <section className="lastcall">
        <h2>Get a free 14-day trial and see for yourself</h2>
        <CTAForm onSubmit={onSubmit} />
      </section>
      <footer>
      <img className={'logo'} src='/logo-updated.png' alt='logo' width="140" />
        <nav>
          <Link className="login" to={signInUrl}>Log in</Link>
          <a href="https://apps.apple.com/us/app/tappity-interactive-stories/id1265019371?ls=1">Download iOS app</a>
                  <a href="https://www.tappityapp.com/support">Support</a>
                  <a href="mailto:hey@tappityapp.com">Contact us</a>
                  
        </nav>
        <p>Copyright &copy; 2022 Tappity, Inc. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Landing;