import React from 'react';
import { useDispatch } from 'react-redux';
import { updateProfileStoryProgress } from 'src/features/userProfiles/userProfilesSlice';
import './index.css';
/** type imports */
import { useRect } from 'src/RectHook';
import type { AppDispatch } from 'src/app/store';

import type {
  NodeState,
  NodeResult,
  LiveStory,
  LiveTrueOrFalseQuestionNode
} from 'types';

interface Props {
  seasonId: string;
  storyId: string;
  story: LiveStory;
  nodeResult: NodeResult;
  active: boolean;
  nodeState: NodeState<LiveTrueOrFalseQuestionNode>;
}

const LivePollQuestionPlayback: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeState, active, nodeResult, storyId } = props;
  const { node } = nodeState;
  const [optionSelected, setSelectedOption] = React.useState<'true' | 'false' | null>(null);
  const option1Bar = React.useRef<HTMLDivElement>(null);
  const option1BarRect = useRect(option1Bar);
  const option2Bar = React.useRef<HTMLDivElement>(null);
  const option2BarRect = useRect(option2Bar);
  const nodeContainer = React.useRef<HTMLDivElement>(null);
  const nodeContainerRect = useRect(nodeContainer);
  const option1ResultContainer = React.useRef<HTMLDivElement>(null);
  const option2ResultContainer = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {

    const bar1 = option1Bar.current;
    const bar2 = option2Bar.current;

    const option1Container = option1ResultContainer.current;
    const option2Container = option2ResultContainer.current;

    if (bar1 && bar2 && nodeResult && optionSelected && option1Container && option2Container) {
      let bar1Height = 0;
      let bar2Height = 0;
      // const totalVotes = nodeResult.option1Votes + nodeResult.option2Votes;
      const heightOffset = 100;
      const maxHeight = nodeContainerRect.height - heightOffset;

      if (nodeResult.option1Votes > nodeResult.option2Votes) {
        bar1Height = maxHeight;
        bar2Height = maxHeight * (nodeResult.option2Votes / nodeResult.option1Votes);
      } else {
        bar2Height = maxHeight;
        bar1Height = maxHeight * (nodeResult.option1Votes / nodeResult.option2Votes);
      }

      const option1BarHeight = (bar1Height) + 'px';
      const option2BarHeight = (bar2Height) + 'px';

      bar1.style.setProperty("height", option1BarHeight);
      bar2.style.setProperty("height", option2BarHeight);
      option1Container.style.setProperty("margin-top", "30px");
      option2Container.style.setProperty("margin-top", "30px");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option1BarRect, option1Bar, option2BarRect, option2Bar, nodeContainerRect, nodeResult]);

  const [{ targetNodeId }] = Object.values(node.edges);

  let imageURL;
  const liveImageOverlayStyle: React.CSSProperties = {};
  if (node.imageOverlay) {
    imageURL = new URL(`https://assetcdn.tappityapp.com/${node.imageOverlay}`);
    liveImageOverlayStyle.backgroundImage = `url(${imageURL.toString()})`;
  }

  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }

  let resultsContainerHidden = 'hidden';
  let interactionPanelHidden = '';

  if (optionSelected) {
    resultsContainerHidden = '';
    interactionPanelHidden = 'hidden';
  }
  return (
    <div node-id={nodeState.nodeId} node-type={node.type} ref={nodeContainer} className={`node-container ${nodeContainerHidden}`}>
      <div style={liveImageOverlayStyle} className={`live-image-overlay`}></div>
      <div className={`live-node-interaction-panel ${interactionPanelHidden}`}>
        <div
          className={`option-item true-option`}
          style={{
            backgroundImage: 'url(/thumbs-up-button.png)',
            display: (optionSelected !== null) ? 'none' : 'inline-block',
          }}
          onClick={() => {
            console.log('true option selected');
            setSelectedOption('true');

            dispatch(updateProfileStoryProgress({
              storyId,
              completed: false,
              nextNodeId: targetNodeId,
              completedNodePoints: node.points,
            }));
          }}
        >
        </div>
        <div
          className={`option-item false-option`}
          style={{
            backgroundImage: 'url(/thumbs-down-button.png)',
            display: (optionSelected !== null) ? 'none' : 'inline-block',
          }}
          onClick={() => {
            console.log('false option selected');
            setSelectedOption('false');
            dispatch(updateProfileStoryProgress({
              storyId,
              completed: false,
              nextNodeId: targetNodeId,
              completedNodePoints: node.points,
            }));
          }}
        >
        </div>
        <div className={`playback-node-results-container true-false-question-results ${resultsContainerHidden}`}>
          <div ref={option1ResultContainer} className={'option-votes-container'}>
            <div className={'option-1-votes-head'}>{nodeResult.option1Votes}</div>
            <div ref={option1Bar} className={'option-1-votes-body'}></div>
          </div>
          <div ref={option2ResultContainer} className={'option-votes-container'}>
            <div className={'option-2-votes-head'}>{nodeResult.option2Votes}</div>
            <div ref={option2Bar} className={'option-2-votes-body'}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivePollQuestionPlayback;