import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSubscriber } from 'src/features/revenuecat/revenuecatSlice';
import { useHistory } from 'react-router-dom';
import useInterval from '@use-it/interval';
import { axiosInstance } from 'src/helpers';
import {
  useLocation,
} from 'react-router-dom';
import './index.css';
import { Link } from 'react-router-dom';
/** import types */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { RCSubscriber } from 'types';
import type { Stripe } from 'stripe';

const CheckoutSuccess: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { subscriber } = useSelector((state: RootState) => state.revenuecatState);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');
  const [entitlement, setEntitlement] = React.useState<RCSubscriber['entitlements'][string] | null>(null);
  const [showIosModal, setShowIosModal] = React.useState<boolean>(false);
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  React.useEffect(() => {
    async function finishSuccess() {
      if (entitlement) {
        try {
          const { data: { session } } = await axiosInstance.get<{ session: Stripe.Checkout.Session }>(`/stripe/sessions/${sessionId}`);
          try {
            const { currency, amount_total } = session;
            const purchaseObj = {
              currency: currency ?? 'usd',
              value: (amount_total ?? 0) / 100,
              content_category: 'STRIPE',
              // content_type: 'product',
              // content_ids: session.
              // content_name: 'tappity subscription'
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).fbq('track', 'Subscribe', purchaseObj, { eventId: session.id }); // use this only for pixel because https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events#event-deduplication-options
          } catch (error) {
            if (error instanceof Error) {
              console.error(error.message, 'facebook pixel tracking failed');
            }
          }

        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          }
        }
        if (iOS()) {
          setShowIosModal(true);
        } else {
          history.push('/');
        }
      }
    }
    finishSuccess();
  }, [entitlement, history, sessionId]);

  /*useInterval(async () => {
    try {
      if (subscriber?.entitlements.subscriber) {
        setEntitlement(subscriber.entitlements.subscriber);
      } else {
        dispatch(fetchSubscriber());
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }, 5000);*/



  return (
    <div className={'checkout-success-container'}>
      {/*(!entitlement) ? (*/
        
        <React.Fragment>
          <nav>
          <img className={'logo'} src='/logo-updated-1.png' alt='logo' width="180" />
          </nav>
          <hr className="solid"></hr>
          <div className={'left-side'}>
          <h1>You&apos;re all set!</h1>
          <h3>What&apos;s next?</h3>
          <ul>
            <li>1. Using your iPhone or iPad search for Tappity app on the app store. Or scan this QR code.</li>
            <li>2. Download Tappity on your device.</li>
            <li>3. Sign in with the email you just used.</li>
          </ul>
          </div>
          <div className={'right-side'}>
          <img src='/frame.png' alt='qr-code' />
          </div>

          <hr className="solid"></hr>
          <div className={'footer'}>
            <p>Don&apos;t have an iPad or iPhone?</p>
            <Link to='/'><p>Continue here</p></Link>
          </div>
          {showIosModal && (
            <React.Fragment>
              <div>Looks like you are on a iOS device. We have an app!</div>
              <button onClick={() => window.location.replace('https://apps.apple.com/us/app/tappity-k-5-science-for-kids/id1265019371')} className={'tappity-form-submit-button'}>
                Download
              </button>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    </div>
  );
};

export default CheckoutSuccess;