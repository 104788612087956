import React from 'react';
import './ClassCarousel.css';
import Carousel from '../carousel/Carousel';
/** import types */

interface StaticSeason {
  title: string;
  image: string;
}

const seasons: StaticSeason[] = [
  {
    title: "Mars Explorer",
    image: "class-images/mars.jpg",
  },
  {
    title: "Dinosaur Adventures",
    image: "class-images/dinosaurs.jpg",
  },
  {
    title: "Volcano Expert",
    image: "class-images/volcanoes.jpg",
  },
  {
    title: "LEGO Architect",
    image: "class-images/legos.jpg",
  },
  {
    title: "Space Travel",
    image: "class-images/space.jpg",
  },
  {
    title: "Extreme Weather",
    image: "class-images/weather.jpg",
  },
  {
    title: "Electricity",
    image: "class-images/electricity-covers.jpg",
  },
  {
    title: "Chemistry",
    image: "class-images/chemistry-cover.jpg",
  },
  {
    title: "Human Body",
    image: "class-images/body-cover.jpg",
  },
  {
    title: "Candy",
    image: "class-images/candy-cover.jpg",
  },
  {
    title: "Insects",
    image: "class-images/insects-cover.jpg",
  },
  {
    title: "Rover Robots",
    image: "class-images/rover-covers.jpg",
  },
  {
    title: "Prehistoric Animals",
    image: "class-images/dinosaur-cover.jpg",
  },
  {
    title: " ",
    image: "class-images/more-lessons.jpg",
  },
];

function highlightInput() {
  const i = document.getElementById("final-email-input");
  i?.scrollIntoView();
  i?.focus();
}

const ClassCarousel: React.FC = () => {
  return (
    <Carousel >
      {seasons.map(({ title, image }, index) => {
        const seasonCardStyle: React.CSSProperties = {
          backgroundImage: `url(${image})`,
        };
        return (
          <div key={index} className='class-item' style={seasonCardStyle} onClick={highlightInput}>
            <div className={'class-info'}>
              <div className={'class-title'}>{title}</div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default ClassCarousel;