import firebase from 'firebase';
import 'firebase/firestore';
import type {
  Story,
  StorySnapshot,
  SnapshotOptions,
} from 'types';


export const storyConverter: firebase.firestore.FirestoreDataConverter<Story> = {
  toFirestore(story: Story) {
    const snapshot = {
      ...story,
      createdBy: firebase.firestore().doc('adminUsers/' + story.createdBy),
      createdAt: firebase.firestore.Timestamp.fromMillis(story.createdAt),
      updatedAt: (story.updatedAt !== undefined) ? firebase.firestore.Timestamp.fromMillis(story.updatedAt) : undefined,
    };

    if (snapshot.updatedAt === undefined) {
      delete snapshot.updatedAt;
    }

    return snapshot;
  },
  fromFirestore(snapshot: StorySnapshot, options: SnapshotOptions): Story {
    const data = snapshot.data(options);
    if (data.type === 'live') {
      return {
        ...data,
        status: data.status || 'staged',
        createdBy: data.createdBy.id,
        createdAt: data.createdAt.toMillis(),
        updatedAt: data.updatedAt?.toMillis(),
      };
    }
    const story: Story = {
      ...data,
      status: data.status || 'staged',
      createdBy: data.createdBy.id,
      createdAt: data.createdAt.toMillis(),
      updatedAt: data.updatedAt?.toMillis(),
    };
    return story;
  },
};

