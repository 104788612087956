import React from 'react';
import { useLocation } from 'react-router-dom';

const NoMatch: React.FC = () => {
  const { pathname } = useLocation();
  console.log('Did not match a route');

  return (
    <div>
      <h1>404</h1>
      <p><span>No match for <code>{pathname}</code></span></p>
    </div>
  );
};

export default NoMatch;