import firebase from 'firebase';
import 'firebase/app';
import 'firebase/storage';
/** type imports */
// firebase.
class AssetFetcher {
  static cacheName = 'AppAssetCache';
  cache: null | Cache;
  storage: firebase.storage.Reference;
  userUploads: firebase.storage.Reference;
  constructor() {
    this.cache = null;
    this.storage = firebase.storage().ref();
    this.userUploads = firebase.app().storage('gs://tappity-user-uploads').ref();
  }
  async init(): Promise<void> {
    if (this.cache === null) {
      this.cache = await caches.open(AssetFetcher.cacheName);
    }
  }
  async getCache(): Promise<Cache> {
    if (this.cache === null) {
      this.cache = await caches.open(AssetFetcher.cacheName);
    }
    return this.cache;
  }
  async getAsset(assetPath: string, userAssets = false): Promise<string> {
    const cache = await this.getCache();
    let assetRef;
    if (userAssets) {
      assetRef = this.userUploads.child(assetPath);
    } else {
      assetRef = this.storage.child(assetPath);
    }
    // if (userAssets) {
    //   console.log('assetPath', assetPath);
    // }

    const downloadUrl: string = await assetRef.getDownloadURL();

    // const cachedResponse = await this.cache.match(assetPath);
    // if (!cachedResponse || !cachedResponse.ok) throw new Error('error with cached response');
    let res = await cache.match(downloadUrl, { ignoreSearch: true });
    if (!res) {
      console.log('did not find in cache');
      const myRequest = new Request(downloadUrl, { method: 'GET' });
      await cache.add(downloadUrl);
      res = await fetch(myRequest);
    } else {
      // console.log('wow found it in the cache');
    }

    if (res.ok) {
      // const clone = res.clone();
      // cache.pu
      // console.log(res.type);
      const blob = await res.blob();
      // console.log('made is past blob');
      // return blob;
      const urlToBlob = URL.createObjectURL(blob);
      return urlToBlob;
    } else {
      throw new Error('Something went wrong');
    }



  }
  initiateUserUploadAsset({ assetPath, asset }: { assetPath: string, asset: Blob }): firebase.storage.UploadTask {
    const assetRef = this.userUploads.child(assetPath);
    const task = assetRef.put(asset);
    return task;
  }
}

export default AssetFetcher;