import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import posthog from 'posthog-js';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import IdBadge from 'src/components/stories/StoryPlayer/helperComponents/IdBadge';
import { useChip } from 'src/components/chip/ChipHook';
import { getLevel, getEarnedPointsAudioAssetPath } from 'src/helpers';
import { updateProfileStoryProgress } from 'src/features/userProfiles/userProfilesSlice';
import { fetchCharacterProfiles } from 'src/features/characterProfiles/characterProfilesSlice';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  PointsDisplayNode,
  NodeState,
  Story,
  StoryCompletedEvent,
} from 'types';
/** Selie specific styling */
import './index.css';

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  activeOverride?: boolean;
  nodeState: NodeState<PointsDisplayNode>;
}
const PointsDisplay: React.FC<Props> = ({ activeOverride = true, ...props }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const [hideBadge, setHideBadge] = React.useState<boolean>(true);
  const [playCount, setPlayCount] = React.useState<number>(0);
  const [addJob] = useChip();
  const {
    selectedProfileId,
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);
  const {
    byCharacterProfileId,
  } = useSelector((state: RootState) => state.characterProfilesState);
  const { assets } = useSelector((state: RootState) => state.chipAssetsState);
  const userProfile = selectedProfileId && userProfiles && userProfiles[selectedProfileId];
  // const nodeContainerRef = React.useRef<HTMLDivElement>(null);
  // const nodeContainerRect = useRect(nodeContainerRef);
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  const active = (nodeId === urlNodeId) && activeOverride;
  React.useEffect(() => {
    if (!byCharacterProfileId) {
      dispatch(fetchCharacterProfiles());
    }
  }, [dispatch, byCharacterProfileId]);

  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  React.useEffect(() => {
    if (active && assets && userProfile && storyId in userProfile.storyProgress && playCount === 0) {
      const storyProgress = userProfile.storyProgress[storyId];
      const points = userProfile.points;
      console.log(storyProgress.score);
      addJob({
        audioClip: assets['BundledRemoteAssets/audio/Leveling/points-announce-chip.mp3'],
      });
      addJob({
        audioClip: assets[`BundledRemoteAssets/audio/Leveling/Calculating/points-calculating-${getRandomInt(1, 3)}-chip.mp3`],
      });
      addJob({
        audioClip: assets['BundledRemoteAssets/audio/Leveling/points-earn-intro-chip.mp3'],
        onFinish: async () => {
          setHideBadge(false);
        },
      });
      const score = storyProgress.score;
      if (score) {
        addJob({
          audioClip: assets[getEarnedPointsAudioAssetPath(score)],
        });
        const updatedPoints = points + score;
        const currentLevel = getLevel(points);
        const levelAfter = getLevel(updatedPoints);
        const levelGained = currentLevel < levelAfter;

        if (levelGained) {
          addJob({
            audioClip: assets['BundledRemoteAssets/audio/Leveling/level-gain-alt-pt1-chip-01.mp3'],
          });
          addJob({
            audioClip: assets[`BundledRemoteAssets/audio/Leveling/levels/level-${levelAfter + 1}-chip.mp3`],
          });
          addJob({
            audioClip: assets['BundledRemoteAssets/audio/Leveling/level-gain-alt-pt2-chip.mp3'],
          });
          addJob({
            audioClip: assets[`BundledRemoteAssets/audio/Leveling/Level gained message/level-gain-celebration-${getRandomInt(1, 3)}-chip.mp3`],
          });
        } else {
          addJob({
            audioClip: assets[`BundledRemoteAssets/audio/Leveling/No level gained message/no-level-gain-${getRandomInt(1, 10)}-chip.mp3`],
          });
        }
      }
      setPlayCount(1);
    }
  }, [addJob, assets, userProfile, storyId, active, playCount]);

  if (!nodeWithAssets || !userProfiles || !assets) {
    return <div>Loading....</div>;
  }

  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  let score;
  if (userProfile && userProfile.storyProgress && userProfile.storyProgress[storyId]) {
    score = userProfile.storyProgress[storyId].score;
  }

  return (
    <div node-id={nodeState.nodeId} node-type={node.type} className={`node-container full-node-container ${nodeContainerHidden}`}>
      <div className="badge-container">
        <IdBadge
          size={25}
          rotation={0}
          hidden={hideBadge}
          storyTotal={score}
        />
      </div>
      <div className='live-node-interaction-panel'>
        <div
          className={`option-item true-option centered-option`}
          style={{
            backgroundImage: 'url(/thumbs-up-button.png)',
            display: (hideBadge) ? 'none' : 'inline-block',
          }}
          onClick={async () => {
            try {
              dispatch(updateProfileStoryProgress({ storyId, completed: true, }));
              if (story.type === 'live' && byCharacterProfileId) {
                const event: StoryCompletedEvent = {
                  storyName: story.title,
                  currentlyLive: false, //  (if story was live when kid started watching)
                  storyType: story.type, // (type field on story (trivia | series | mini | feature | checkpoint | live | badge))
                  subType: story.subType, // (subType field on story (case activity | badge | build_a_blank | drawing | mission | narrative))
                  percentComplete: 100, // (If resuming a story percent of nodes complete out of total nodes)
                  characterProfileId: story.characterProfileId, // (character profile id on the story)
                  characterDisplayName: byCharacterProfileId[story.characterProfileId].characterProfile.displayName,
                };
                console.log('storyCompleted', event);
                posthog.capture('storyCompleted', event);
                try {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (window as any).fbq('track', 'ViewContent');
                } catch (error) {
                  if (error instanceof Error) {
                    console.error(error.message);
                  }
                }
              }
              const nextNodeUrl = '/';
              history.replace(nextNodeUrl);
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
    </div >
  );
};

export default PointsDisplay;