import React, { ReactNode } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  fetchCharacterProfiles,
} from 'src/features/characterProfiles/characterProfilesSlice';
import { fetchStories } from 'src/features/stories/storiesSlice';
import './filterButtons.css';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { LiveStory } from 'types';
import type { CharacterProfileState } from 'src/features/characterProfiles/characterProfilesSlice';

interface FilterButtonsProps {
  typeFilters: string[];
  topicFilters: string[];
  tinkererFilters: string[];
  setTypeFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setTopicFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setTinkererFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterButtons: React.FC<FilterButtonsProps> = (props: FilterButtonsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { stories, tags, characterProfiles, loading } = useSelector((state: RootState) => {
    return {
      characterProfiles: state.characterProfilesState.byCharacterProfileId,
      stories: state.storiesState.stories,
      tags: state.tagsState.tags,
      loading: state.characterProfilesState.loading === 'pending'
    };
  }, shallowEqual);

  React.useEffect(() => {
    if (characterProfiles === null) {
      dispatch(fetchCharacterProfiles());
    }
    if (stories === null) {
      dispatch(fetchStories());
    }
  }, [dispatch, characterProfiles, stories]);

  if (loading || !characterProfiles || !tags || !stories) {
    return <div>loading...</div>;
  }

  const filtersActive = (props.typeFilters.length + props.topicFilters.length + props.tinkererFilters.length) > 0;

  function clearFilters() {
    props.setTypeFilters([]);
    props.setTopicFilters([]);
    props.setTinkererFilters([]);
  }

  const topics = Object.entries(tags)
    .filter(([tagId]) => stories.byTagId[tagId]?.length > 0)
    .map(([tagId, tag]) => {
      return {
        value: tagId,
        title: (tag.emoji ? `${tag.emoji} ` : "") + tag.displayName
      };
    });

  const types = [
    { value: "activity", title: "Activities and experiments" },
    { value: "trivia", title: "Science trivia" },
    { value: "drawing", title: "Drawing activity" },
    { value: "mission", title: "Science mission" },
  ];

  const profileIdSet = new Set(Object.values(stories.byId)
    .filter(({ story }) => story.type === 'live')
    .map(({ story }) => (story as LiveStory).characterProfileId));

  const profiles = Object.entries(characterProfiles)
    .filter(([profileId]) => profileIdSet.has(profileId));

  const tinkerers = profiles
    .filter(([, profile]) => !profile.characterProfile.isGuest)
    .map(([profileId, profile]) => {
      return {
        value: profileId,
        title: profile.characterProfile.displayName.substring(0, 10),
        element: <CharacterProfile profile={profile} />
      };
    });

  return (
    <nav id="story-filters">
      <PopUpButton title={"All Types"}
        items={types}
        subtitle="Select 1 or more lesson types to show"
        filters={props.typeFilters}
        setFilters={props.setTypeFilters} />
      <PopUpButton title={"All Topics"} items={topics}
        subtitle="Select 1 or more topics to show"
        filters={props.topicFilters}
        setFilters={props.setTopicFilters} />
      <PopUpButton title={"All Tinkerers"} items={tinkerers}
        subtitle="Select 1 or more tinkerers to show"
        className="tinkerers-filter"
        filters={props.tinkererFilters}
        setFilters={props.setTinkererFilters} />
      {filtersActive && <button className="clear-filters" onClick={clearFilters}>ⓧ Clear Filters</button>}
    </nav>
  );
};

interface CharacterProfileProps {
  profile: CharacterProfileState;
}

const CharacterProfile: React.FC<CharacterProfileProps> = ({ profile }: CharacterProfileProps) => {
  return (
    <div className="profile-image">
      <img src={`https://assetcdn.tappityapp.com/${profile.characterProfile.profileImage}`} />
    </div>
  );
};

interface PopUpButtonItem {
  title: string;
  value: string;
  element?: ReactNode;
}

interface PopUpButtonProps {
  items: PopUpButtonItem[];
  title: string;
  subtitle: string;
  filters: string[];
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
}

const PopUpButton: React.FC<PopUpButtonProps> = ({ items, title, subtitle, filters, setFilters, className = "" }: PopUpButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const drop = React.useRef<HTMLDivElement>(null);
  const activeClass = filters.length > 0 ? "filterActive" : "";

  function handleClick(e: MouseEvent) {
    if (drop?.current && !(e.target as HTMLElement).closest(`.${drop.current.className}`) && open) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  function itemClicked(id: string) {
    const active = filters.includes(id);
    if (active) setFilters(filters.filter(i => i !== id));
    else setFilters([...filters, id]);
  }

  return (
    <span className={`filter-button ${className}`}>
      <button className={activeClass} onClick={() => setOpen(!open)}>{title}</button>
      {open && (
        <div className="dropdown-card" ref={drop}>
          <div className="dropdown-subtitle">{subtitle}</div>
          <button onClick={() => setOpen(false)} className="dropdown-close">ⓧ</button>
          <div className="dropdown-item-wrapper">
            {items.map((item) => (
              <div key={item.value} className="dropdown-item">
                {item.element && <div onClick={() => itemClicked(item.value)}>{item.element}</div>}
                <div>
                  <input
                    id={`filter-item-${item.value}`}
                    className="filter-item"
                    type="checkbox"
                    checked={filters.includes(item.value)}
                    value={item.value}
                    onChange={() => itemClicked(item.value)}
                  />
                  <label className="filter-item" htmlFor={`filter-item-${item.value}`}>
                    {item.title}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </span>
  );
};

export default FilterButtons;