import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chip from 'src/components/chip';
import { fetchCharacterProfiles } from 'src/features/characterProfiles/characterProfilesSlice';
import posthog from 'posthog-js';
import PointsDisplay from '../nodes/PointsDisplay';
import LiveImageOverlayPlayback from '../nodes/LiveImageOverlayPlayback';
import LivePollQuestionPlayback from '../nodes/LivePollQuestionPlayback';
import LiveTrueOrFalseQuestionPlayback from '../nodes/LiveTrueOrFalseQuestionPlayback';
import LiveBuilderImageSelectorPlayback from '../nodes/LiveBuilderImageSelectorPlayback';
import BuilderImageAssemblerPlayback from '../nodes/BuilderImageAssemblerPlayback';
import Selfie from '../nodes/Selfie';
import BadgeEarning from '../nodes/BadgeEarning';
import dayjs from 'dayjs';
import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useRect } from 'src/RectHook';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  UserProfile,
  UserProfileAssets,
  NodeState,
  LiveStory,
  PointsDisplayNode,
  StoryState,
  LiveImageOverlayNode,
  LivePollQuestionNode,
  LiveTrueOrFalseQuestionNode,
  StoryAbortedEvent,
  SelfieNode,
  BadgeEarningNode,
  LiveBuilderImageSelectorNode,
  BuilderImageAssemblerNode,
} from 'types';

interface Params {
  seasonId: string;
  storyId: string;
  nodeId?: string;
}

interface Props {
  storyRef: StoryState<LiveStory>;
  currentProfile: (UserProfile & UserProfileAssets);
}

const LiveStoryPlaybackPlayer: React.FC<Props> = ({ storyRef, currentProfile }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { seasonId, storyId, nodeId } = useParams<Params>();
  const componentWillUnmount = React.useRef(false);
  const history = useHistory();
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');
  const storyPlayerContainer = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const videoRect = useRect(videoRef);
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const nodesRef = React.useRef(storyRef.nodes);
  const [videoOpacity, setVideoOpacity] = React.useState<0 | 1>(1);
  const [nodeIsActive, setNodeIsActive] = React.useState(false);
  const [videoPlaying, setVideoPlaying] = React.useState<boolean | null>(false);
  const [percentageComplete, setPercentageComplete] = React.useState(0);
  const { byCharacterProfileId } = useSelector((state: RootState) => state.characterProfilesState);
  const { subscriber } = useSelector((state: RootState) => state.revenuecatState);
  const { selectedProfileId, userProfiles } = useSelector((state: RootState) => state.userProfilesState);

  const story = storyRef.story;

  const storyProgress = currentProfile.storyProgress[storyId] || {};

  React.useEffect(() => {
    if (byCharacterProfileId === null) {
      dispatch(fetchCharacterProfiles());
    }
  }, [dispatch, byCharacterProfileId]);



  const handleTimeUpdate = React.useCallback((event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const videoPlayer = event.currentTarget;
    const currentSeconds = videoPlayer.currentTime;
    const nodes = nodesRef.current;
    if (nodeId) {
      const currentNode = nodes[nodeId];

      let prevNodeId: string | undefined;
      let prevNode;
      const nodeArr = Object.entries(nodes).filter(([, { node }]) => !node.isDeleted);

      for (const [id, nodeState] of nodeArr) {
        const [edge] = Object.values(nodeState.node.edges);
        const targetNodeId = edge?.targetNodeId;
        if (targetNodeId === nodeId) {
          prevNodeId = id;
        }
      }

      if (prevNodeId) {
        prevNode = nodes[prevNodeId];
      }
      if (
        currentNode.node.type === 'Live Image Overlay' ||
        currentNode.node.type === 'Live Poll Question' ||
        currentNode.node.type === 'Live True Or False Question' ||
        currentNode.node.type === 'Live Builder Image Selector'
      ) {
        const [edge] = Object.values(currentNode.node.edges);
        const nextNodeId = edge?.targetNodeId as string | undefined;

        /** if the current time is after the end of the current node */
        if (currentSeconds > (currentNode.node.endTime / 1000)) {
          if (nextNodeId) {
            /** updates the URL to advance the story one node */
            history.replace(`/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`);
          } else {
            console.log("missing nextNodeId. Can't proceed to next node");
          }
        } else {
          if (
            /** 
             * if the current time is before the end of the previous node
            **/
            prevNode && (
              prevNode.node.type === 'Live Image Overlay' ||
              prevNode.node.type === 'Live Poll Question' ||
              prevNode.node.type === 'Live True Or False Question' ||
              prevNode.node.type === 'Live Builder Image Selector'
            ) && currentSeconds < (prevNode.node.endTime / 1000)
          ) {
            /** 
             * this sets the video time to the end of the previous node
             */
            videoPlayer.currentTime = (prevNode.node.endTime / 1000);
          } else {
            if (currentSeconds < (currentNode.node.startTime / 1000)) {
              if (nodeIsActive) {
                setNodeIsActive(false);
              }
            } else {
              if (!nodeIsActive) {
                setNodeIsActive(true);
              }
            }
          }
        }
      } else if (currentNode.node.type === 'Points Display') {
        setVideoOpacity(0);
        if (videoPlayer.paused) {
          /** 
           * we use nodeIsActive because 'Points Display' does not have 
           * startTime and endTime so we manually control the 'nodeIsActive' state
           */
          if (!nodeIsActive) {
            setNodeIsActive(true);
          }
        } else {
          videoPlayer.pause();
        }
      } else if (currentNode.node.type === 'Badge Earning') {
        if (videoPlayer.paused) {
          /** 
           * we use nodeIsActive because 'Badge Earning' does not have 
           * startTime and endTime so we manually control the 'nodeIsActive' state
           */
          if (!nodeIsActive) {
            setNodeIsActive(true);
          }
        } else {
          videoPlayer.pause();
        }
      } else if (currentNode.node.type === 'Builder Image Assembler') {
        if (videoPlayer.paused) {
          /** 
           * we use nodeIsActive because 'Builder Image Assembler' does not have 
           * startTime and endTime so we manually control the 'nodeIsActive' state
           */
          if (!nodeIsActive) {
            setNodeIsActive(true);
          }
        } else {
          videoPlayer.pause();
        }

      }
    }
  }, [nodeId, nodeIsActive, history, seasonId, storyId]);
  React.useEffect(() => {
    return () => {
      // need to do this in order to make sure the cleanup section in the hook below only fires when the component unmounts
      componentWillUnmount.current = true;
    };
  }, []);

  React.useEffect(() => {
    const nodes = nodesRef.current;
    const startingNodeId = story?.startingNodeId;
    if (nodeId && startingNodeId) {
      const nodeArr = Object.entries(nodes).filter(([, { node }]) => !node.isDeleted);
      const totalNodes = nodeArr.length;

      const currentNode = nodes[nodeId];
      let node = nodes[startingNodeId];
      let count = 0;
      while (node.nodeId !== currentNode.nodeId) {
        count++;
        const nextNodeId = Object.values(node.node.edges)[0].targetNodeId;
        node = nodes[nextNodeId];
      }
      const complete = (count / totalNodes) * 100;
      setPercentageComplete(complete);
    }
    return () => {
      if (componentWillUnmount.current && story && nodeId && byCharacterProfileId) {
        const nodeArr = Object.entries(story.nodes);
        let finalNodeId: string | undefined;
        nodeArr.forEach(([id, { isEndingNode }]) => {
          if (isEndingNode) {
            finalNodeId = id;
          }
        });
        if (nodeId !== finalNodeId) {
          const event: StoryAbortedEvent = {
            storyName: story.title,
            currentlyLive: false, //  (if story was live when kid started watching)
            storyType: story.type, // (type field on story (trivia | series | mini | feature | checkpoint | live | badge))
            subType: story.subType, // (subType field on story (case activity | badge | build_a_blank | drawing | mission | narrative))
            lastNode: nodeId, // (id of last node the kid watched)
            percentComplete: percentageComplete, // (If resuming a story percent of nodes complete out of total nodes)
            characterProfileId: story.characterProfileId, // (character profile id on the story)
            characterDisplayName: byCharacterProfileId[story.characterProfileId].characterProfile.displayName,
          };
          console.log('storyAborted', event);
          posthog.capture('storyAborted', event);
        }
      }
    };
  }, [story, nodeId, byCharacterProfileId, percentageComplete, seasonId, storyId, userProfiles, selectedProfileId]);



  if (!story?.video) {
    return <div>Loading</div>;
  }


  const livePlaybackURL = new URL(`https://assetcdn.tappityapp.com/${story.video}`);
  return (
    <div ref={storyPlayerContainer} className='live-story-player'>
      <img
        className={`video-play-button ${(videoPlaying) ? 'hidden' : ''}`}
        src='/play_button.png'
        onClick={async () => {
          try {
            if (subscriber) {
              const now = dayjs();
              if (!subscriber.entitlements.subscriber || dayjs(subscriber.entitlements.subscriber.expires_date).isBefore(now)) {
                const subsriptionUrl = promoCode ? `/subscription?promo=${promoCode}` : '/subscription';
                history.replace(subsriptionUrl);
              } else {

                if (videoRef.current) {
                  await videoRef.current.play();
                  if (audioRef.current) {
                    await audioRef.current.play();
                    audioRef.current.volume = 0.35;
                  }
                } else {
                  console.log("missing video");
                }
              }
            }


          } catch (error) {
            if (error instanceof Error) {
              console.error(error.message);
            }
          }
        }}
      />
      <audio
        ref={audioRef}
        autoPlay={false}
        loop
        src='https://assetcdn.tappityapp.com/audio/audio_tappity-theme-medium-soft.mp3'
      />
      <video
        ref={videoRef}
        style={{
          opacity: videoOpacity,
        }}
        autoPlay={false}
        playsInline
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => {
          setVideoPlaying(true);
        }}
        onEnded={() => {
          const nodes = nodesRef.current;
          if (nodeId) {
            const currentNode = nodes[nodeId];
            if (currentNode.node.type === 'Points Display') {
              if (!nodeIsActive) {
                console.log('activating points display node');
                setNodeIsActive(true);
              }
            }
          }
        }}
        src={livePlaybackURL.toString()}
      />
      <Chip />
      {Object.entries(storyRef.nodes).map(([id, nodeState]) => {
        const node = nodeState.node;
        switch (node.type) {
          case 'Live Image Overlay': {
            return <LiveImageOverlayPlayback
              key={id}
              nodeState={nodeState as NodeState<LiveImageOverlayNode>}
              active={nodeId === id && nodeIsActive}
            />;
          }
          case 'Live Poll Question': {
            return <LivePollQuestionPlayback
              key={id}
              nodeState={nodeState as NodeState<LivePollQuestionNode>}
              active={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
              nodeResult={storyRef.nodeResults?.[nodeState.nodeId] || { numShards: 0, option1Votes: 0, option2Votes: 0 }}
            />;
          }
          case 'Live True Or False Question': {
            return <LiveTrueOrFalseQuestionPlayback
              key={id}
              nodeState={nodeState as NodeState<LiveTrueOrFalseQuestionNode>}
              active={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
              nodeResult={storyRef.nodeResults?.[nodeState.nodeId] || { numShards: 0, option1Votes: 0, option2Votes: 0 }}
            />;
          }
          case 'Live Builder Image Selector': {
            return <LiveBuilderImageSelectorPlayback
              key={id}
              nodeState={nodeState as NodeState<LiveBuilderImageSelectorNode>}
              active={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
              nodesProgress={storyProgress.nodes || {}}
              videoWidth={videoRect.width}
            />;
          }
          case 'Points Display': {
            return <PointsDisplay
              key={id}
              nodeState={nodeState as NodeState<PointsDisplayNode>}
              activeOverride={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
            />;
          }
          case 'Selfie': {
            return <Selfie
              key={id}
              nodeState={nodeState as NodeState<SelfieNode>}
              activeOverride={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
            />;
          }
          case 'Badge Earning': {
            return <BadgeEarning
              key={id}
              nodeState={nodeState as NodeState<BadgeEarningNode>}
              activeOverride={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
            />;
          }
          case 'Builder Image Assembler': {
            return <BuilderImageAssemblerPlayback
              key={id}
              nodeState={nodeState as NodeState<BuilderImageAssemblerNode>}
              activeOverride={nodeId === id && nodeIsActive}
              story={story}
              storyId={storyId}
              seasonId={seasonId}
              nodesProgress={storyProgress.nodes || {}}
              videoWidth={videoRect.width}
            />;
          }
          default: {
            return <React.Fragment key={id}></React.Fragment>;
          }
        }
      })}
    </div>
  );
};

export default LiveStoryPlaybackPlayer;