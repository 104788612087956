import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import 'firebase/firestore';
import AssetFetcher from 'src/AssetFetcher';
/** type imports */
import type { RootState } from 'src/app/rootReducer';

interface ChipAssetsState {
  assets: { [assetName: string]: string } | null;
  loading: 'idle' | 'pending';
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: ChipAssetsState = {
  assets: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};


export const fetchChipAssets = createAsyncThunk<{ [assetName: string]: string } | null, void, { state: RootState }>(
  'chipAssetsState/fetchChipAssets',
  async (_, { getState }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const baseAssetList = [
      // chip talking assets
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame0.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame1.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame2.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame3.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame4.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame5.png',
      'BundledRemoteAssets/image/Chip/spud-mouth-talking-frame6.png',
    ];
    for (let i = 1; i < 31; i++) {
      if (i < 4) {
        // calculating points audio
        baseAssetList.push(`BundledRemoteAssets/audio/Leveling/Calculating/points-calculating-${i}-chip.mp3`);
      }
      if (i < 4) {
        // level gained audio
        baseAssetList.push(`BundledRemoteAssets/audio/Leveling/Level gained message/level-gain-celebration-${i}-chip.mp3`);
      }
      if (i < 11) {
        // no level gained message audio
        baseAssetList.push(`BundledRemoteAssets/audio/Leveling/No level gained message/no-level-gain-${i}-chip.mp3`);
      }
      // levels audio
      baseAssetList.push(`BundledRemoteAssets/audio/Leveling/levels/level-${i}-chip.mp3`);
    }

    // points earned audio
    baseAssetList.push(...[
      'BundledRemoteAssets/audio/Leveling/Points earned/10-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/50-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/100-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/200-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/300-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/400-points-chip.mp3',
      'BundledRemoteAssets/audio/Leveling/Points earned/500-points-chip-01.mp3',
    ]);

    // points intro audio clips
    baseAssetList.push(...[
      'BundledRemoteAssets/audio/Leveling/points-earn-intro-chip.mp3', // "You earned"
      'BundledRemoteAssets/audio/Leveling/points-announce-chip.mp3', // "Now let's see how many points you've earned"
      'BundledRemoteAssets/audio/Leveling/level-gain-alt-pt1-chip-01.mp3', // "Wow, now you're a"
      'BundledRemoteAssets/audio/Leveling/level-gain-alt-pt2-chip.mp3', // "Tappernaut!"
      'BundledRemoteAssets/audio/Leveling/points-earn-ending-chip.mp3', // "Tappernaut points"
    ]);

    const assetFetcher = new AssetFetcher();
    const assetsArray = await Promise.all(baseAssetList.map(async (assetPath) => {
      const blobUrl = await assetFetcher.getAsset(assetPath);
      return {
        assetPath,
        blobUrl,
      };
    }));
    const assets: { [assetPath: string]: string } = {};

    assetsArray.forEach(({ assetPath, blobUrl }) => {
      assets[assetPath] = blobUrl;
    });

    return assets;
  }
);

const chipSlice = createSlice({
  name: 'chipAssetsState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChipAssets.pending, (state, action) => {
      const {
        meta: {
          requestId,
        },
      } = action;

      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchChipAssets.fulfilled, (state, action) => {
      const {
        meta: {
          requestId,
        },
        payload,
      } = action;

      if (payload && state.loading === 'pending' && state.currentRequestId === requestId) {
        state.assets = payload;
        state.currentRequestId = undefined;
        state.loading = 'idle';
        state.error = null;
      }
    });
    builder.addCase(fetchChipAssets.rejected, (state, action) => {
      const {
        meta: {
          requestId,
        },
        error,
      } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default chipSlice.reducer;