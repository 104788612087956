import firebase from 'firebase';
/** type imports */
import type { SnapshotOptions, Activity } from 'types';

export const profileActivityConverter: firebase.firestore.FirestoreDataConverter<Activity> = {
  toFirestore(data: Activity): Activity {
    return data;
  },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<Activity>, options: SnapshotOptions): Activity {
    const data = snapshot.data(options); 
    return data;
  },
};