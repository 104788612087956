import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { addUserProfile, updateUserProfile } from 'src/features/userProfiles/userProfilesSlice';
import { ageGroup as ageGroupDefintions } from 'src/helpers';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


interface FormState  {
  name: string;
  ageGroup: keyof typeof ageGroupDefintions;
}
interface Params {
  userProfileId?: string;
}

const UserProfileForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userProfileId } = useParams<Params>();
  const edit = !!userProfileId;
  const {
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);
  const userProfile = userProfiles?.[userProfileId ?? ""];
  let initialValues: FormState = {
    name: '',
    ageGroup: '5 and under',
  };
  if (edit && userProfile) {
    initialValues = {
      name: userProfile.name || '',
      ageGroup: userProfile.ageGroup || '5 and under',
    };
  }
  const formik = useFormik<FormState>({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      console.log(values);
      const { setSubmitting, resetForm } = formikHelpers;
      try {
        setSubmitting(true);
        let result;
        if (edit && userProfileId) {
          result = await dispatch(updateUserProfile({ userProfileId, userProfile: values }));
        } else {
          result = await dispatch(addUserProfile({ userProfile: values }));
          resetForm();
        }
        console.log('result', result);
        setSubmitting(false);
      } catch (error) {
        console.log('Non validation error:', error);
      }
    },
  });
  const { handleChange, handleSubmit, isSubmitting } = formik;
  const { name, ageGroup } = formik.values;


  return (
    <form className={'tappity-form'} onSubmit={handleSubmit}>
      <div className={'tappity-form-title'}>{(!edit) ? "Create child profile" : "Update child profile" }</div>
      <div className={'tappity-form-sub-title'}>{"We'll use it to customize their science journal, lessons, and more!"}</div>
      <label
        className={'tappity-form-label'}
        htmlFor='name'
      >
        Name:
      </label>
      <input
        className={'tappity-form-input'}
        name={'name'}
        type={'text'}
        disabled={isSubmitting}
        onChange={handleChange}
        required
        value={name}
      />
      <label
        className={'tappity-form-label'}
        htmlFor='ageGroup'
      >
        Child&apos;s age:
      </label>
      <select
        className={'tappity-form-input'}
        disabled={isSubmitting}
        name={'ageGroup'}
        onChange={handleChange}
        required
        value={ageGroup}
      >
        <option>{ageGroupDefintions['5 and under']}</option>
        <option>{ageGroupDefintions['6 7 8']}</option>
        <option>{ageGroupDefintions['9 and over']}</option>
      </select>
      <button
        className={'tappity-form-submit-button'}
        disabled={isSubmitting}
        type="submit"
      >
        {(edit) ? 'Save' : 'Submit'}
      </button>
      {/* {error && <div className='tappity-form-error'>{error.message}</div>} */}
    </form>
  );
};

export default UserProfileForm;