import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfileAsset } from 'src/features/userProfiles/userProfilesSlice';
import { getLevel, getLevelPoints, } from 'src/helpers';
import 'firebase/storage';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
/** IdBadge specific styling */
import './index.css';



interface Props {
  size: number;
  rotation: number;
  hidden?: boolean;
  storyTotal?: number;
}
const IdBadge: React.FC<Props> = ({ size, rotation, storyTotal, hidden = false }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedProfileId, userProfiles } = useSelector((state: RootState) => state.userProfilesState);
  React.useEffect(() => {
    if (userProfiles && selectedProfileId && userProfiles[selectedProfileId]['image/id-badge-selfie'].blobUrl === null) {
      dispatch(fetchUserProfileAsset({ userProfileId: selectedProfileId, assetName: 'image/id-badge-selfie' },));
    }
  }, [dispatch, userProfiles, selectedProfileId]);

  if (!userProfiles || !selectedProfileId || !userProfiles[selectedProfileId]) {
    return <div>Loading....</div>;
  }

  const userProfile = userProfiles[selectedProfileId];
  const points = userProfile.points + (storyTotal ?? 0);
  const currentLevel = getLevel(points);
  const nextLevelPoints = getLevelPoints(currentLevel + 1);
  const selfieImageAsset = userProfile['image/id-badge-selfie'];

  const levelBarHeight = (size / 15) + 'rem';

  const containerStyle: React.CSSProperties = {
    width: size + 'rem',
    height: ((size * 4) / 3) + 'rem',
    transform: `rotate(${rotation}deg)`,
  };
  const fontSize = (size / 15) + 'rem';
  let idBadgeHiddenClass = '';
  if (hidden) {
    idBadgeHiddenClass = 'hidden';
  }
  return (
    <div className={'id-badge-container ' + idBadgeHiddenClass} style={containerStyle}>
      <img className={'display-selfie-image'} src={selfieImageAsset.blobUrl ?? '/abstract-user-flat-4.svg'} alt='selfie' />
      <div
        className={'user-profile-name'}
        style={{
          fontSize
        }}
      >
        {userProfile.name ?? 'A Tappernaut'}
      </div>
      <div
        className={'user-level-progress-container'}
        style={{ height: levelBarHeight }}
      >
        <div className={'user-level-progress'} style={{
          width: `${(points / nextLevelPoints) * 100}%`,
        }}></div>
      </div>
      <div
        className={'user-level'}
        style={{
          fontSize
        }}
      >
        LEVEL {currentLevel + 1}
      </div>
    </div>
  );
};

export default IdBadge;
