import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';

const AnonymousButtons: React.FC = () => {
  const location = useLocation();
  const promoCode = (new URLSearchParams(location.search)).get('promo');
  const signInUrl = promoCode ? `/sign-in?promo=${promoCode}` : '/sign-in';
  // const signUpUrl = promoCode ? `/sign-up?promo=${promoCode}` : '/sign-up';
  return (
    <React.Fragment>
      {/* <Link className={`sidebar-menu-item ${(location.pathname === '/sign-up') ? 'sidebar-item-selected' : ''}`} to={signUpUrl}>Sign up</Link> */}
      <Link className={`sidebar-menu-item ${(location.pathname === '/sign-in') ? 'sidebar-item-selected' : ''}`} to={signInUrl}>Sign in</Link>
    </React.Fragment>
  );
};

export default AnonymousButtons;