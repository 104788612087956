import React from 'react';
import './index.css';
/** type imports */

import type {
  NodeState,
  LiveImageOverlayNode,
} from 'types';

interface Props {
  active: boolean;
  nodeState: NodeState<LiveImageOverlayNode>;
}

const LiveImageOverlayPlayback: React.FC<Props> = (props: Props) => {
  const { nodeState, active } = props;
  const { node } = nodeState;
  const imageURL = new URL(`https://assetcdn.tappityapp.com/${node.imageOverlay}`);
  const liveImageOverlayStyle: React.CSSProperties = {
    backgroundImage: `url(${imageURL.toString()})`,
  };
  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  return (
    <div node-id={nodeState.nodeId} node-type={node.type} className={`node-container ${nodeContainerHidden}`}>
      <div style={liveImageOverlayStyle} className='live-image-overlay'>
      </div>
      <div className='live-node-interaction-panel'>
      </div>
    </div>
  );
};

export default LiveImageOverlayPlayback;