import { createSlice, SerializedError, PayloadAction } from '@reduxjs/toolkit';
/** type imports */

export interface ChipJob {
  jobId: string;
  audioClip: string;
  location?: { top: number; left: number; };
}
interface ChipState {
  finishedJobIds: string[];
  jobs: ChipJob[];
  error: null | SerializedError;
}

const initialState: ChipState = {
  finishedJobIds: [],
  jobs: [],
  error: null,
};

const chipSlice = createSlice({
  name: 'chipState',
  initialState,
  reducers: {
    addJob(state, { payload }: PayloadAction<ChipJob>) {
      for (let i = 0; i < state.jobs.length; i++) {
        if (state.jobs[i].jobId === payload.jobId) {
          return;
        }
      }
      state.jobs.push(payload);
    },
    shiftJobs(state) {
      const finishedJob = state.jobs.shift();
      if (finishedJob) {
        state.finishedJobIds.push(finishedJob.jobId);
      }
    },
    clearJobs(state) {
      const jobIds: ChipState['finishedJobIds'] = [];
      for (let i = 0; i < state.jobs.length; i++) {
        jobIds.push(state.jobs[i].jobId);
      }
      state.jobs = [];
      state.finishedJobIds.push(...jobIds);
    }
  },
});

export const {
  addJob,
  shiftJobs,
  clearJobs,
} = chipSlice.actions;

export default chipSlice.reducer;