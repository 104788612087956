import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { fetchStories } from 'src/features/stories/storiesSlice';
import { fetchSeasons } from 'src/features/seasons/seasonsSlice';
import { useParams, Link } from 'react-router-dom';
import StoryCard from '../stories/StoryList/StoryCard';
import './Seasons.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


const SeasonPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { seasonId } = useParams<{ seasonId: string }>();
  const { stories, seasons, loading } = useSelector((state: RootState) => {
    const loading = (state.storiesState.loading === 'pending' ||
      state.seasonsState.loading === 'pending') ? 'pending' : 'idle';
    return {
      stories: state.storiesState.stories,
      seasons: state.seasonsState.seasons,
      loading
    };
  }, shallowEqual);

  React.useEffect(() => {
    if (stories === null) {
      dispatch(fetchStories());
    }
    if (seasons === null) {
      dispatch(fetchSeasons());
    }
  }, [dispatch, stories, seasons]);

  if (loading === 'pending' || !stories || !seasons) {
    return (
      <div className='loading-screen'></div>
    );
  }

  const seasonStories = stories.bySeason[seasonId] || [];
  const season = seasons[seasonId];
  const lessonCount = seasonStories.length === 1 ? "1 Story" : `${seasonStories.length} Stories`;

  return (
    <div id='season-page' className="app-page">
      <header>
        <h1>
          <Link className="back-button" to='/'>←</Link> {season.title}
          <span className="lesson-count-label">{lessonCount}</span>
        </h1>
        <h2>{season.longDescription}</h2>
      </header>
      <div className="story-list-container">
        <div className="story-list">
          {seasonStories.map(storyId => {
            const story = stories.byId[storyId];
            return (
              <StoryCard
                key={storyId}
                storyId={storyId}
                storyState={story}
                seasonId={story.seasonId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeasonPage;