import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { generateFirestorePath } from 'src/helpers';
import 'firebase/firestore';
import { storyConverter } from 'src/features/stories/helpers';
import { transformNode } from 'src/features/nodes/transformers';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { Node, Story, NodeState, StoryState, LiveNode, NodeResult } from 'types';
interface NodesState {
  bySeasonId: {
    [seasonId: string]: {
      byStoryId: {
        [storyId: string]: StoryState<Story>,
      },
    };
  };
}

const initialState: NodesState = {
  bySeasonId: {}
};

interface FetchNodesReturn { story: Story; nodes: { [nodeId: string]: NodeState<Node | LiveNode>; }; nodeResults?: { [nodeId: string]: NodeResult } }

export const fetchNodesBySeasonByStory = createAsyncThunk<FetchNodesReturn | null, { seasonId: string; storyId: string }, { state: RootState }>(
  'nodes/fetchNodesBySeasonByStory',
  async ({ seasonId, storyId }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { bySeasonId } = getState().nodesState;
    const storyRef = bySeasonId[seasonId]?.byStoryId[storyId];
    if (storyRef === undefined || storyRef.loading !== 'pending' || requestId !== storyRef.currentRequestId) {
      return null;
    }
    const storySnapshot = await firebase.firestore()
      .collection(generateFirestorePath(`seasons/${seasonId}/stories`))
      .doc(storyId)
      .withConverter(storyConverter)
      .get();
    if (!storySnapshot.exists) {
      throw new Error('No story with that ID');
    }
    const story = storySnapshot.data();
    if (!story) {
      throw new Error('No story with that ID');
    }

    const nodes: { [nodeId: string]: NodeState<Node | LiveNode> } = {};


    const nodeResultsPromises: Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>[] = [];
    Object.entries(story.nodes).forEach(([nodeId, node]) => {
      nodes[nodeId] = {
        nodeId,
        node,
        nodeWithAssets: null,
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
      };
      if (story.type === 'live') {
        nodeResultsPromises.push(firebase.firestore().doc(generateFirestorePath(`seasons/${seasonId}/stories/${storyId}/nodeResults/${nodeId}`)).get());
      }
    });

    const returnValue: FetchNodesReturn = { story, nodes };
    if (nodeResultsPromises.length) {
      returnValue.nodeResults = {};
      const nodeResults = await Promise.all(nodeResultsPromises);
      nodeResults.forEach((nodeResultSnapshot) => {
        const nodeResult = nodeResultSnapshot.data();
        if (nodeResultSnapshot.exists && nodeResult && returnValue.nodeResults) {
          console.log(nodeResultSnapshot.id, nodeResult);
          returnValue.nodeResults[nodeResultSnapshot.id] = nodeResult as NodeResult;
        }
      });
    }

    return returnValue;
  }
);

export const fetchNodeAssets = createAsyncThunk<Node | null, { seasonId: string; storyId: string; nodeId: string; node: Node }, { state: RootState }>(
  'nodes/fetchNodeAssets',
  async ({ seasonId, storyId, nodeId, node }, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }
    const { bySeasonId } = getState().nodesState;

    const nodeContainerRef = bySeasonId[seasonId]?.byStoryId[storyId]?.nodes[nodeId];
    if (nodeContainerRef === undefined || nodeContainerRef.loading !== 'pending' || requestId !== nodeContainerRef.currentRequestId) {
      return null;
    }
    const nodeWithAssets = await transformNode(node);
    return nodeWithAssets;
  }
);

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNodesBySeasonByStory.pending, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId },
          requestId,
        },
      } = action;
      const seasonRef = state.bySeasonId[seasonId];
      const storyRef = seasonRef?.byStoryId[storyId];
      if (storyRef === undefined || storyRef.loading === 'idle') {
        if (storyRef) {
          storyRef.loading = 'pending';
          storyRef.currentRequestId = requestId;
        } else if (seasonRef) {
          seasonRef.byStoryId[storyId] = {
            storyId,
            story: null,
            nodes: {},
            nodeResults: undefined,
            loading: 'pending',
            currentRequestId: requestId,
            error: null,
          };

        } else {
          state.bySeasonId[seasonId] = {
            byStoryId: {
              [storyId]: {
                storyId,
                story: null,
                nodes: {},
                nodeResults: undefined,
                loading: 'pending',
                currentRequestId: requestId,
                error: null,
              },
            },
          };
        }
      }
    });
    builder.addCase(fetchNodesBySeasonByStory.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId },
          requestId,
        },
        payload,
      } = action;
      const seasonRef = state.bySeasonId[seasonId];
      const storyRef = seasonRef?.byStoryId[storyId];
      if (payload && storyRef?.currentRequestId === requestId) {
        storyRef.story = payload.story;
        storyRef.nodes = payload.nodes;
        storyRef.nodeResults = payload.nodeResults;
        storyRef.loading = 'idle';
        storyRef.error = null;
        storyRef.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchNodesBySeasonByStory.rejected, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId },
          requestId,
        },
        error,
      } = action;
      const seasonRef = state.bySeasonId[seasonId];
      const storyRef = seasonRef?.byStoryId[storyId];
      if (storyRef && storyRef.loading === 'pending' && storyRef.currentRequestId === requestId) {
        storyRef.loading = 'idle';
        storyRef.error = error;
        storyRef.currentRequestId = undefined;
      }
    });

    builder.addCase(fetchNodeAssets.pending, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId, nodeId },
          requestId,
        },
      } = action;
      const nodeContainerRef = state.bySeasonId[seasonId]?.byStoryId[storyId]?.nodes[nodeId];
      if (nodeContainerRef?.loading === 'idle') {
        nodeContainerRef.loading = 'pending';
        nodeContainerRef.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchNodeAssets.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId, nodeId },
          requestId,
        },
        payload,
      } = action;
      const nodeContainerRef = state.bySeasonId[seasonId]?.byStoryId[storyId]?.nodes[nodeId];
      if (payload && nodeContainerRef?.currentRequestId === requestId) {
        nodeContainerRef.nodeWithAssets = payload;
        nodeContainerRef.loading = 'idle';
        nodeContainerRef.error = null;
        nodeContainerRef.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchNodeAssets.rejected, (state, action) => {
      const {
        meta: {
          arg: { seasonId, storyId, nodeId },
          requestId,
        },
        error,
      } = action;
      const nodeContainerRef = state.bySeasonId[seasonId]?.byStoryId[storyId]?.nodes[nodeId];
      if (nodeContainerRef && nodeContainerRef.loading === 'pending' && nodeContainerRef.currentRequestId === requestId) {
        nodeContainerRef.loading = 'idle';
        nodeContainerRef.error = error;
        nodeContainerRef.currentRequestId = undefined;
      }
    });
  },
});

export default nodesSlice.reducer;