import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import IdBadge from 'src/components/stories/StoryPlayer/helperComponents/IdBadge';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type {
  IdBadgeNode,
  NodeState,
  Story,
} from 'types';
/** Selie specific styling */
import './index.css';



interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  nodeState: NodeState<IdBadgeNode>;
}
const DisplayIdBadge: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const [hideBadge, setHideBadge] = React.useState<boolean>(true);
  // const nodeContainerRef = React.useRef<HTMLDivElement>(null);
  // const nodeContainerRect = useRect(nodeContainerRef);
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  const active = nodeId === urlNodeId;

  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);

  let nextNodeId: string | null = null;
  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }

  if (!nodeWithAssets) {
    return <div>Loading....</div>;
  }

  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }

  let videoContainerHidden = '';
  if (!hideBadge) {
    videoContainerHidden = 'hidden';
  }
  return (
    <div node-id={nodeState.nodeId} node-type={node.type} className={`node-container ${nodeContainerHidden}`}>
      <div className={`node-video-container ${videoContainerHidden}`}>
        <ReactPlayer
          playing={active}
          width={'100%'}
          height={'auto'}
          url={nodeWithAssets.video}
          onEnded={() => {
            setHideBadge(false);
          }}
        />
      </div>
      <IdBadge
        size={25}
        rotation={0}
        hidden={hideBadge}
      />
      <div className='node-interaction-panel'>
        <div
          className={`option-item`}
          style={{
            backgroundImage: 'url(/point-button.png)',
            backgroundColor: 'cyan',
            display: (hideBadge) ? 'none' : 'inherit',
          }}
          onClick={() => {
            if (nextNodeId) {
              const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
              console.log(nextNodeUrl);
              history.replace(nextNodeUrl);
            }
          }}
        ></div>
      </div>
    </div>
  );
};

export default DisplayIdBadge;