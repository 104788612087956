import React from 'react';
import './index.css';

interface Props {
  score?: number;
}

const StoryPoints: React.FC<Props> = ({ score }: Props) => {
  return (
    <div className='points-counter'>
      {score || 0}
      <p>POINTS</p>
    </div>
  );
};

export default StoryPoints;