import React from 'react';
import './index.css';
/** type imports */
import type {
  Story,
} from 'types';

interface Props {
  story: Story | null;
}

const StoryDetails: React.FC<Props> = ({ story }: Props) => {
  return (
    <div className='story-details'>
      <h5 className="purple-text">LESSON DETAILS</h5>
      <h2 className="dark-text">{story?.title}</h2>
      <p className="gray-text">{story?.description}</p>
      <h5 className="dark-text">Discussion Questions</h5>
      <ul className="gray-text">
        {story?.discussionQuestions.map((name, index) => {
          return (
            <li key={index}>{name}</li>
          );
        })}
      </ul>
    </div>
  );
};

export default StoryDetails;