import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase';
import 'firebase/auth';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import posthog from 'posthog-js';
import { fetchUpsertUser } from 'src/features/authentication/userSlice';
import { fetchUserProfiles } from 'src/features/userProfiles/userProfilesSlice';
import { fetchSubscriber, clearSubscriber } from 'src/features/revenuecat/revenuecatSlice';
import { fetchProfileActivities } from 'src/features/profileActivities/profileActivitiesSlice';
import LandingPage from 'src/components/landing/index';
import AppLandingPage from 'src/components/landing/AppLanding/index';
import UserProfiles from 'src/components/UserProfiles';
import UserProfileForm from 'src/components/UserProfiles/UserProfileForm';
import ProfileTopics from 'src/components/UserProfiles/ProfileTopics';
import NoMatch from 'src/components/auth/NoMatch';
import Home from 'src/components/home';
import StoryPlayer from 'src/components/stories/StoryPlayer';
import Sidebar from 'src/components/Sidebar';
import SignedInRoute from 'src/components/auth/SignedInRoute';
import ParentMenu from 'src/components/ParentMenu';
import ProgressPage from 'src/components/progress/';
import SubscriptionManager from 'src/components/ParentMenu/SubscriptionManager';
import MembershipDetails from 'src/components/ParentMenu/MembershipDetails';
import ProgressUpdateEmails from 'src/components/ParentMenu/ProgressUpdateEmails';
import CustomerSupport from 'src/components/ParentMenu/CustomerSupport';
import UpdateEmailPassword from 'src/components/ParentMenu/UpdateEmailPassword';
import CheckoutSuccess from 'src/components/ParentMenu/SubscriptionManager/CheckoutSuccess';
import EmailPasswordAuthForm from 'src/components/auth/EmailPasswordAuthForm';
import './App.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type { FirebaseAuthUser } from 'types';
import type { StaticContext, RouteComponentProps } from 'react-router';
import type Stripe from 'stripe';
import DownloadApp from 'src/components/landing/DownloadApp';


const firebaseConfig = {
  apiKey: "AIzaSyDTuzdkUh1GMA617HLyhxCs8OzrnudIM-Y",
  authDomain: "storytime-a93ed.firebaseapp.com",
  databaseURL: "https://storytime-a93ed.firebaseio.com",
  projectId: "storytime-a93ed",
  storageBucket: "storytime-a93ed.appspot.com",
  messagingSenderId: "641267299443",
  appId: "1:641267299443:web:db044f8a91df4a8030a606",
  measurementId: "G-K74JZBXJQ3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_ijImBoeMBdY1M8t6OQ2NRIA2');

posthog.init('NFay4zPB-wkc-pFmAjXRIoAmoY_5ExIgc-8GA-CdALY', { api_host: 'https://hog.tappityapp.com' });

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<{ promoCode?: Stripe.PromotionCode; skipSplash?: boolean; } | undefined>();
  const {
    user,
  } = useSelector((state: RootState) => state.userState);
  const {
    selectedProfileId,
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);
  const {
    selectedProfileId: activitiesProfileId,
  } = useSelector((state: RootState) => state.profileActivitiesState);

  React.useEffect(() => {
    if (selectedProfileId !== null && selectedProfileId !== activitiesProfileId) {
      dispatch(fetchProfileActivities({ selectedProfileId }));
    }
  }, [dispatch, selectedProfileId, activitiesProfileId]);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userRef) => {
      try {
        if (userRef) {
          // Need to cast because the 'toJSON()' function returns the type 'Object'
          const user = userRef.toJSON() as FirebaseAuthUser;
          console.log(user);
          await dispatch(fetchUpsertUser(user));
        } else {
          // User is signed out.
          console.log("user is signed out");
          await dispatch(fetchUpsertUser(null));
          await firebase.auth().signInAnonymously();
        }
      } catch (error) {
        console.log('error in "onAuthStateChanged"', error);
      }
    });
  }, [dispatch]);
  React.useEffect(() => {
    if (user !== null) {
      dispatch(fetchUserProfiles());
      if (!user.isAnonymous) {
        dispatch(fetchSubscriber());
      } else {
        dispatch(clearSubscriber());
      }
    }
  }, [dispatch, user]);

  if (userProfiles === null || user === null) {
    return <div style={{ height: '100vh' }} className='loading-screen'></div>;
  }
  if (selectedProfileId === null) {
    return (
      <Redirect
        to={{
          pathname: '/profiles'
        }}
      />
    );
  }

  const selectedProfile = userProfiles[selectedProfileId];

  const allowedAnonPages = ['/sign-in', '/sign-up', '/landing', '/applanding'];

  if (user.isAnonymous && !allowedAnonPages.includes(location.pathname)) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/applanding',
        }}
      />
    );
  }

  if (!user.isAnonymous && ['/sign-in', '/sign-up'].includes(location.pathname)) {
    if (location.state?.promoCode) {
      return (
        <Redirect
          to={{
            ...location,
            pathname: `/applanding`,
            search: `?promo=${location.state.promoCode.code}`,
          }}
        />);
    }
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/',
        }}
      />
    );
  }

  return (
    <Switch>
      <Route path="/landing">
        <Elements stripe={stripePromise}>
          <LandingPage
            user={user}
            selectedProfileId={selectedProfileId}
            selectedProfile={selectedProfile}
            skipSplash={location.state?.skipSplash}
          />
        </Elements>
      </Route>
      <Route path="/applanding">
        <Elements stripe={stripePromise}>
          <AppLandingPage
            user={user}
            selectedProfileId={selectedProfileId}
            selectedProfile={selectedProfile}
            skipSplash={false}
          />
        </Elements>
      </Route>
      <Route path="/downloadapp">
        <Elements stripe={stripePromise}>
          <DownloadApp />
        </Elements>
      </Route>
      <Route path='/checkout_success'>
        <SignedInRoute>
          <CheckoutSuccess />
        </SignedInRoute>
      </Route>
      <Route>
        <div id='app-container'>
          <Sidebar user={user} selectedProfile={selectedProfile} />
          <div id='main-content'>
            <Switch>
              <Route path='/profiles/:userProfileId/topics'
                render={(props: RouteComponentProps<{ userProfileId: string }, StaticContext, unknown>) => (
                  <SignedInRoute>
                    <ProfileTopics userProfileId={props.match.params.userProfileId} />
                  </SignedInRoute>
                )} />
              <Route path='/profiles/new' >
                <SignedInRoute>
                  <UserProfileForm />
                </SignedInRoute>
              </Route>
              <Route path='/profiles/:userProfileId' >
                <SignedInRoute>
                  <UserProfileForm />
                </SignedInRoute>
              </Route>
              <Route path='/profiles' >
                <SignedInRoute>
                  <UserProfiles />
                </SignedInRoute>
              </Route>
              <Route path='/parent_menu' >
                <SignedInRoute>
                  <ParentMenu />
                </SignedInRoute>
              </Route>
              <Route exact path={['/progress', '/progress/:topicId']}>
                <SignedInRoute>
                  <ProgressPage />
                </SignedInRoute>
              </Route>
              <Route path='/subscription'>
                <SignedInRoute>
                  <Elements stripe={stripePromise}>
                    <SubscriptionManager />
                  </Elements>
                </SignedInRoute>
              </Route>

              <Route path='/membership_details'>
                <SignedInRoute>
                  <MembershipDetails />
                </SignedInRoute>
              </Route>
              <Route path='/progress_update_emails'>
                <SignedInRoute>
                  <ProgressUpdateEmails />
                </SignedInRoute>
              </Route>
              <Route path='/change_password'>
                <SignedInRoute>
                  <UpdateEmailPassword />
                </SignedInRoute>
              </Route>
              <Route path='/support'>
                <CustomerSupport />
              </Route>
              {/* <Route path='/sign-up'>
                <EmailPasswordAuthForm type='sign-up' />
              </Route> */}
              <Route path='/sign-in'>
                <EmailPasswordAuthForm type='sign-in' />
              </Route>
              <Route
                path={['/seasons/:seasonId/stories/:storyId/nodes/:nodeId', '/seasons/:seasonId/stories/:storyId']}
                render={(props: RouteComponentProps<{ seasonId: string; storyId: string; nodeId?: string; }, StaticContext, unknown>) => (
                  <SignedInRoute>
                    <StoryPlayer  {...props.match.params} />
                  </SignedInRoute>
                )}
              />
              <Route exact path={['/seasons/:seasonId', '/seasons', '/']}>
                <SignedInRoute>
                  <Home selectedProfile={selectedProfile} />
                </SignedInRoute>
              </Route>
              <Route>
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default App;
