import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addJob, clearJobs } from 'src/features/chip/chipSlice';
import { v4 as uuidv4 } from 'uuid';
/** type imports */
import type { AppDispatch } from 'src/app/store';
import type { RootState } from 'src/app/rootReducer';
import type { ChipJob } from 'src/features/chip/chipSlice';

interface Job extends Omit<ChipJob, 'jobId'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFinish?: () => Promise<any>;
}

/**
 * useChip hook
 */
export function useChip(): [(job: Job) => void, typeof clearJobs] {
  const dispatch = useDispatch<AppDispatch>();
  const finishedJobIds = useSelector((state: RootState) => state.chipState.finishedJobIds);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [jobCallbacks, updateJobCallbacks] = React.useState<{ [jobId: string]: () => Promise<any> }>({});
  const executingRef = React.useRef(false);
  React.useEffect(() => {
    async function executeFinishedJobCB() {
      try {
        const executing = executingRef.current;
        let currentId: string | null = null;
        for (const jobId of finishedJobIds) {
          if (jobId in jobCallbacks) {
            /** 
             * pick the first finished job with a CB 
             * when it is not currently executing and break
             */
            if (!executing) {
              currentId = jobId;
            }
            break;
          }
        }
        if (currentId) {
          executingRef.current = true;
          await jobCallbacks[currentId]();
          executingRef.current = false;
          updateJobCallbacks((currentJobCallbacks) => {
            if (currentId) {
              /** 
               * remove CB after finished executing
               * this also causes the useEffect callback
               * to execute again
               */
              delete currentJobCallbacks[currentId];
            }
            return currentJobCallbacks;
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    executeFinishedJobCB();

  }, [finishedJobIds, jobCallbacks]);

  const addChipJob = React.useCallback(({ onFinish, ...rest }: Job) => {
    try {
      const jobId = uuidv4();
      if (onFinish) {
        updateJobCallbacks((currentCbs) => {
          currentCbs[jobId] = onFinish;
          return currentCbs;
        });
      }
      const chipJob = { jobId, ...rest };
      dispatch(addJob(chipJob));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  return [addChipJob, clearJobs];
}