import React from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { fetchNodeAssets } from 'src/features/nodes/nodesSlice';
import { fetchCharacterProfileAssets } from 'src/features/characterProfiles/characterProfilesSlice';
import { useChip } from 'src/components/chip/ChipHook';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';
import type {
  PhoneNode,
  NodeState,
  Story,
} from "types";


interface Props {
  seasonId: string;
  storyId: string;
  story: Story;
  nodeState: NodeState<PhoneNode>;
}

const Phone: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [addJob] = useChip();
  const { nodeId: urlNodeId } = useParams<{ nodeId: string }>();
  const {
    byCharacterProfileId
  } = useSelector((state: RootState) => state.characterProfilesState);
  const history = useHistory();
  const { nodeState, seasonId, storyId, story } = props;
  const { nodeId, node, nodeWithAssets } = nodeState;
  const active = nodeId === urlNodeId;
  React.useEffect(() => {
    if (nodeWithAssets === null) {
      dispatch(fetchNodeAssets({ seasonId, storyId, nodeId, node }));
    }
  }, [dispatch, nodeWithAssets, seasonId, storyId, nodeId, node]);
  const { characterProfileId } = node;
  React.useEffect(() => {
    if (byCharacterProfileId && byCharacterProfileId[characterProfileId].characterProfileWithAssets === null) {
      dispatch(fetchCharacterProfileAssets({ characterProfileId }));
    }
  }, [dispatch, byCharacterProfileId, characterProfileId]);

  React.useEffect(() => {
    const audioClip = nodeWithAssets?.botAudio;
    if (audioClip && active) {
      addJob({
        audioClip,
      });
    }
  }, [addJob, nodeWithAssets, active]);

  let nextNodeId: string | null = null;
  if (!node.isEndingNode) {
    const filteredEdgeArray = Object.entries(node.edges).filter(([, { targetNodeId }]) => !story.nodes[targetNodeId].isDeleted);
    if (filteredEdgeArray.length > 0) {
      const [[, { targetNodeId }]] = filteredEdgeArray;
      nextNodeId = targetNodeId;
    }
  }


  const characterProfileWithAssets = byCharacterProfileId && byCharacterProfileId[characterProfileId]?.characterProfileWithAssets;
  if (!nodeWithAssets || !characterProfileWithAssets) {
    return <div>Loading....</div>;
  }

  let nodeContainerHidden = 'hidden';
  if (active) {
    nodeContainerHidden = '';
  }
  const nodeVideoContainerStyle: React.CSSProperties = {
    backgroundImage: `url(${nodeWithAssets.backgroundImage})`,
  };
  const profileImageStyle: React.CSSProperties = {
    backgroundImage: `url(${characterProfileWithAssets.profileImage})`,
  };
  return (
    <div className={`node-container ${nodeContainerHidden}`}>
      <div style={nodeVideoContainerStyle} className={`node-video-container phone-call-image`}>
        <div className={`incoming-call`}>INCOMING CALL</div>
        <div className={`phone-profile-image`} style={profileImageStyle}></div>
        <div className={`character-title`}>{characterProfileWithAssets.displayName}</div>
      </div>
      <div className='node-interaction-panel'>
        <div
          className={`option-item`}
          style={{
            backgroundImage: 'url(/iphone-clipart-clear-background-phone-8-transparent.png)',
            borderRadius: 0,
          }}
          onClick={() => {
            if (nextNodeId) {
              const nextNodeUrl = `/seasons/${seasonId}/stories/${storyId}/nodes/${nextNodeId}`;
              console.log(nextNodeUrl);
              history.replace(nextNodeUrl);

            }
          }}
        >
        </div>
      </div>
      <ReactPlayer
        playing={active}
        width={'100%'}
        height={'auto'}
        url={characterProfileWithAssets.ringAudio}
        loop={true}
      />
    </div>
  );
};

export default Phone;