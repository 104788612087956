import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserProfileTopics, fetchUserProfiles } from 'src/features/userProfiles/userProfilesSlice';
import { fetchTags } from 'src/features/stories/tagsSlice';
import './index.css';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { AppDispatch } from 'src/app/store';


interface Props {
  userProfileId: string;
}

const ProfileTopics: React.FC<Props> = ({ userProfileId }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    userProfiles,
  } = useSelector((state: RootState) => state.userProfilesState);
  const {
    tags,
  } = useSelector((state: RootState) => state.tagsState);
  React.useEffect(() => {
    if (userProfiles === null) {
      dispatch(fetchUserProfiles());
    }
  }, [dispatch, userProfiles]);
  React.useEffect(() => {
    if (tags === null) {
      dispatch(fetchTags());
    }
  }, [dispatch, tags]);
  const userProfile = userProfiles?.[userProfileId];
  const [selectedTopics, setSelectedTopics] = React.useState(userProfile?.topics ?? []);
  const [isEqual, setIsEqual] = React.useState(true);

  function testEqual(a: string[], b: string[]): boolean {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (b.indexOf(a[i]) === -1 || a.indexOf(b[i]) === -1) {
        return false;
      }
    }
    return true;
  }
  React.useEffect(() => {
    const userTopics = userProfile?.topics;
    if (userTopics !== undefined) {
      setIsEqual(testEqual(userTopics, selectedTopics));
    }
  }, [userProfile?.topics, selectedTopics]);


  return (
    <div className={'profile-topics-container'}>
      <div className={'tappity-form-title'}>{"Select your child's favorite topics"}</div>
      <p>We&apos;ll recommend lessons based on their interests.</p>
      <div className={'profile-topics-list'}>
        {Object.entries(tags || {}).map(([tagId, tag], index) => {
          let className = 'profile-topic';
          if (selectedTopics.includes(tagId)) {
            className = 'profile-topic topic-selected';
          }
          return (
            <div
              key={index}
              onClick={() => setSelectedTopics((prevTopics) => {
                let next: string[] = [];
                const location = prevTopics.indexOf(tagId);
                if (location === -1) {
                  next = [...prevTopics, tagId];
                } else {
                  next = [...prevTopics.slice(0, location), ...prevTopics.slice(location + 1)];
                }
                return next;
              })}
              className={className}
            >
              {tag.emoji} {tag.displayName}
            </div>
          );
        })}
      </div>
      <button
        className={'tappity-form-submit-button'}
        disabled={isEqual}
        type="submit"
        onClick={async () => {
          try {
            await dispatch(updateUserProfileTopics({ userProfileId, topics: selectedTopics }));
          } catch (error) {
            if (error instanceof Error) {
              console.error(error.message);
            }
          }
        }}
      >
        {(isEqual) ? 'Select a topic' : 'Continue'}
      </button>
      {/* {error && <div className='tappity-form-error'>{error.message}</div>} */}
    </div>
  );
};

export default ProfileTopics;