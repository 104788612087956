import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import dayjs from 'dayjs';
import { axiosInstance } from 'src/helpers';
import 'firebase/firestore';
/** type imports */
import type { RootState } from 'src/app/rootReducer';
import type { RCSubscriber, RCResponse } from 'types';


interface RevenuecatState {
  subscriber: RCSubscriber | null;
  currentRequestId: undefined | string,
  loading: 'idle' | 'pending';
  error: null | SerializedError;
}

const initialState: RevenuecatState = {
  subscriber: null,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const fetchSubscriber = createAsyncThunk<RCSubscriber | null, void, { state: RootState }>(
  'revenuecat/fetchSubscriber',
  async (_, { getState, requestId }) => {
    const { user } = getState().userState;
    if (!user) {
      throw new Error("Oops! You're not authenticated!");
    }

    const { currentRequestId, loading } = getState().revenuecatState;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return null;
    }
    const { data: { subscriber } } = await axiosInstance.get<RCResponse>('/revenuecat/subscriber');
    const now = dayjs();
    let hasActiveMembership = true;
    if (!subscriber.entitlements.subscriber || dayjs(subscriber.entitlements.subscriber.expires_date).isBefore(now)) {
      hasActiveMembership = false;
    }
    posthog.people.set({ hasActiveMembership });
    return subscriber;
  }
);

const revenuecatSlice = createSlice({
  name: 'revenuecat',
  initialState,
  reducers: {
    clearSubscriber(state) {
      state.subscriber = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriber.pending, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchSubscriber.fulfilled, (state, action) => {
      const { meta: { requestId }, payload } = action;
      if (payload !== null && requestId === state.currentRequestId) {
        state.subscriber = payload;
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
      }
    });
    builder.addCase(fetchSubscriber.rejected, (state, action) => {
      const { meta: { requestId } } = action;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { clearSubscriber } = revenuecatSlice.actions;
export default revenuecatSlice.reducer;