import React from 'react';
// import { useStripe } from '@stripe/react-stripe-js';
// import { axiosInstance } from 'src/helpers';
// import { useLocation, Link } from 'react-router-dom';
import PriceSelector from '../PriceSelector';
// import {
//   useSelector,
//   // useDispatch,
// } from 'react-redux';
// import {
//   AcademicCapIcon,
//   CalendarIcon,
//   DeviceTabletIcon,
//   UserCircleIcon,
//   LockClosedIcon,
//   NewspaperIcon,
//   ChatAlt2Icon,
//   BeakerIcon,
// } from '@heroicons/react/outline';
import FourClassMembership from './FourClassMembership';
import DigitalWithClasses from './DigitalWithClasses';
import DigitalOnly from './DigitalOnly';
import HomeschoolYearPlan from './HomeschoolYearPlan';
import HomeschoolFamilyYearPlan from './HomeschoolFamilyYearPlan';
import SingleAppPlan from './SingleAppPlan';
/** import types */
import type { Stripe } from 'stripe';
// import type { RootState } from 'src/app/rootReducer';


interface Props {
  digitalOnlyPrices: Stripe.Price[];
  withClassesPrices: Stripe.Price[];
  withFourClassesPrices: Stripe.Price[];
  homeschoolPlanPrices: Stripe.Price[];
  homeschoolFamilyPlanPrices: Stripe.Price[];
  promoCode: Stripe.PromotionCode | undefined;
  prodegeTrxId: string | null;
}

const CategorySelector: React.FC<Props> = ({ digitalOnlyPrices, withClassesPrices, withFourClassesPrices, homeschoolPlanPrices, homeschoolFamilyPlanPrices, promoCode, prodegeTrxId }: Props) => {
  const [category, setCategory] = React.useState<"digital_only" | "with_classes" | "with_four_classes" | "homeschool_plan" | "homeschool_family_plan" | "single_app_plan" | null>(null);
  let amountOff = promoCode?.coupon.percent_off;
  let amountOffString = amountOff + "%";
  let freeTrial = true;
  console.log("prodegeTrxId 1");
  console.log(prodegeTrxId);

  if (promoCode?.coupon.amount_off) {
    amountOff = promoCode?.coupon.amount_off / 100;
    amountOffString = "$" + amountOff;
  }
  if (promoCode?.code.includes("SUMMER") || promoCode?.code.includes("HOMESCHOOL")) {
    freeTrial = false;
  }
  return (
    <div className={'subscription-category-selector-container'}>
      {(category === null) ? <>
        {
          (promoCode?.code.includes("HOMESCHOOL")) ?
            <div className='plan-page-header-container'>
              <div className={'tappity-header'}>Homeschool Science Program Discount</div>
              <div className={'tappity-sub-header'}>Review and continue to checkout</div>

            </div>
            :
            <span></span>
        }
        {
          (promoCode?.code.includes("SUMMER")) ?
            <div className='plan-page-header-container'>
              <div className={'tappity-header'}>Summer Science Program</div>
              <div className={'tappity-sub-header'}>Review and continue to checkout</div>
            </div>
            :
            <span></span>
        }
        {freeTrial ?
          <div className='plan-page-header-container'>
          <div className={'tappity-header'}>Try Tappity FREE for two weeks</div>
            </div>  :
          <span></span>
        }

        {promoCode && <div className="promo-code-banner">CODE {promoCode.code}: {`${amountOffString} off will be applied at checkout`}</div>}

        {(promoCode?.code.includes("SUMMER")) ?
          <FourClassMembership
            stripePrices={withFourClassesPrices}
            promoCode={promoCode}
            onClick={() => setCategory("with_four_classes")}
          />
          :
          (promoCode?.code.includes("APP")) ?
            <SingleAppPlan
              stripePrices={digitalOnlyPrices}
              promoCode={promoCode}
              onClick={() => setCategory("digital_only")}
              prodegeTrxId={prodegeTrxId}
            />
            :
            (promoCode?.code.includes("HOMESCHOOL750")) ?
              <HomeschoolYearPlan
                stripePrices={homeschoolPlanPrices}
                promoCode={promoCode}
                onClick={() => setCategory("homeschool_plan")}
              />
              :
              (promoCode?.code.includes("HOMESCHOOL1550")) ?
                <HomeschoolFamilyYearPlan
                  stripePrices={homeschoolFamilyPlanPrices}
                  promoCode={promoCode}
                  onClick={() => setCategory("homeschool_plan")}
                />
                :
                <div className={'subscription-categories-container'}>
                  <DigitalOnly
                    stripePrices={digitalOnlyPrices}
                    promoCode={promoCode}
                    onClick={() => setCategory("digital_only")}
                  />
                  <DigitalWithClasses
                    stripePrices={withClassesPrices}
                    promoCode={promoCode}
                    onClick={() => setCategory("with_classes")}
                  />
                </div>}
        <div className="small-print">You will only be charged after 14-day trial period. Cancel any time.</div>
        <img className={'featured-by-checkout'} src='/featured-by-checkout.png' alt='featured-by-apple' width="38%" />
      </> : (category === "digital_only") ?
        <PriceSelector
          category={category}
          stripePrices={digitalOnlyPrices}
          promoCode={promoCode}
          onCancel={() => setCategory(null)}
          prodegeTrxId={prodegeTrxId}
        />
        :

        (category === "with_four_classes") ?
          <PriceSelector
            category={category}
            stripePrices={withFourClassesPrices}
            promoCode={promoCode}
            onCancel={() => setCategory(null)}
            prodegeTrxId={prodegeTrxId}
          />
          :
          <PriceSelector
            category={category}
            stripePrices={withClassesPrices}
            promoCode={promoCode}
            onCancel={() => setCategory(null)}
            prodegeTrxId={prodegeTrxId}
          />
      }
    </div>
  );
};

export default CategorySelector;